import { BuffType } from './buff'

export enum PROPERTY_EVENTS {
  /**
   * Property to count how many times a user views his profile
   */
  profileViewed = 'profile.viewed',

  /**
   * Property to count how many times a user expands the menu
   */
  menuShown = 'menu.shown',

  /**
   * Property to count how many times a user mutes buff sound
   */
  muteActivated = 'mute.Activated',

  /**
   * Property to count how many times a user unmutes buff sound
   */
  muteDeactivated = 'mute.Deactivated',

  /**
   * Property to count how many times a user send a message in friends group chat
   */
  chatMessageSent = 'chat.MessageSend',

  /**
   * Property to count how many times a user has disconnected from centrifugo
   */
  socketDisconnected = 'socket.Disconnected',
}

export enum METRIC_EVENTS {
  /** Events */

  /**
   * Event fired when an announcement is hidden
   */
  announcementDisplayed = 'announcements.displayed',

  /**
   * Event fired when a buff is hidden
   */
  voteableDisplayed = 'voteables.displayed',

  /**
   * Event fired when welcome buff is hidden
   */
  onboardingDisplayed = 'onboarding.displayed',

  /**
   * Event fired when the language is changed
   */
  languageChanged = 'language.changed',

  /**
   * Event fired when a user joins a private group
   */
  privateGroupJoined = 'groups.join',

  /**
   * Event fired when a user leave a group
   */
  privateGroupLeft = 'groups.leave',

  /**
   * Event fired when a private group is created
   */
  privateGroupCreated = 'groups.create',

  /**
   * Event fired when a stream is found
   */
  streamStarted = 'stream.Started',

  /**
   * Event fired when a user snoozes the buff
   */
  snoozeActivated = 'snooze.Activated',

  /**
   * Event fired when a user unsnoozes the buff
   */
  snoozeDeactivated = 'snooze.Deactivated',

  /**
   * Event fired when a user taps on a reaction
   */
  reactionsTriggered = 'reactions.triggered',

  /**
   * Event fired when a user toggles the reaction snooze
   */
  reactionsSnoozed = 'reactions.snoozed',
}

export type METRIC_PAYLOAD_MAP = {
  [METRIC_EVENTS.onboardingDisplayed]: {
    /**
     * if the user tapped the X to close the Buff
     */
    dismiss: boolean

    /**
     * if the user didn’t vote on it and it was auto hidden
     */
    ignore: boolean
  }
  [METRIC_EVENTS.announcementDisplayed]: {
    /**
     * The announcement id
     */
    id: string
    /**
     * if the user tapped the X to close the Announcement
     */
    dismiss: boolean

    /**
     * Number of seconds announcement was shown for
     */
    duration?: number

    image: {
      /**
       * Boolean to indicate if announcement has an image
       */
      attached: boolean
    }

    link: {
      /**
       * URL of the link for the announcement
       */
      URL?: string

      /**
       * if the user did click on the Announcement Link
       */
      Clicked: boolean
    }

    /**
     * if the announcement has sponsored content
     */
    sponsored: boolean

    sponsor: {
      /**
       * URL of the sponsor link
       */
      URL?: string

      /**
       * if the user did click on the Sponsor Link
       */
      Clicked: boolean
    }
  }
  [METRIC_EVENTS.voteableDisplayed]: {
    /**
     * voteable ID
     */
    id: string

    /**
     * Buff type
     */
    type?: BuffType

    /**
     * The number of available answers on this buff (0 for rating type)
     */
    answers: number

    /**
     * if the user tapped the X to close the Buff
     */
    dismiss: boolean

    /**
     * if the user didn’t vote on it and it was auto hidden
     */
    ignore: boolean

    /**
     * The number of seconds voteable was visible on screen
     */
    duration?: number

    /**
     * if the voteables has sponsored content
     */
    sponsored: boolean

    /**
     * How quickly a user cast their vote
     */
    votedInSeconds: number

    link: {
      /**
       * URL of the sponsor link
       */
      URL?: string

      /**
       * if the user did click on the Sponsor Link
       */
      Clicked: boolean
    }

    /**
     * Distinguish the difference between logged in/anonymous users who voted on the buff
     */
    loggedIn: boolean
  }
  [METRIC_EVENTS.languageChanged]: {
    /**
     * The language that was selected
     */
    language: string
  }
  [METRIC_EVENTS.privateGroupJoined]: {
    /**
     * Group Id
     */
    id: string
  }
  [METRIC_EVENTS.privateGroupLeft]: {
    /**
     * Group Id
     */
    id: string
  }
  [METRIC_EVENTS.privateGroupCreated]: {
    /**
     * Group Id
     */
    id: string
  }
  [METRIC_EVENTS.streamStarted]: {
    /**
     * Stream Id
     */
    streamId: string
  }
  [METRIC_EVENTS.snoozeActivated]: undefined
  [METRIC_EVENTS.snoozeDeactivated]: {
    /**
     * How many buffs the user missed while he was snoozed
     */
    missedBuffs: number

    /**
     * How long it was snoozed (in seconds)
     */
    SnoozedTime: number
  }
  [METRIC_EVENTS.reactionsTriggered]: {
    /**
     * Reaction Id
     */
    reactionId: string
  }
  [METRIC_EVENTS.reactionsSnoozed]: {
    /**
     * True, if the user disabled Reactions, False if he enabled it
     */
    disabled: boolean
  }
}
