import { useParams } from 'react-router-dom'
import { useGetLeaderboardMeta } from '@utils/hooks/useGetLeaderboardMeta'
import { useGetLeaderboardUserStanding } from './useGetLeaderboardUserStanding'
import {
  DecoratedLeaderboardStanding,
  Leaderboard,
} from '@interfaces/leaderboard'

type ProfileParams = {
  entityId: string
  leaderboardId: string
  streamId: string
}

interface UseProfileView {
  standing?: DecoratedLeaderboardStanding
  leaderboardMeta?: Leaderboard
}

interface UseProfileViewArgs {
  groupId?: string
  streamId?: string
}

/**
 * A hook that will load a user from the entityId in the route path
 * @param {UseProfileViewArgs} props
 * @return {UseProfileView}
 */
export const useProfileView = (props?: UseProfileViewArgs): UseProfileView => {
  const { groupId, streamId } = props ?? {}
  const { entityId, leaderboardId } = useParams<ProfileParams>()

  const { data: leaderboardMeta } = useGetLeaderboardMeta(leaderboardId)
  const { data: standingData } = useGetLeaderboardUserStanding(
    streamId,
    leaderboardId,
    entityId,
    groupId
  )

  const standing = standingData

  return { standing, leaderboardMeta }
}
