import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { ReactComponent as LeftIcon } from '@assets/icons/icon_left_chevron.svg'
import { ReactComponent as RightIcon } from '@assets/icons/icon_right_chevron.svg'
import { useValueAsRef } from '@utils/hooks/useValueAsRef'
import { CarouselProps } from './types'

const Carousel = ({
  showLeftArrow,
  showRightArrow,
  className,
  children,
}: CarouselProps) => {
  const tabListRef = useRef<HTMLUListElement>(null)
  const childrenRefs = useRef<Record<string, HTMLElement>>({})
  const observerRef = useRef<IntersectionObserver>()

  const [itemsIntersectionsObj, setItemsIntersectionsObj] = useState<
    Record<number, IntersectionObserverEntry>
  >({})

  const itemsIntersectionsObjRef = useValueAsRef(itemsIntersectionsObj)

  const itemsIntersectionsBoolean = Object.values(
    itemsIntersectionsObj || {}
  )?.map((item) => item?.isIntersecting || false)

  const addChildRef = useCallback((index: number) => {
    return (node: HTMLElement | null) => {
      if (node) {
        childrenRefs.current[index] = node
        observerRef.current?.observe(node)
        return
      }

      observerRef.current?.unobserve(childrenRefs.current[index])
      delete childrenRefs.current[index]
    }
  }, [])

  useEffect(() => {
    const observerOptions = {
      rootMargin: '0px',
      threshold: 0.8,
    }

    const observer = new IntersectionObserver((entries) => {
      const obj = { ...itemsIntersectionsObjRef.current }
      entries.forEach((entry) => {
        const index = Number(entry.target?.getAttribute('data-index'))
        obj[index] = entry
      })
      setItemsIntersectionsObj(obj)
    }, observerOptions)

    for (const key in childrenRefs.current) {
      if (childrenRefs.current.hasOwnProperty(key)) {
        observer.observe(childrenRefs.current[key])
      }
    }

    observerRef.current = observer

    return () => {
      observer.disconnect()
      observerRef.current = undefined
    }
  }, [itemsIntersectionsObjRef])

  const scrollIntoItem = useCallback((index: number) => {
    const children = childrenRefs.current?.[index]
    const left = children?.offsetLeft - children?.offsetWidth
    tabListRef.current?.scrollTo?.({
      behavior: 'smooth',
      left,
    })
  }, [])

  const onLeftClick = () => {
    const index = itemsIntersectionsBoolean?.indexOf(true)
    scrollIntoItem(index - 1)
  }

  const onRightClick = () => {
    const index = itemsIntersectionsBoolean?.lastIndexOf(true)
    scrollIntoItem(index + 1)
  }

  const resolvedChildren = useMemo(
    () => children({ addChildRef, scrollIntoItem }),
    [children, addChildRef, scrollIntoItem]
  )

  const rightArrowVisibility =
    showRightArrow &&
    !itemsIntersectionsObj?.[resolvedChildren?.length - 1]?.isIntersecting &&
    resolvedChildren.length > 1
  const leftArrowVisibility =
    showLeftArrow &&
    !itemsIntersectionsObj?.[0]?.isIntersecting &&
    resolvedChildren.length > 1

  useEffect(() => {
    if (!tabListRef?.current) return
    tabListRef.current?.style.setProperty(
      '--left-fade',
      leftArrowVisibility ? '20%' : '0%'
    )
    tabListRef.current?.style.setProperty(
      '--right-fade',
      rightArrowVisibility ? '80%' : '100%'
    )
  }, [rightArrowVisibility, leftArrowVisibility])

  return (
    <div className="relative w-full">
      {leftArrowVisibility && (
        <button
          onClick={onLeftClick}
          className={`absolute start-0 z-20 w-6 flex items-center justify-center cursor-pointer rounded-md bg-button-tabnavigation-bg -ms-2 h-7 top-2/4 -translate-y-2/4`}
        >
          <LeftIcon
            className="modal__back-button"
            color="var(--color-button-tabnavigation-text)"
            width="50%"
          />
        </button>
      )}

      {rightArrowVisibility && (
        <button
          onClick={onRightClick}
          className={`absolute end-0 z-20 w-6 flex items-center justify-center cursor-pointer rounded-md bg-button-tabnavigation-bg -me-2 h-7 top-2/4 -translate-y-2/4`}
        >
          <RightIcon
            color="var(--color-button-tabnavigation-text)"
            width="50%"
            className="modal__back-button"
          />
        </button>
      )}

      <ul
        className={`flex w-full overflow-auto hide-scrollbar ${className}`}
        ref={tabListRef}
      >
        {resolvedChildren}
      </ul>
    </div>
  )
}

export default Carousel
