import React, { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { StreamContext } from '@services/providers/StreamProvider'
import { UserContext } from '@services/providers/UserProvider'
import Modal from '@components/molecules/Modal'
import { LanguageCode } from '@interfaces/languages'
import { languagesMap } from '@utils/language'
import LanguageOption from '@components/atoms/LanguageOption'
import { METRIC_EVENTS } from '@interfaces/metrics'
import { sendEvent } from '@utils/metrics'
import storage from '@utils/storage'
import { RouteMainPaths } from '@interfaces/navigation'

const Languages = () => {
  const { t, i18n } = useTranslation()
  const { stream } = useContext(StreamContext)
  const navigate = useNavigate()
  const { userLanguage, setUserLanguage } = useContext(UserContext)

  const onGoBack = () => {
    navigate(RouteMainPaths.SETTINGS)
  }

  const onLanguageClick = async (lang: LanguageCode) => {
    try {
      // await updateUserProfile({ locale: lang })
      i18n.changeLanguage(languagesMap[lang].locale)
      setUserLanguage(lang)
      storage.setItem('TEMP_USER_LANGUAGE', lang)
      sendEvent(METRIC_EVENTS.languageChanged, { language: lang })
      onGoBack && onGoBack()
    } catch (err) {}
  }

  return (
    <Modal
      title={t('settings.languageTitle')}
      onGoBack={onGoBack}
      centerTitle
      data-testid="languages"
    >
      <div className="mb-4 overflow-auto hide-scrollbar">
        {stream?.languages.map((lang) => {
          const selected = userLanguage === lang
          return (
            <LanguageOption
              language={lang}
              key={lang}
              onClick={() => onLanguageClick(lang)}
              isSelected={selected}
            />
          )
        })}
      </div>
    </Modal>
  )
}

export default Languages
