import React, { FC, useContext, CSSProperties } from 'react'
import { BuffContext } from '@services/providers/BuffProvider'
import { UserContext } from '@services/providers/UserProvider'
import { ConfigContext } from '@services/providers/ConfigProvider'
import BuffCore from '@components/organisms/BuffCore'
import {
  useStreamConfigPosition,
  StreamConfigTarget,
} from '@utils/hooks/useStreamConfigPosition'
import { getBuffId, isVodVoteable, isVoteable } from '@utils/buff'
import { RoutePaths } from '@interfaces/navigation'
import { WidgetLayout } from '@interfaces/widget'
import { useNavigate } from 'react-router-dom'
import { useFloatingTabsContext } from '@services/providers/FloatingTabsProvider'
import { useSDKFeatures } from '@utils/hooks/useSDKFeatures'

const mobileBuffPositionStyles: CSSProperties = {
  left: 0,
  right: 0,
}

const BuffWrapper: FC = () => {
  const navigate = useNavigate()
  const {
    activeBuff,
    getEngagement,
    removeActiveBuff,
    pubSubEvent,
    voteBuff,
    voteWelcomeBuff,
    selectedAnswerId,
    voteState,
  } = useContext(BuffContext)
  const { widgetConfig, streamConfig } = useContext(ConfigContext)
  const { userLanguage, token } = useContext(UserContext)
  const { isOpen, setOpen } = useFloatingTabsContext()
  const buffPositionStyles = useStreamConfigPosition(
    StreamConfigTarget.VOTEABLE
  )
  const { allowGamePoints, getRedirectPath } = useSDKFeatures()

  const isTwitch =
    widgetConfig.player === 'twitch-mobile' ||
    widgetConfig.player === 'twitch-web'

  const isTV = !!widgetConfig.tvDeviceId

  const castedVote = activeBuff ? voteState[getBuffId(activeBuff)] : undefined

  const votedAnswerId = castedVote?.answerId

  const useLiveVoting = !!streamConfig?.config?.useLiveVoting

  const handleSignup = () => {
    if (!isOpen) {
      setOpen(true)
    }
    navigate(RoutePaths.AUTH, {
      state: {
        onGoBack: () =>
          navigate(getRedirectPath(RoutePaths.LEADERBOARDS, 'leaderboard')),
      },
    })
  }

  const sharedProps = {
    activeLanguage: userLanguage,
    selectedAnswerId,
    activeBuff,
    voteBuff,
    voteWelcomeBuff,
    pubSubEvent,
    votedAnswerId,
    castedVote,
    onSignup: handleSignup,
    onClose: () => removeActiveBuff(0, true),
    engagement:
      activeBuff && (isVoteable(activeBuff) || isVodVoteable(activeBuff))
        ? getEngagement(getBuffId(activeBuff))
        : undefined,
    isAuth: !!token,
    isTwitch,
    isTV,
    allowGamePoints,
    useLiveVoting,
  }

  if (
    widgetConfig.layout === WidgetLayout.PORTRAIT_FULL_LEADERBOARD ||
    widgetConfig.layout === WidgetLayout.PORTRAIT_TOGGLE_LEADERBOARD
  ) {
    const displayOverlay =
      activeBuff &&
      widgetConfig.layout === WidgetLayout.PORTRAIT_FULL_LEADERBOARD

    const fixedPositionStyles = {
      position: 'fixed',
    }

    const shouldAddFixedPositionStyles =
      widgetConfig.layout === WidgetLayout.PORTRAIT_TOGGLE_LEADERBOARD &&
      !!widgetConfig?.fixedPortraitBuffs

    const positionStyle = {
      ...buffPositionStyles,
      ...mobileBuffPositionStyles,
      ...(shouldAddFixedPositionStyles && fixedPositionStyles),
    }

    return (
      <>
        {displayOverlay ? (
          <div className="absolute inset-0 bg-shades-black z-20 opacity-40" />
        ) : null}
        <BuffCore
          {...sharedProps}
          style={positionStyle as CSSProperties}
          widgetLayout={widgetConfig.layout}
        />
      </>
    )
  }

  return <BuffCore {...sharedProps} style={buffPositionStyles} />
}

export default BuffWrapper
