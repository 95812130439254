import React, { FC } from 'react'
import { NotificationDotProps } from './types'

const NotificationDot: FC<NotificationDotProps> = ({
  className,
  bgColor = 'bg-button-danger-bg',
  textColor = 'text-shades-white',
  number,
  'data-testid': testId = 'notification-dot',
}) => {
  const sizeStyle = number
    ? 'h-5 p-2 -top-1 -right-1'
    : 'h-3 w-3 p-1 top-1 right-1'

  return (
    <div
      data-testid={testId}
      className={`inline-flex items-center justify-center z-10 absolute text-center rounded-full ${sizeStyle} ${bgColor} ${className} ${textColor}`}
    >
      {!!number && (
        <span className={`text-xs align-top`}>
          {number > 999 ? '999+' : number}
        </span>
      )}
    </div>
  )
}

export default NotificationDot
