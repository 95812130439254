import { Buff } from '@interfaces/buff'
import { CentrifugoEvent, PubSubEventType } from '@interfaces/pubSub'
import { LanguageCode } from '@interfaces/languages'
import { WidgetConfig } from '@interfaces/widget'
import { Vote } from '@interfaces/vote'
import { BuffQueue } from './buffQueue'

export enum BuffQueueType {
  LIVE = 'LIVE',
  VOD = 'VOD',
  TIME_SYNC = 'TIME_SYNC',
  PRE_STREAM_MODE = 'PRE_STREAM_MODE',
}

export enum BuffQueueEventName {
  ACTIVE_QUEUE_CHANGE = 'ACTIVE_QUEUE_CHANGE',
  VOTE_STATE_CHANGE = 'VOTE_STATE_CHANGE',
}

export type VoteState = Record<string, Vote | undefined>

export interface UseBuffQueueArgs {
  /**
   * The type of queue being used
   */
  type: BuffQueueType

  /**
   * Language selected by the user
   */
  language: LanguageCode

  /**
   * If the queue should be snoozed
   */
  snooze: boolean

  /**
   * If the game has ended
   */
  gameEnded: boolean

  /**
   * Id of the game
   */
  gameId?: string

  /**
   * Id of the stream
   */
  streamId?: string

  /**
   * Id of the current user
   */
  userId?: string

  preStreamBuffFrequency?: WidgetConfig['preStreamBuffFrequency']
}

export interface BuffQueueItem {
  eventId?: string
  eventType: PubSubEventType
  buff: Buff

  /**
   * Relative visible time, used on LIVE streams
   */
  visibleAt?: Date | number

  /**
   * UTC timestamp when buff is shown
   */
  displayedAt?: number

  /**
   * UTC timestamp when buff is voted
   */
  votedAt?: number

  /**
   * Relative closing time, used on LIVE streams
   */
  closesAt?: Date | number

  /**
   * A promise that will run before the buff is set to active in the queue.
   * Allows for any data fetching. E.g engagement data
   *
   * If false is returned item won't be displayed
   */
  beforeActive?: (
    queueItem: BuffQueueItem
  ) => Promise<void | false> | void | false
}

export const ANIMATION_BUFFER = 2000

export interface UseBuffQueueReturn {
  activeQueueItem?: BuffQueueItem
  activeQueueMessage?: CentrifugoEvent
  removeActiveQueueItem: BuffQueue['removeActiveQueueItem']
  addQueueItem: BuffQueue['addQueueItem']
  addQueueItems: BuffQueue['addQueueItems']
  clearQueue: BuffQueue['clearQueue']
  updateGameTime: BuffQueue['updateGameTime']
  handleStreamPubSubMessage: BuffQueue['handleStreamPubSubMessage']
  handleStreamWinnerPubSubMessage: BuffQueue['handleStreamWinnerPubSubMessage']
  getEngagement: BuffQueue['getEngagement']
  handleVODBuffs: BuffQueue['handleVODBuffs']
  handlePreStreamBuffs: BuffQueue['handlePreStreamBuffs']
  // getVoteStatus: BuffQueue['getVoteStatus']
  castVote: BuffQueue['castVote']
  voteState: VoteState
}
