import { LanguageCode } from '@interfaces/languages'
import { Leaderboard } from '@interfaces/leaderboard'

/**
 * Gets the tab name that should be rendered
 * @param {LanguageCode} activeLanguage
 * @param {Leaderboard} meta
 * @return {string}
 */
export const getLeaderboardName = (
  activeLanguage: LanguageCode,
  meta?: Leaderboard
) => {
  if (!meta) {
    return '...'
  }

  const activeLangTitle = meta?.content?.[activeLanguage]?.title
  if (activeLangTitle) {
    return activeLangTitle
  }

  const englishLangTitle = meta?.content?.[LanguageCode.en]?.title
  if (englishLangTitle) return englishLangTitle

  return meta.name
}
