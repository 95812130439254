import { useContext } from 'react'
import { ConfigContext } from '@services/providers/ConfigProvider'
import { RoutePaths } from '@interfaces/navigation'
import { UIFeatures } from '@interfaces/clientConfig'

type Feature = 'friends' | 'profile' | 'leaderboard'

interface useSDKFeaturesReturn {
  allowGamePoints?: boolean
  features?: UIFeatures
  getRedirectPath: (path: string, feature: Feature) => string
}

const defaultSDKFeatures: UIFeatures = {
  friends: {
    enabled: true,
  },
  profile: {
    enabled: true,
  },
  leaderboard: {
    enabled: true,
  },
  chat: {
    enabled: true,
  },
}

type useSDKFeatures = () => useSDKFeaturesReturn

export const useSDKFeatures: useSDKFeatures = () => {
  const { streamConfig, clientConfig } = useContext(ConfigContext)

  const features = clientConfig?.sdk?.features ?? defaultSDKFeatures
  const allowGamePoints = streamConfig?.config?.allowGamePoints ?? true

  const isProfileTabEnabled = !!features?.profile?.enabled
  const isLeaderboardTabEnabled =
    !!features?.leaderboard?.enabled && !!allowGamePoints
  const isFriendsTabEnabled = !!features?.friends?.enabled && !!allowGamePoints

  const checks = {
    profile: isProfileTabEnabled,
    leaderboard: isLeaderboardTabEnabled,
    friends: isFriendsTabEnabled,
  }

  const getRedirectPath = (path: string, feature: Feature) => {
    if (checks[feature]) {
      return path
    }

    return isProfileTabEnabled ? RoutePaths.PROFILE : RoutePaths.SETTINGS
  }

  return {
    allowGamePoints,
    features,
    getRedirectPath,
  }
}
