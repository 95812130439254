import React from 'react'
import { ReactComponent as EmojiIcon } from '@assets/icons/emoji.svg'
import { ReactionsButtonProps } from './types'

const ReactionsButton = ({ onClick }: ReactionsButtonProps) => {
  return (
    <button
      onClick={onClick}
      className="h-12 w-12 rounded-full flex items-center justify-center bg-button-reaction-bg"
    >
      <EmojiIcon className="h-7 w-7 -mt-0.5 text-button-reaction-text" />
    </button>
  )
}

export default ReactionsButton
