export interface Language {
  name: string
  id: number
  enum: LanguageCode
}

export enum LanguageCode {
  en = 'en',
  fr = 'fr',
  es = 'es',
  ar = 'ar',
  pt = 'pt',
  de = 'de',
  it = 'it',
  el = 'el',
  he = 'he',
  ms = 'ms',
  no = 'no',
  pl = 'pl',
  bg = 'bg',
  hu = 'hu',
  id = 'id',
  nl = 'nl',
  sr = 'sr',
}
