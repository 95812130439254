import { setWith } from 'lodash'

const ACCEPTABLE_KEYS = [
  'value',
  'startColor',
  'endColor',
  'rotation',
  'start',
  'middle',
  'end',
  'background',
  'success',
  'error',
  'bar',
]
const REMOVABLE_KEYS = ['value']

const traverseTheme = (obj: any, path: string, res: any) => {
  const result = res
  for (const key in obj) {
    if (key) {
      const value = obj[key]
      const newKey = path ? path + '.' + key : key // joined key with dot
      if (value && typeof value === 'object') {
        traverseTheme(value, newKey, result) // it's a nested object, so do it again
      } else {
        const matched = ACCEPTABLE_KEYS.some((elem) => newKey.includes(elem))
        if (
          value &&
          (typeof value === 'string' || typeof value === 'number') &&
          !!matched
        ) {
          const shouldRemove = REMOVABLE_KEYS.some((elem) =>
            newKey.includes(elem)
          )
          const key = shouldRemove
            ? newKey.split('.').slice(0, -1).join('.')
            : newKey

          if (typeof value === 'number') {
            result[key] = `${Math.round(value)}deg`
          } else {
            result[key] = value // it's not an object, so set the property
          }
        }
      }
    }
  }

  return result
}

export const convertDynamicTheme = (theme: any) => {
  const mappedTheme: any = {}
  const traversedTheme = traverseTheme(theme, '', {})

  Object.keys(traversedTheme ?? {}).forEach((key: any) => {
    setWith(mappedTheme, key, traversedTheme[key], Object)
  })

  return mappedTheme
}
