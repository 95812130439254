import React, { FC } from 'react'
import { ReactComponent as Info } from '@assets/icons/info.svg'
import { InfoTextProps } from './types'

const InfoText: FC<InfoTextProps> = ({
  text,
  className,
  textClassName,
  iconClassName,
  Icon = Info,
  color = 'floatingPanel-semantic-error',
  'data-testid': testId = 'info-text',
}) => {
  if (!text) return null

  const colorVariable = `var(--color-${color})`

  return (
    <div data-testid={testId} className={`flex mt-1 ${className}`}>
      <Icon
        className={`flex-shrink-0 w-4 h-4 me-1 ${iconClassName}`}
        data-testid={`${testId}_icon`}
        color={colorVariable}
      />
      <span
        data-testid={`${testId}_text`}
        style={{ color: colorVariable }}
        className={`text-xs sb-break-words line-clamp-2 ${textClassName}`}
      >
        {text}
      </span>
    </div>
  )
}

export default InfoText
