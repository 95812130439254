import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import IconButton from '@components/atoms/IconButton'
import { ReactComponent as SoundOnIcon } from '@assets/icons/sound-on.svg'
import { ReactComponent as SoundOffIcon } from '@assets/icons/sound-off.svg'
import { ReactComponent as SnoozeIcon } from '@assets/icons/snooze.svg'
import { ReactComponent as LineIcon } from '@assets/icons/line.svg'
import { ReactComponent as EmojiIcon } from '@assets/icons/emoji.svg'
import { ReactComponent as LanguageIcon } from '@assets/icons/language.svg'
import { IStreamSettingsProps } from './types'
import { languagesMap } from '@utils/language'

const StreamSettings: FC<IStreamSettingsProps> = ({
  muted,
  showMute = true,
  showReactions = true,
  reactionEnabled,
  snoozed,
  locale,
  onLanguageClick,
  onMuteClick,
  onReactionsClick,
  onSnoozeClick,
  disabled = false,
  disabledSounds = false,
}) => {
  const { t } = useTranslation()

  const handleSnoozeClick = () => {
    onSnoozeClick && onSnoozeClick(!snoozed)
  }

  const handleMuteClick = () => {
    onMuteClick && onMuteClick(!muted)
  }

  const handleReactionsClick = () => {
    onReactionsClick && onReactionsClick(!reactionEnabled)
  }

  const handleLanguageClick = () => {
    onLanguageClick && onLanguageClick()
  }

  return (
    <div
      data-testid="stream-settings"
      className="flex flex-col justify-between mt-3 rounded-md"
    >
      <IconButton
        className="text-left mb-3"
        active={snoozed}
        content={
          <SnoozeIcon className="w-4 h-4 text-floatingPanel-icons-text" />
        }
        disabled={disabled}
        text={t('settings.snooze')}
        data-testid="stream-settings__snooze"
        onClick={handleSnoozeClick}
      />
      {showMute && (
        <IconButton
          className="text-left mb-3"
          active={!muted}
          disabled={disabled || disabledSounds}
          content={
            muted ? (
              <SoundOffIcon
                className="w-4 h-4 text-floatingPanel-icons-text"
                data-testid="stream-settings__sound-off"
              />
            ) : (
              <SoundOnIcon
                className="w-4 h-4 text-floatingPanel-icons-text"
                data-testid="stream-settings__sound-on"
              />
            )
          }
          text={t('settings.sound')}
          onClick={handleMuteClick}
          data-testid="stream-settings__mute"
        />
      )}

      {showReactions && (
        <IconButton
          className="text-left mb-3"
          active={reactionEnabled}
          content={
            <EmojiIcon className="h-4 w-4 text-floatingPanel-icons-text" />
          }
          text={t('reactions.reactions')}
          onClick={handleReactionsClick}
          data-testid="stream-settings__reactions"
        />
      )}

      <IconButton
        fullRowClickable
        className="text-left mb-3"
        active={false}
        content={
          <LanguageIcon className="w-4 h-4 text-floatingPanel-icons-text" />
        }
        disabled={disabled}
        text={languagesMap[locale].name}
        rightActionIcon={
          <LineIcon className="w-4 h-4 modal__back-button text-floatingPanel-icons-text" />
        }
        onClick={handleLanguageClick}
        data-testid="stream-settings__language"
      />
    </div>
  )
}

export default StreamSettings
