import { v4 } from 'uuid'
import { DecoratedLeaderboardStanding } from '@interfaces/leaderboard'
import { PlayerPositionProps } from '@components/atoms/PlayerPosition'
import { getDecoratedLeaderboardStandingByEntityId } from '@services/requests/leaderboards'
import { getLeaderboardMetaById } from '@services/requests/leaderboard-v2'
import { StreamWinner } from '@interfaces/streamWinner'
import { getDisplayDuration } from '@utils/buff'
import { WidgetLayout } from '@interfaces/widget'
import { logError } from '@utils/log'

const MIN_HIGHLIGHT_FINISH = 3
export const MAX_HIGHLIGHT_FINISH = 50

export const getPlayerPositionProps = (
  layout: WidgetLayout,
  userStandingId?: string,
  standing?: DecoratedLeaderboardStanding,
  hideProfilePicture?: boolean
): PlayerPositionProps | false => {
  if (!standing) return false
  const isUserStanding = standing.userId === userStandingId
  const inHighlightRange =
    (standing?.position ?? Infinity) <= MAX_HIGHLIGHT_FINISH

  return {
    hideProfilePicture,
    'data-testid': 'player-rank',
    profilePicture: standing?.profilePicture ?? '',
    ranking: standing.position,
    points: standing.points || 0,
    name: standing.displayName ?? '',
    displayConfetti: isUserStanding && inHighlightRange,
    displayLargeImage: isUserStanding && layout === WidgetLayout.DESKTOP,
  }
}

export const shouldDisplayStreamWinnersSidePanel = (
  userStanding?: DecoratedLeaderboardStanding
): boolean => {
  if (!userStanding) return false
  return (
    (userStanding.position !== undefined &&
      userStanding.position > MIN_HIGHLIGHT_FINISH) ||
    userStanding.percentile !== undefined
  )
}

/**
 * Creates and returns a stream winner object to display
 *
 * @param {string} leaderboardId
 * @param {string} winnerIds
 * @param {string | undefined} userId
 * @param {string | undefined} streamId
 * @return {Promise<StreamWinner | undefined>}
 */
export const getStreamWinner = async (
  leaderboardId: string,
  winnerIds: string[],
  userId?: string,
  streamId?: string
) => {
  try {
    const leaderboard = await getLeaderboardMetaById(leaderboardId, streamId)

    const standingsPromises = winnerIds.map((winnerId: string) => {
      return getDecoratedLeaderboardStandingByEntityId(
        streamId,
        leaderboardId,
        winnerId
      )
    })

    const userStandingPromise = userId
      ? getDecoratedLeaderboardStandingByEntityId(
          streamId,
          leaderboardId,
          userId
        )
      : Promise.resolve(undefined)

    const [userStanding, ...standings] = await Promise.allSettled([
      userStandingPromise,
      ...standingsPromises,
    ])

    const standingsResult = standings.every(
      (standing) => standing.status === 'fulfilled'
    )
      ? (standings.map((standing) => {
          if (standing.status === 'fulfilled') {
            return standing.value
          }
          return undefined
        }) as DecoratedLeaderboardStanding[])
      : []

    const userStandingResult =
      userStanding.status === 'fulfilled' ? userStanding.value : undefined

    const streamWinner: StreamWinner = {
      standings: standingsResult,
      userStanding: userStandingResult,
      streamWinnerId: v4(),
      leaderboard,
    }
    streamWinner.displayDurationSeconds = getDisplayDuration(streamWinner)
    return streamWinner
  } catch (error) {
    logError(error)
  }
}
