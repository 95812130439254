import { GetFriendGroupLeaderboard } from '@interfaces/friendGroups'
import {
  DecoratedLeaderboardStanding,
  LeaderboardStandingMethod,
} from '@interfaces/leaderboard'

/**
 * Transforms gql friends group leaderboard to existing interface
 * @param {string} leaderboardId Temporary as can't query for leaderboard is in GQL
 * @param {GetFriendGroupLeaderboard} data Data to transform
 * @return {DecoratedLeaderboardStanding[]}
 */
export const transformGQLFriendsGroupLeaderboards = (
  leaderboardId: string,
  { data }: GetFriendGroupLeaderboard
) => {
  const leaderboards: Record<string, DecoratedLeaderboardStanding[]> = {}

  data.user.friendGroups.forEach((group) => {
    const standings = group.leaderboard.standings

    const newStandings = standings.map((standing) => {
      return {
        userID: standing.user.id,
        method: LeaderboardStandingMethod.RANKED,
        points: standing.points,
        rank: standing.rank,
        position: standing.rank,
        percentile: 0,
        displayName: standing.user.displayName,
        profilePicture: standing.user.profilePicture,
      }
    })

    // @ts-ignore
    // TODO: Fix this
    leaderboards[leaderboardId] = newStandings
  })

  return leaderboards[leaderboardId]
}

export const friendsGroupIdPrivateRouteRegex =
  /friends\/([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})/

export const friendsGroupIdRouteRegex =
  /friends\/.*([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})/
