import React, {
  useEffect,
  useState,
  createContext,
  FC,
  useContext,
} from 'react'
import { getGameById } from '@services/requests/games'
import { Game } from '@interfaces/game'
import {
  isPubSubEvent,
  formatEvent,
  isProviderPubSubEvent,
} from '@utils/pubSub'
import { DebugContext, IDebug } from '@services/providers/DebugProvider'
import { PubSubEventType } from '@interfaces/pubSub'
import { getQueryKey, useActiveStream } from '@utils/hooks/useActiveStream'
import { queryClient } from '@utils/reactQuery'
import { useWebSocketClient } from '@utils/hooks/useWebSocketClient'
import {
  sendEvent,
  initAmplitude,
  destroyAmplitude,
  setGroup,
} from '@utils/metrics'
import { METRIC_EVENTS } from '@interfaces/metrics'
import { logError } from '@utils/log'
import { StreamContextProps, StreamProviderProps } from './types'

export const StreamContext = createContext<StreamContextProps>(
  {} as StreamContextProps
)

export const StreamProvider: FC<StreamProviderProps> = ({
  children,
  streamId,
  streamSourceId,
  twitchChannelId,
  clientName,
}) => {
  const {
    subscribe,
    unsubscribe,
    disconnectClient,
    connectClient,
    isConnected,
  } = useWebSocketClient()
  const [games, setGames] = useState<Game[]>([])
  const { setDebugInfo } = useContext(DebugContext)
  const { data: stream } = useActiveStream({
    streamId,
    streamSourceId,
    twitchChannelId,
    options: {
      onSuccess: () => {
        if (!isConnected) {
          connectClient()
        }
      },
      // Function will be called when hook fails to get a stream
      retry: () => {
        if (isConnected) {
          disconnectClient()
        }

        return true
      },
    },
  })

  const props: StreamContextProps = {
    stream,
    games,
  }

  /**
   * Disconnect client when component unmounts
   */
  useEffect(() => {
    return () => {
      const queryKey = getQueryKey(streamId, streamSourceId, twitchChannelId)
      queryClient.removeQueries(queryKey)
      return disconnectClient()
    }
  }, [disconnectClient])

  useEffect(() => {
    const streamId = stream?.id ?? ''
    if (streamId) {
      initAmplitude()
      setGroup('streamID', streamId)
      setGroup('client', clientName ?? '')

      if (twitchChannelId) {
        setGroup('twitchChannelID', twitchChannelId)
      }

      sendEvent(METRIC_EVENTS.streamStarted, { streamId })
    } else {
      destroyAmplitude()
    }

    return destroyAmplitude
  }, [stream?.id, clientName, twitchChannelId])

  useEffect(() => {
    if (!stream) return

    setDebugInfo((info) => {
      return {
        ...info,
        stream,
      }
    })
  }, [stream, clientName, setDebugInfo])

  useEffect(() => {
    const gameIds = stream?.gameIds
    if (!gameIds || gameIds.length === 0) return
    ;(async () => {
      try {
        const promises = gameIds.map((id) => {
          return getGameById(id)
        })
        const games = await Promise.all(promises)
        setGames(games)
        setDebugInfo((info: IDebug) => ({
          ...info,
          games,
        }))
      } catch (error) {
        logError(error)
      }
    })()
  }, [setDebugInfo, stream?.gameIds])

  useEffect(() => {
    const streamSourceChannel =
      !streamId && streamSourceId ? `externalId.${streamSourceId}` : undefined

    if (!streamSourceChannel || !stream) return

    const subscription = subscribe(streamSourceChannel, (message) => {
      const event = formatEvent(message)
      if (!isPubSubEvent(event) || !isProviderPubSubEvent(event)) return
      if (
        event.name === PubSubEventType.PROVIDER_UPDATE ||
        event.name === PubSubEventType.PROVIDER_REMOVE
      ) {
        const queryKey = `streams.source-web.${streamSourceId}`
        queryClient.removeQueries({ queryKey })
        queryClient.invalidateQueries(queryKey)
      }
    })

    return () => {
      !!subscription && unsubscribe(subscription)
    }
  }, [subscribe, unsubscribe, streamId, streamSourceId, stream])

  return (
    <StreamContext.Provider value={props}>{children}</StreamContext.Provider>
  )
}
