import React, { FC, useState, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import Button, { ButtonBackground } from '@components/atoms/Button'
import { ReactComponent as ResetIcon } from '@assets/icons/code-reset.svg'
import { ReactComponent as CopyIcon } from '@assets/icons/code-copy.svg'
import { IShareCodeProps } from './types'

const ShareCode: FC<IShareCodeProps> = ({
  showRefreshCodeButton = false,
  code,
  subtext,
  onRotateCode,
  loading = false,
  isGroupLocked = false,
}) => {
  const { t } = useTranslation()
  const [codeInputValue, setCodeInputValue] = useState('')
  const [displayCopiedText, setDisplayCopiedText] = useState<boolean>(false)
  const inputRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (!code) return setCodeInputValue('')
    setCodeInputValue(code.replace(/\W/gi, '').replace(/(.{4})/g, '$1 '))
  }, [setCodeInputValue, code])

  const handleSuccessCopy = () => {
    setDisplayCopiedText(true)
    setTimeout(() => {
      setDisplayCopiedText(false)
    }, 2000)
  }

  const fallbackCopyTextToClipboard = () => {
    if (
      document.queryCommandSupported &&
      document.queryCommandSupported('copy') &&
      inputRef?.current
    ) {
      const originalValue = inputRef.current.value
      inputRef.current.value = inputRef.current.value.replace(/\s/g, '')
      inputRef.current.select()
      try {
        document.execCommand('copy')
        inputRef.current.value = originalValue
        handleSuccessCopy()
      } catch (err) {
        console.warn('Copy to clipboard failed.', err)
      }
    }
  }

  const copyTextToClipboard = async () => {
    try {
      const permissionName = 'clipboard-write' as PermissionName
      const permissionStatus = await navigator.permissions.query({
        name: permissionName,
      })

      if (permissionStatus.state !== 'granted') {
        fallbackCopyTextToClipboard()
        return
      }
    } catch {
      fallbackCopyTextToClipboard()
      return
    }

    try {
      await navigator.clipboard.writeText(codeInputValue.replace(/\s/g, ''))
      handleSuccessCopy()
    } catch (err) {
      console.warn('Copy to clipboard failed.', err)
    }
  }

  const resetDisabled = !code || loading
  const resetColour = resetDisabled
    ? 'rgba(var(--color-button-primary-text-rgb), 0.55)'
    : 'var(--color-button-primary-text'

  return (
    <div className="flex flex-col">
      <div className="flex flex-col mb-1.5 bg-floatingPanel-friends-bg p-2 rounded">
        <label
          htmlFor="code"
          className="mb-2 text-xs font-semibold text-floatingPanel-friends-text"
        >
          {t('friends.inviteCode')}
        </label>
        <div className="flex">
          <div className="relative flex-1 me-2">
            <input
              ref={inputRef}
              id="code"
              type="text"
              name="code"
              value={codeInputValue}
              className={`text-center text-shades-black py-1 ps-2 block text-sm rounded me-2 border border-floatingPanel-accent-default-bg bg-shades-white h-9 w-full font-semibold`}
              readOnly={true}
            />
            {displayCopiedText && (
              <p className="text-shades-black py-1 ps-2 text-sm rounded me-2 border border-floatingPanel-accent-default-bg bg-shades-white h-9 w-full absolute inset-0 flex items-center justify-center font-semibold">
                {t('general.copied')}
              </p>
            )}
            {isGroupLocked && (
              <p
                className="text-shades-black py-1 ps-2 text-sm rounded me-2 border border-floatingPanel-accent-default-bg bg-shades-white h-9 w-full absolute inset-0 flex items-center justify-center font-semibold"
                data-testid="shareCode__locked-text"
              >
                {t('friends.groupIsLocked')}
              </p>
            )}
            {!isGroupLocked && (
              <button
                className="absolute top-0 bottom-0 right-0 px-1.5"
                type="button"
                disabled={!code}
                aria-label={t('general.copy')}
                onClick={copyTextToClipboard}
                data-testid="shareCode__button-copy"
              >
                <CopyIcon
                  fill="var(--color-shades-black)"
                  className="w-6 h-6"
                />
              </button>
            )}
          </div>

          {showRefreshCodeButton && (
            <Button
              disabled={resetDisabled || isGroupLocked}
              background={ButtonBackground.PRIMARY}
              type="button"
              className="flex justify-center items-center px-0 py-2 w-9 h-9 flex-shrink-0"
              onClick={onRotateCode}
              data-testid="shareCode__button-refresh"
            >
              <ResetIcon fill={resetColour} className="w-5 h-5" />
            </Button>
          )}
        </div>
      </div>
      {subtext && (
        <span className="text-center text-floatingPanel-default-text text-xs">
          {subtext}
        </span>
      )}
    </div>
  )
}

export default ShareCode
