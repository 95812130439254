import {
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'
import { ConfigContext } from '@services/providers/ConfigProvider'
import { UserContext } from '@services/providers/UserProvider'
import { LeaderboardContext } from '@services/providers/LeaderboardProvider'
import defaultAvatar from '@assets/default-user-avatar_2.png'
import { sendOTP, updateUser } from '@services/requests/auth'
import { useTranslation } from 'react-i18next'
import { RoutePaths } from '@interfaces/navigation'
import { useNavigate } from 'react-router-dom'
import { queryClient } from '@utils/reactQuery'
import { useSDKFeatures } from './useSDKFeatures'

interface UseUpdateUserReturn {
  /**
   * User's profile picture
   */
  profilePhoto?: string

  /**
   * User's displayName
   */
  name?: string

  /**
   * Loading state of name change api call
   */
  loadingName?: boolean

  /**
   * Error state of name change api call
   */
  errorName?: string

  /**
   * User's email
   */
  email?: string

  /**
   * Loading state of email change api call
   */
  loadingEmail?: boolean

  /**
   * Error state of email change api call
   */
  errorEmail?: string

  /**
   * Can user change photo
   */
  isPhotoChangeEnabled?: boolean

  /**
   * Function which calls api to change user's displayName
   */
  handleNameChangeRequest: (name: string, initialName: string) => Promise<void>

  /**
   * Function which changes name
   */
  setName: Dispatch<SetStateAction<string>>

  /**
   * Function which calls api to change user's email
   */
  handleEmailChangeRequest: (
    email: string,
    initialEmail: string
  ) => Promise<void>

  /**
   * Function which changes email
   */
  setEmail: Dispatch<SetStateAction<string>>

  /**
   * If the edit name input should be disabled
   */
  editNameInputDisabled: boolean

  /**
   * If the edit email input should be disabled
   */
  editEmailInputDisabled: boolean
}

type UseUpdateUser = () => UseUpdateUserReturn

export const useUpdateUser: UseUpdateUser = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { streamConfig, widgetConfig } = useContext(ConfigContext)
  const { profile, refetchProfile } = useContext(UserContext)
  const { leaderboardIds } = useContext(LeaderboardContext)
  const { getRedirectPath } = useSDKFeatures()

  const [name, setName] = useState('')
  const [loadingName, setLoadingName] = useState(false)
  const [errorName, setErrorName] = useState('')

  const [email, setEmail] = useState('')
  const [loadingEmail, setLoadingEmail] = useState(false)
  const [errorEmail, setErrorEmail] = useState('')

  const isTwitch =
    widgetConfig?.player === 'twitch-mobile' ||
    widgetConfig?.player === 'twitch-web'

  const image = streamConfig?.config?.hideProfilePicture
    ? defaultAvatar
    : profile?.profilePicture || defaultAvatar

  const ENABLE_EDIT_PROFILE_PICTURE =
    process.env.EDIT_PROFILE_PICTURE === 'true'

  const disablePhotoChange =
    !!streamConfig?.config.hideProfilePicture ||
    !ENABLE_EDIT_PROFILE_PICTURE ||
    isTwitch

  const handleOTPLoginSuccess = useCallback(() => {
    navigate(getRedirectPath(RoutePaths.PROFILE, 'profile'), { replace: true })
  }, [navigate])

  const editNameInputDisabled =
    streamConfig?.config.allowProfileEdit !== true || isTwitch

  const editEmailInputDisabled = false

  const handleNameChangeRequest = useCallback(
    async (displayName: string, initialDisplayName: string) => {
      try {
        if (!profile?.id) return
        setErrorName('')
        setLoadingName(true)
        await updateUser(profile?.id, displayName)
        refetchProfile()

        // Add delay for 2 seconds to invalidate leaderboard queries because of the delay in updating the displayName in backend
        for (let i = 0; i < Number(leaderboardIds?.length); i++) {
          setTimeout(() => {
            queryClient.invalidateQueries({
              queryKey: ['leaderboard', leaderboardIds?.[i]],
              refetchInactive: true,
            })
            queryClient.invalidateQueries({
              queryKey: [`leaderboard.${leaderboardIds?.[i]}`],
              refetchInactive: true,
            })
          }, 3000)
        }
      } catch (err) {
        setName(initialDisplayName)
        const errMessage = t('error.unknown')
        setErrorName(errMessage)
      } finally {
        setLoadingName(false)
      }
    },
    [profile, leaderboardIds, t, refetchProfile]
  )

  const handleEmailChangeRequest = useCallback(
    async (email: string, initialEmail: string) => {
      try {
        if (email === initialEmail) return
        setErrorEmail('')
        setLoadingEmail(true)
        await sendOTP(email)
        navigate(`${RoutePaths.PASSCODE}?email=${encodeURIComponent(email)}`, {
          state: {
            onSuccess: handleOTPLoginSuccess,
          },
        })
      } catch (err) {
        setName(initialEmail)
        const errMessage = t('error.unknown')
        setErrorEmail(errMessage)
      } finally {
        setLoadingEmail(false)
      }
    },
    [handleOTPLoginSuccess, navigate, t]
  )

  useEffect(() => {
    if (profile?.displayName) {
      setName(profile?.displayName)
    }

    if (profile?.email) {
      setEmail(profile?.email)
    }
  }, [profile])

  return {
    profilePhoto: image,
    isPhotoChangeEnabled: !disablePhotoChange && !editNameInputDisabled,
    name,
    loadingName,
    errorName,
    email,
    loadingEmail,
    errorEmail,
    handleNameChangeRequest,
    setName,
    handleEmailChangeRequest,
    setEmail,
    editNameInputDisabled,
    editEmailInputDisabled,
  }
}
