import React, { useContext, useEffect, useReducer, useRef } from 'react'
import Button, { ButtonBackground } from '@components/atoms/Button'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import ReactCanvasConfetti from 'react-canvas-confetti'
import { ConfigContext } from '@services/providers/ConfigProvider'
import { ReactComponent as TrophyIcon } from '@assets/trophy.svg'
import {
  LeaderboardActionTypes,
  LeaderboardContext,
} from '@services/providers/LeaderboardProvider'
import { WidgetLayout } from '@interfaces/widget'
import { RoutePaths } from '@interfaces/navigation'
import { PurchaseSuccessState } from './types'
import { TRANSACTION_COMPLETED } from '../../../constants'

const PurchaseSuccess = () => {
  const { widgetConfig } = useContext(ConfigContext)
  const { activeLeaderboardId, dispatch } = useContext(LeaderboardContext)
  const navigate = useNavigate()
  const location = useLocation()
  const { t } = useTranslation()
  const confettiRef = useRef<any>()

  const { points } = (location?.state || {}) as PurchaseSuccessState

  const handleContinue = () => {
    const paymentLeaderboardId = localStorage.getItem(TRANSACTION_COMPLETED)

    if (!!paymentLeaderboardId) {
      dispatch({
        type: LeaderboardActionTypes.ACTIVE_LEADERBOARD,
        payload: {
          leaderboardId: paymentLeaderboardId,
        },
      })
      localStorage.removeItem(TRANSACTION_COMPLETED)
      navigate(RoutePaths.LEADERBOARDS)
    }
  }

  useEffect(() => {
    const fire = () => {
      const fireShot = (particleRatio: number, options: any) => {
        confettiRef.current &&
          confettiRef.current({
            ...options,
            origin: { y: 0.7 },
            particleCount: Math.floor(200 * particleRatio),
            scalar: 0.6,
          })
      }

      fireShot(0.25, {
        spread: 26,
        startVelocity: 55,
      })

      fireShot(0.2, {
        spread: 60,
      })

      fireShot(0.35, {
        spread: 100,
        decay: 0.91,
        scalar: 0.8,
      })

      fireShot(0.1, {
        spread: 120,
        startVelocity: 25,
        decay: 0.92,
        scalar: 1.2,
      })

      fireShot(0.1, {
        spread: 120,
        startVelocity: 45,
      })

      setTimeout(() => {
        fireShot(0.25, {
          spread: 26,
          startVelocity: 55,
        })

        fireShot(0.2, {
          spread: 60,
        })

        fireShot(0.35, {
          spread: 100,
          decay: 0.91,
          scalar: 0.8,
        })

        fireShot(0.1, {
          spread: 120,
          startVelocity: 25,
          decay: 0.92,
          scalar: 1.2,
        })

        fireShot(0.1, {
          spread: 120,
          startVelocity: 45,
        })
      }, 4500)
    }

    fire()
  }, [confettiRef])

  const heightStyle =
    widgetConfig?.layout === WidgetLayout.PORTRAIT_FULL_LEADERBOARD
      ? 'max-h-[300px]'
      : ''

  return (
    <div className="absolute inset-0 z-20 bg-[#1F1F23F2] flex flex-col justify-center align-center">
      <div
        className={`h-full flex flex-col m-4 p-2 text-floatingPanel-default-text flex-1 items-center bg-[#1F1F23] ${heightStyle}`}
      >
        <ReactCanvasConfetti
          className="absolute left-0 right-0 bottom-0 -top-1/4"
          refConfetti={(confetti) => {
            confettiRef.current = confetti
          }}
          style={{
            width: '100%',
            height: '125%',
            margin: 'auto',
          }}
        />

        <span
          className={`text-lg mb-4 font-bold sb-break-words text-center normal-case text-shades-white`}
        >
          {t('leaderboard.purchaseCompleted')}
        </span>
        <TrophyIcon className="flex-shrink-0" />
        <p
          data-testid="purchase-success-subtitle"
          className="mt-4 text-center text-sm font-bold mb-1 text-shades-white"
        >
          {points
            ? t('leaderboard.purchaseCompletedDescriptionWithPoints', {
                points,
              })
            : t('leaderboard.purchaseCompletedDescription')}
        </p>

        <Button
          data-testid="purchase-success-button"
          onClick={handleContinue}
          background={ButtonBackground.PRIMARY}
          className="mt-auto w-full z-10"
        >
          {t('general.continue')}
        </Button>
      </div>
    </div>
  )
}

export default PurchaseSuccess
