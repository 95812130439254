import React, {
  FC,
  useContext,
  useMemo,
  CSSProperties,
  useEffect,
  useState,
} from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import { BuffType, Buff } from '@interfaces/buff'
import { ReactComponent as IconClose } from '@assets/icon_misc_close.svg'
import Timebar from '@components/atoms/Timebar'
import InfoBubble from '@components/atoms/InfoBubble'
import AuthorContainer from '@components/atoms/AuthorContainer'
import BuffSwitcher from '@components/molecules/BuffSwitcher'
import LateralImage from '@components/atoms/LateralImage'
import StreamWinnersSidePanel from '@components/molecules/StreamWinnersSidePanel'
import { useBuffInfoBubble } from '@utils/hooks/useBuffInfoBubble'
import { mapTheme } from '@themes/utils'
import { shouldDisplayStreamWinnersSidePanel } from '@utils/streamWinners'
import { VoteableLayout } from '@interfaces/voteable'
import { LanguageCode } from '@interfaces/languages'
import { ThemeContext } from '@services/providers/ThemeProvider'
import {
  getBuffType,
  getBuffTitleText,
  getAuthorContent,
  isStreamWinner,
  isAnnouncement,
  getDisplayDuration,
  getLateralImage,
  getSponsorContent,
  getBuffId,
  isVoteable,
  isVodVoteable,
  isVodAnnouncement,
} from '@utils/buff'
import { PubSubEventType } from '@interfaces/pubSub'
import BuffSponsorContent from '@components/atoms/BuffSponsorContent'
import usePreviousValue from '@utils/hooks/usePreviousValue'
import { useValueAsRef } from '@utils/hooks/useValueAsRef'
import { WidgetLayout } from '@interfaces/widget'
import { queryClient } from '@utils/reactQuery'
import { useLiveVoting as useLiveVotingHook } from '@utils/hooks/useLiveVoting'
import { IBuffCore } from './types'
import getGradientContainerStyles from '@utils/getContainerStyles'

const variants = {
  enter: () => {
    return {
      y: 0,
      // We can change enter animation position to be from bottom -> up or left -> right by changing x and y values.
      // x: 0,
      opacity: 1,
      transition: {
        delay: 0.1,
        type: 'spring',
        damping: 10,
        duration: 5,
      },
    }
  },
  exit: () => {
    return {
      y: 100,
      // x: 100,
      opacity: 0,
      transition: {
        type: 'spring',
        damping: 10,
        duration: 5,
      },
    }
  },
}

const ANNOUNCEMENT_MIN_WIDTH_MAX_CHARS = 38

const getBuffContentWidth = (
  activeLanguage: LanguageCode,
  widgetLayout: WidgetLayout,
  buff?: Buff
) => {
  if (!buff) return ''

  // Forces mobile to have buffs at 320px wide
  if (
    widgetLayout === WidgetLayout.PORTRAIT_FULL_LEADERBOARD ||
    widgetLayout === WidgetLayout.PORTRAIT_TOGGLE_LEADERBOARD
  ) {
    if (isStreamWinner(buff)) {
      return 'w-59'
    }

    if (isAnnouncement(buff) || isVodAnnouncement(buff)) {
      return 'mx-2 shrink'
    }
    return 'w-80'
  }

  if (isAnnouncement(buff) || isVodAnnouncement(buff)) {
    const text = getBuffTitleText(activeLanguage, buff)
    return text.length <= ANNOUNCEMENT_MIN_WIDTH_MAX_CHARS ? 'w-59' : 'w-83'
  }

  if (isStreamWinner(buff)) {
    return 'w-98.5'
  }

  const buffType = getBuffType(buff)
  if (!buffType) return ''

  const smallVoteable =
    buff.answers.length <= 3 &&
    buffType !== BuffType.EMOJI &&
    buff.layout !== VoteableLayout.VOTEABLE_LAYOUT_VERTICAL

  const hasSponsorContent =
    getSponsorContent(activeLanguage, buff) !== undefined

  if (hasSponsorContent) {
    return smallVoteable ? 'w-76.5' : 'w-106.5'
  }

  return smallVoteable ? 'w-64' : 'w-94'
}

const BuffCore: FC<IBuffCore> = ({
  activeBuff: buff,
  selectedAnswerId,
  votedAnswerId,
  castedVote,
  pubSubEvent,
  onClose,
  onSignup,
  voteBuff,
  voteWelcomeBuff,
  style,
  activeLanguage: activeLanguageProp,
  engagement,
  isAuth = true,
  isTwitch = false,
  isTV = false,
  widgetLayout = WidgetLayout.DESKTOP,
  allowGamePoints = true,
  useLiveVoting = false,
}) => {
  const prevBuff = usePreviousValue(buff)
  const activeLanguageRef = useValueAsRef(activeLanguageProp)
  const [activeLanguage, setActiveLanguage] = useState(
    activeLanguageRef.current
  )

  const { buffTheme } = useContext(ThemeContext)

  const borderStyles = ''

  // TODO: challenge: either all have opacity or non IF lighttheme then opacity is not scalable
  const bgStyles = 'bg-gradient-to-e'

  let borderRadiusStyle = 'rounded-md'

  const hasLateralImage = getLateralImage(activeLanguage, buff) !== null

  if (hasLateralImage) {
    borderRadiusStyle += ' rounded-te-none rounded-be-none'
  }

  const buffThemeVars = useMemo(() => {
    return buffTheme ? mapTheme(buffTheme) : {}
  }, [buffTheme])

  const children = (
    <BuffSwitcher
      activeLanguage={activeLanguage}
      activeBuff={buff}
      pubSubEvent={pubSubEvent}
      voteBuff={voteBuff}
      voteWelcomeBuff={voteWelcomeBuff}
      selectedAnswerId={selectedAnswerId}
      votedAnswerId={votedAnswerId}
      engagement={engagement}
      widgetLayout={widgetLayout}
      allowGamePoints={allowGamePoints}
      useLiveVoting={useLiveVoting}
    />
  )

  const { authorName, authorPicture } = getAuthorContent(activeLanguage, buff)
  const { useLiveVotingEnabled } = useLiveVotingHook({
    buff,
    pubSubEventType: pubSubEvent,
    enabled: useLiveVoting,
  })

  const buffId = buff && getBuffId(buff)
  const type = getBuffType(buff)
  const text = getBuffTitleText(activeLanguage, buff)
  const duration = getDisplayDuration(buff, pubSubEvent)
  const [currentTime, setCurrentTime] = useState<number>(duration)

  const infoBubbleProps = useBuffInfoBubble(
    buff,
    pubSubEvent,
    votedAnswerId ?? selectedAnswerId,
    activeLanguage,
    castedVote,
    isAuth,
    isTwitch,
    allowGamePoints,
    currentTime
  )

  const paddingMap = {
    announcement: 'p-2',
    buffs: 'px-2 pt-2 pb-2',
    emojiPublished: 'px-2 py-2',
    star: 'px-2 py-2',
    streamWinners: 'pt-2 pb-4 px-4',
  }

  let padding = paddingMap.buffs
  if (type === BuffType.ANNOUNCEMENT) {
    padding = paddingMap.announcement
  } else if (
    type === BuffType.EMOJI &&
    pubSubEvent === PubSubEventType.VOTEABLE_OPEN
  ) {
    padding = paddingMap.emojiPublished
  } else if (type === BuffType.STAR) {
    padding = paddingMap.star
  } else if (type === BuffType.STREAM_WINNER) {
    padding = paddingMap.streamWinners
  }

  const handleClose = () => {
    onClose && onClose()
  }

  const showTimebarClose = !isTV

  const displayAuthorContainer = buff && !isStreamWinner(buff)

  const timebarPubSubEventTypes = [
    PubSubEventType.VOTEABLE_OPEN,
    PubSubEventType.ANNOUNCEMENT_OPEN,
    PubSubEventType.welcomeBuff,
  ]

  const showTimebar =
    pubSubEvent &&
    timebarPubSubEventTypes.includes(pubSubEvent) &&
    buff?.developer?.hideTimebar !== true

  const sponsorContent = getSponsorContent(activeLanguage, buff)

  // Negative marging of the answer overflow is 8,
  // used 9 to count on the expansion of the answer when being selected
  const buffAnswerMarginMultiplier = 9
  const yPercentageToApplySafeBottomSpacing = 93

  const processStyles = (style: CSSProperties | undefined) => {
    if (!style) return {}
    if (!style.top) return style

    // Only apply safe bottom space if voteable Y position is bigger than
    // {yPercentageToApplySafeBottomSpacing}% to prevent answers overflow
    // the video container.
    if (
      parseFloat(style.top.toString()) > yPercentageToApplySafeBottomSpacing
    ) {
      return {
        ...style,
        top: `calc(${style.top} - (var(--spacing) * ${buffAnswerMarginMultiplier}))`,
      }
    }

    return style
  }

  const styleObj = {
    ...processStyles(style),
    ...buffThemeVars,
  }

  const buffContentWidth = getBuffContentWidth(
    activeLanguage,
    widgetLayout,
    buff
  )

  const containerStyles = getGradientContainerStyles(
    'var(--color-buff-default-bg-startColor)',
    'var(--color-buff-default-bg-endColor)',
    'var(--color-buff-default-bg-rotation)'
  )

  useEffect(() => {
    if (prevBuff !== buff && buff) {
      setActiveLanguage(activeLanguageRef?.current)
    }
  }, [buff, prevBuff, activeLanguageRef])

  const handleSignup = () => {
    if (!buff) return
    if ((isVoteable(buff) || isVodVoteable(buff)) && !isAuth) {
      onSignup && onSignup?.()
    }
  }

  const handleSponsorClick = () => {
    if (!buff) return
    queryClient.setQueryData<boolean>(
      `buff.${getBuffId(buff)}.sponsorClicked`,
      () => true
    )
  }

  const timebarInProgress =
    (!!votedAnswerId && !castedVote) ||
    !selectedAnswerId ||
    (!!buff && isVoteable(buff) && useLiveVotingEnabled)

  const buffCoreBackgroundStyles =
    sponsorContent && widgetLayout === WidgetLayout.DESKTOP ? 'ps-12.5' : ''
  const infoBubbleSpacingStyle =
    sponsorContent && widgetLayout === WidgetLayout.DESKTOP ? 'ms-12.5' : ''
  const flexDirection =
    (widgetLayout === WidgetLayout.PORTRAIT_FULL_LEADERBOARD ||
      widgetLayout === WidgetLayout.PORTRAIT_TOGGLE_LEADERBOARD) &&
    sponsorContent
      ? 'flex-col'
      : ''
  const buffPointerEvents = isTV ? 'pointer-events-none' : ''

  return (
    <div
      className={`flex flex-col items-start absolute z-30`}
      data-testid="buff-core"
      style={styleObj}
    >
      <AnimatePresence initial={false}>
        {buff ? (
          <motion.div
            key={buffId}
            variants={variants}
            initial="exit"
            animate="enter"
            exit="exit"
            style={containerStyles}
            className={`flex relative mx-auto ${flexDirection} ${buffPointerEvents}`}
          >
            {sponsorContent && (
              <BuffSponsorContent
                onSponsorClick={handleSponsorClick}
                imageUrl={sponsorContent.imageUrl ?? ''}
                linkUrl={sponsorContent.linkTarget}
                widgetLayout={widgetLayout}
              />
            )}
            <div className="flex">
              <div
                className={`flex ${borderStyles} ${bgStyles} ${borderRadiusStyle} ${buffContentWidth} ${buffCoreBackgroundStyles} relative backdrop-blur-sm`}
                data-testid="buff-core__content"
              >
                <div className={`flex flex-col w-full ${padding}`}>
                  <AnimatePresence initial={false}>
                    {infoBubbleProps && (
                      <div onClick={handleSignup}>
                        <InfoBubble
                          {...infoBubbleProps}
                          className={`${infoBubbleProps?.className} ${infoBubbleSpacingStyle}`}
                          motionProps={{
                            initial: { y: 20, x: 0 },
                            animate: { y: 0, x: 0 },
                          }}
                        />
                      </div>
                    )}
                  </AnimatePresence>
                  <div className={`flex flex-col w-full pb-3`}>
                    <div className="flex mb-1 items-center">
                      <div className="w-full">
                        {showTimebar && (
                          <Timebar
                            buffId={buffId}
                            inProgress={timebarInProgress}
                            duration={duration}
                            onTimeChange={setCurrentTime}
                          />
                        )}
                      </div>

                      {showTimebarClose && (
                        <button
                          data-testid="buffCore__close-button"
                          onClick={handleClose}
                          className="button cursor-pointer p-2 -me-2 -mt-2 -mb-2 ms-px"
                        >
                          <IconClose
                            fill="var(--color-buff-default-text)"
                            className="h-2 w-2"
                          />
                        </button>
                      )}
                    </div>
                    {displayAuthorContainer && (
                      <AuthorContainer
                        authorName={authorName}
                        authorPicture={authorPicture}
                        text={text}
                      />
                    )}
                  </div>

                  <div data-testid="buffcore-children-container">
                    {children}
                  </div>
                </div>
              </div>
              <LateralImage buff={buff} activeLanguage={activeLanguage} />
            </div>
          </motion.div>
        ) : null}
      </AnimatePresence>
    </div>
  )
}

export default BuffCore
