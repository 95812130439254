import React, { FC, MutableRefObject, useLayoutEffect } from 'react'
import { LocalDebugUtilities, WidgetConfig } from '@interfaces/widget'
import { FloatingTabsProvider } from '@services/providers/FloatingTabsProvider'
import { BuffProvider, BuffProviderRef } from '@services/providers/BuffProvider'
import { UserProvider } from '@services/providers/UserProvider'
import { ConfigProvider } from '@services/providers/ConfigProvider'
import { LeaderboardProvider } from '@services/providers/LeaderboardProvider'
import { StreamProvider } from '@services/providers/StreamProvider'
import { AudioProvider } from '@services/providers/AudioProvider'
import { ThemeProvider } from '@services/providers/ThemeProvider'
import { ScreenProvider } from '@services/providers/ScreenProvider'
import { DebugProvider } from '@services/providers/DebugProvider'
import { RouteHistoryProvider } from '@services/providers/RouteHistoryProvider'
import { VideoPlayer } from '@interfaces/videoPlayer'
import { initClient, destroyClient } from '@utils/hooks/useWebSocketClient'

interface ProviderWrapperProps {
  config: WidgetConfig

  /**
   * The container element of the video
   */
  container?: HTMLElement

  /**
   * Writing mode for SDK (used for dev)
   */
  writingMode?: 'ltr' | 'rtl'

  /**
   * video player to show buffs
   */
  videoPlayer?: VideoPlayer
  /**
   * Debug Object to activate/deactivate some functionality
   * for local debugging purposes
   */
  debug?: LocalDebugUtilities

  /**
   * Ref to the providers
   */
  innerRef?: MutableRefObject<BuffProviderRef | undefined>
}

export const ProviderWrapper: FC<ProviderWrapperProps> = ({
  debug,
  config,
  container,
  writingMode,
  children,
  videoPlayer,
  innerRef,
}) => {
  const streamId = config?.streamId

  const isTwitch =
    config?.player === 'twitch-mobile' || config?.player === 'twitch-web'
  const isLinkedTwitchUser = isTwitch && Boolean(config.twitchUserId)

  useLayoutEffect(() => {
    initClient(config?.clientName)
    return () => {
      destroyClient()
    }
  }, [config?.clientName])

  return (
    <DebugProvider>
      <RouteHistoryProvider>
        <StreamProvider
          streamId={streamId}
          streamSourceId={config?.streamSourceId}
          twitchChannelId={config?.twitchChannelId}
          clientName={config?.clientName}
        >
          <ConfigProvider widgetConfig={config}>
            <ScreenProvider>
              <ThemeProvider
                themeName={debug?.theme}
                localDevSDKSize={debug?.localDevSDKSize}
                container={container}
              >
                <UserProvider
                  writingMode={writingMode}
                  refreshToken={config.token}
                  isLinkedTwitchUser={isLinkedTwitchUser}
                  anonymousLogin={config.anonymousLogin}
                >
                  <FloatingTabsProvider>
                    <LeaderboardProvider>
                      <BuffProvider
                        ref={innerRef}
                        videoPlayer={videoPlayer}
                        preStreamBuffFrequency={config.preStreamBuffFrequency}
                      >
                        <AudioProvider>{children}</AudioProvider>
                      </BuffProvider>
                    </LeaderboardProvider>
                  </FloatingTabsProvider>
                </UserProvider>
              </ThemeProvider>
            </ScreenProvider>
          </ConfigProvider>
        </StreamProvider>
      </RouteHistoryProvider>
    </DebugProvider>
  )
}
