import { useQuery } from 'react-query'
import { getLeaderboardStandingsById } from '@services/requests/leaderboard-v2'
import { LEADERBOARD_STANDING_STALE_TIME } from '@utils/reactQuery'

export const useGetLeaderboardStandings = (
  leaderboardId: string,
  streamId?: string,
  groupId?: string
) => {
  return useQuery(
    groupId
      ? ['friend.leaderboard', leaderboardId, groupId]
      : ['leaderboard', leaderboardId],
    async () => {
      return await getLeaderboardStandingsById(leaderboardId, streamId, groupId)
    },
    {
      enabled: Boolean(streamId) && Boolean(leaderboardId),
      staleTime: LEADERBOARD_STANDING_STALE_TIME,
      refetchInterval: LEADERBOARD_STANDING_STALE_TIME,
    }
  )
}
