import React, { FC, useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Modal from '@components/molecules/Modal'
import { useTranslation } from 'react-i18next'
import Button, { ButtonBackground } from '@components/atoms/Button'
import { UserContext } from '@services/providers/UserProvider'
import PictureSelection from '@components/molecules/PictureSelection'
import { updateUserProfilePicture } from '@services/requests/auth'
import { getAssets } from '@services/requests/assets'
import { useQuery } from 'react-query'
import { Asset } from '@interfaces/asset'
import InfoText from '@components/atoms/InfoText'

const EMPTY_ASSET: Asset = {
  id: '',
  publicUrl: '',
  fileName: '',
}

const AssetSelection: FC = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { profile, refetchProfile } = useContext(UserContext)

  const userProfilePicture = profile?.profilePicture ?? ''

  const [selectedImage, setSelectedImage] = useState(userProfilePicture)
  const [isLoading, setIsLoading] = useState(false)

  const { data: assets, error } = useQuery('assets', async () => {
    const response = await getAssets()
    return [EMPTY_ASSET, ...response]
  })

  const handleGoBack = () => navigate(-1)

  const handleConfirm = async () => {
    if (!profile?.id) return

    try {
      setIsLoading(true)
      await updateUserProfilePicture(profile?.id, selectedImage ?? '')
      await refetchProfile()
      handleGoBack()
    } catch (err) {
    } finally {
      setIsLoading(false)
    }
  }

  const handleSelection = (url: string) => {
    setSelectedImage(url)
  }

  const confirmDisabled = userProfilePicture === selectedImage || isLoading

  return (
    <Modal
      titleClassName="normal-case"
      title={t('assets.avatar')}
      onGoBack={handleGoBack}
      data-testid="asset-selection"
      childrenClassName="overflow-y-auto assets-scrollbar -mx-2"
      centerTitle
      rightAction={
        <Button
          disabled={confirmDisabled}
          keepBgOnDisabled
          data-testid="asset-selection__confirm-button"
          background={ButtonBackground.PRIMARY}
          className="!py-1.5 !px-2"
          onClick={handleConfirm}
        >
          <span className="font-semibold text-xs">{t('general.confirm')}</span>
        </Button>
      }
    >
      <div className="flex flex-1 flex-col">
        {!!error && (
          <InfoText className="justify-center" text={t('error.unknown')} />
        )}
        <div className="flex flex-wrap justify-center">
          {assets?.map((asset, index) => {
            const assetImageUrl = asset?.publicUrl + asset?.fileName
            return (
              <PictureSelection
                disabled={isLoading}
                key={index.toString()}
                onSelect={handleSelection}
                image={assetImageUrl}
                selected={assetImageUrl === selectedImage}
              />
            )
          })}
        </div>
      </div>
    </Modal>
  )
}

export default AssetSelection
