import { LanguageCode } from '@interfaces/languages'

interface LanguageMapValue {
  flag: string
  name: string
  locale: string
}

export const languagesMap: Record<LanguageCode, LanguageMapValue> = {
  [LanguageCode.en]: {
    flag: '🇬🇧',
    name: 'English',
    locale: 'en',
  },
  [LanguageCode.fr]: {
    flag: '🇫🇷',
    name: 'Français',
    locale: 'fr',
  },
  [LanguageCode.es]: {
    flag: '🇪🇸',
    name: 'Español',
    locale: 'es',
  },
  [LanguageCode.pt]: {
    flag: '🇵🇹',
    name: 'Português',
    locale: 'pt',
  },
  [LanguageCode.de]: {
    flag: '🇩🇪',
    name: 'Deutsch',
    locale: 'de',
  },
  [LanguageCode.it]: {
    flag: '🇮🇹',
    name: 'Italiano',
    locale: 'it',
  },
  [LanguageCode.el]: {
    flag: '🇬🇷',
    name: 'Ελληνικά',
    locale: 'el',
  },
  [LanguageCode.ar]: {
    flag: '🇦🇪',
    name: 'عربي',
    locale: 'ar',
  },
  [LanguageCode.ms]: {
    flag: '',
    name: 'هاس ملايو',
    locale: 'ms',
  },
  [LanguageCode.no]: {
    flag: '',
    name: 'Norsk',
    locale: 'no',
  },
  [LanguageCode.pl]: {
    flag: '',
    name: 'język polski',
    locale: 'pl',
  },
  [LanguageCode.he]: {
    flag: '',
    name: 'עברית',
    locale: 'he',
  },
  [LanguageCode.bg]: {
    name: 'български',
    locale: 'bg',
    flag: '',
  },
  [LanguageCode.hu]: {
    name: 'Magyar',
    locale: 'hu',
    flag: '',
  },
  [LanguageCode.id]: {
    name: 'Indonesian',
    locale: 'id',
    flag: '',
  },
  [LanguageCode.nl]: {
    name: 'Dutch',
    locale: 'nl',
    flag: '',
  },
  [LanguageCode.sr]: {
    name: 'Srpski',
    locale: 'sr',
    flag: '',
  },
}

export const transformLanguageToCode = (
  language: LanguageCode
): LanguageCode => {
  return (languagesMap[language]?.locale ?? '') as LanguageCode
}
