import React, { FC } from 'react'
import { motion } from 'framer-motion'
import { Link, useLocation } from 'react-router-dom'
import { NavigationButtonProps } from './types'

const NavigationButton: FC<NavigationButtonProps> = ({
  children,
  to,
  tab,
  ...props
}) => {
  const { pathname } = useLocation()
  const matchRegex = new RegExp(`^${to}`)
  const match = matchRegex.test(pathname)

  const Icon = match ? tab.activeIcon : tab.icon

  return (
    <motion.button
      data-testid={`tab-${tab.key}`}
      // data-active-tab={resolved.pathname}
      className={`cursor-pointer border-none m-0.5 w-full text-center text-xs rounded ${
        match ? 'bg-navbar-selected-bg' : ''
      }`}
      key={tab.key}
      transition={{ duration: 0.2 }}
      style={{ outline: 'none' }}
    >
      <Link data-testid={`tab-${tab.key}_link`} to={to} {...props}>
        <div className="flex flex-col items-center justify-center p-2 h-full">
          <Icon
            className="w-5 h-5 mb-1.5"
            fill={`${
              match
                ? 'var(--color-navbar-selected-text)'
                : 'var(--color-navbar-default-text)'
            }`}
          />
          <p
            className={`font-semibold leading-none ${
              match ? 'text-navbar-selected-text' : 'text-navbar-default-text'
            }`}
          >
            {children}
          </p>
        </div>
      </Link>
    </motion.button>
  )
}

export default NavigationButton
