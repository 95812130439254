export type MappedTheme = Record<string, string | null>

export interface ITheme {
  answertile: {
    body: {
      bg: string
      text: string
    }
    default: {
      bg: string
      text: string
    }
    selected: {
      bg: string
      text: string
      percentageBg: string
    }
    success: {
      bg: string
      text: string
      percentageBg: string
    }
    wrong: {
      bg: string
      text: string
      percentageBg: string
    }
    winner: {
      bg: string
      text: string
      percentageBg: string
    }
    imageplaceholder: {
      bg: string
    }
    bodysupporting: {
      bg: string
    }
    percentage: {
      bg: string
      text: string
      percentageBg: string
      bar?: string
    }
  }
  buff: {
    default: {
      bg: {
        startColor: string
        endColor: string
        rotation: string
      }
      text: string
    }
    inverse: {
      bg: string
    }
  }
  button: {
    primary: {
      bg: string
      text: string
    }
    secondary: {
      bg: string
      text: string
    }
    tertiary: {
      bg: string
      text: string
    }
    cta: {
      bg: string
      text: string
    }
    expand: {
      bg: string
      text: string
    }
    danger: {
      bg: string
      text: string
    }
    announcement: {
      bg: string
      text: string
    }
    share: {
      bg: string
      text: string
    }
    tabnavigation: {
      bg: string
      text: string
    }
    reaction: {
      bg: string
      text: string
    }
  }
  floatingPanel: {
    default: {
      bg: {
        startColor: string
        endColor: string
        rotation: string
      }
      text: string
    }
    vip: {
      default: {
        bg: {
          startColor: string
          endColor: string
          rotation: string
        }
        text: string
      }
      accent: {
        bg: string
        text: string
      }
    }
    accent: {
      default: {
        bg: string
        text: string
      }
      warning: {
        bg: string
        text: string
      }
      muted: {
        bg: string
        text: string
      }
    }
    banner: {
      bg: string
      text: string
    }
    friends: {
      bg: string
      text: string
    }
    profile: {
      bg: string
    }
    actionSheet: {
      bg: string
    }
    toggleSwitch: {
      bg: string
    }
    navigationBar: {
      bg: string
    }
    icons: {
      bg: string
    }
    border: {
      bg: string
    }
    inverse: {
      bg: string
    }
    transparentbackdrop: {
      bg: string
    }
    linkbutton: {
      text: string
    }
    muted: {
      text: string
    }
    semantic: {
      success: string
      error: string
    }
  }
  infopill: {
    default: {
      bg: {
        startColor: string
        endColor: string
      }
      text: string
    }
    action: {
      text: string
    }
    semantic: {
      error: string
      success: string
    }
  }
  leaderboard: {
    standard: {
      selected: {
        bg: string
        text: string
      }
      default: {
        bg: string
        text: string
      }
    }
    vip: {
      default: {
        bg: string
        text: string
      }
      selected: {
        bg: string
        text: string
      }
    }
  }
  navbar: {
    selected: {
      bg: string
      text: string
    }
    default: {
      bg: string
      text: string
    }
  }
  pointsdeck: {
    default: {
      bg: {
        startColor: string
        endColor: string
        rotation: string
      }
      text: string
    }
  }
  reactionPanel: {
    action: {
      text: string
    }
    default: {
      bg: {
        startColor: string
        endColor: string
        rotation: string
      }
      text: string
    }
  }
  shades: {
    black: string
    white: string
  }
  slider: {
    default: {
      bg: {
        startColor: string
        endColor: string
        rotation: string
      }
    }
    backdrop: {
      bg: string
    }
    pill: {
      text: string
      bg: string
    }
    pillPercentage: {
      text: string
    }
  }
  star: {
    default: {
      bg: {
        startColor: string
        endColor: string
        rotation: string
      }
    }
    backdrop: {
      bg: string
    }
  }
  table: {
    columnheader: {
      bg: string
      text: string
    }
    rowheader: {
      bg: string
      text: string
    }
  }
  timer: {
    background: string
    start: string
    middle: string
    end: string
  }

  variant: string
  'font-base': string
  'font-secondary': string
}

export interface DynamicTheme {
  theme: Theme
}

export interface Theme {
  name: string | ProviderTheme | Fonts
  [key: string]: string | ProviderTheme | Fonts
  fonts: string | ProviderTheme | Fonts
}

export interface FontType {
  name: string
  url: string
}

export interface FontItem {
  name: string
  regular: FontType
  semiBold: FontType
  bold: FontType
}

export interface Fonts {
  font1: FontItem
  font2: FontItem
}

export interface ProviderTheme {
  base50: string
  base100: string
  base200: string
  base300: string
  base400: string
  base500: string
  base600: string
  base700: string
  base800: string
  base900: string
  label50: string
  label100: string
  label200: string
  label400: string
  label500: string
  label600: string
  label700: string
  label800: string
  label900: string
  backgroundStart: string
  backgroundEnd: string
  gradientStart: string
  gradiendEnd: string
  gradientBackground?: string
  primaryDefault: string
  primaryTint: string
  primaryShade: string
  bitsDefault?: string
  bitsTint?: string
  bitsShade?: string
  successDefault: string
  successTint: string
  successShade: string
  warningDefault: string
  warningTint: string
  warningShade: string
  errorDefault: string
  errorTint: string
  errorShade: string
  goldDefault: string
  goldTint: string
  goldShade: string
  shadeWhite: string
  shadeBlack: string
  fonts: Fonts
}

export enum BuffSize {
  xSmall = 'xSmall',
  small = 'small',
  medium = 'medium',
  large = 'large',
}
