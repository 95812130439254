import { ITheme } from '@interfaces/theme'

const base700 = '#323A46'
const baseTheme: ITheme = {
  answertile: {
    body: {
      bg: '#ffe35b',
      text: '#ff0000',
    },
    default: {
      bg: '#ffe35b',
      text: '#ff0000',
    },
    selected: {
      bg: '#ffe35b',
      text: '#ff0000',
      percentageBg: '#0000ff',
    },
    success: {
      bg: '#000000',
      text: '#ff0000',
      percentageBg: '#0000ff',
    },
    wrong: {
      bg: '#000000',
      text: '#ff0000',
      percentageBg: '#0000ff',
    },
    winner: {
      bg: '#000000',
      text: '#ff0000',
      percentageBg: '#0000ff',
    },
    imageplaceholder: {
      bg: '#000000',
    },
    bodysupporting: {
      bg: '#000000',
    },
    percentage: {
      bg: '#000000',
      text: '#ff0000',
      percentageBg: '#0000ff',
    },
  },
  buff: {
    default: {
      bg: {
        startColor: '#ff0000',
        endColor: '#00ff00',
        rotation: '45deg',
      },
      text: '#ff0000',
    },
    inverse: {
      bg: '#000000',
    },
  },
  button: {
    primary: {
      bg: '#1d4ed8ff',
      text: '#ffffffff',
    },
    secondary: {
      bg: '#f0f3f7ff',
      text: '#191d23ff',
    },
    tertiary: {
      bg: '#08274fff',
      text: '#ffffffff',
    },
    cta: {
      bg: '#ffffffff',
      text: '#191d23ff',
    },
    expand: {
      bg: '#08274fff',
      text: '#ffffffff',
    },
    danger: {
      bg: '#dc2626ff',
      text: '#ffffffff',
    },
    announcement: {
      bg: '#08274fff',
      text: '#ffffffff',
    },
    share: {
      bg: '#1977d4ff',
      text: '#ffffffff',
    },
    tabnavigation: {
      bg: '#f7f8faff',
      text: '#191c1fff',
    },
    reaction: {
      bg: '#f0f3f7ff',
      text: '#191d23ff',
    },
  },
  pointsdeck: {
    default: {
      bg: {
        startColor: '#1d4ed8ff',
        endColor: '#1d4ed8ff',
        rotation: '45deg',
      },
      text: '#1d4ed8ff',
    },
  },
  reactionPanel: {
    action: {
      text: '#409cffff',
    },
    default: {
      bg: {
        startColor: '#f7f8f9db',
        endColor: '#f7f8f9db',
        rotation: '45deg',
      },
      text: '#b8b8b8ff',
    },
  },
  infopill: {
    default: {
      bg: {
        startColor: '#ffffffff',
        endColor: '#ffffffff',
      },
      text: '#ffffffff',
    },
    action: {
      text: '#ffffffff',
    },
    semantic: {
      error: '#ffffffff',
      success: '#ffffffff',
    },
  },
  floatingPanel: {
    default: {
      bg: {
        startColor: '#f7f8f9cc',
        endColor: '#f7f8f9cc',
        rotation: '45deg',
      },
      text: '#191d23ff',
    },
    vip: {
      default: {
        bg: {
          endColor: '#efdec5cc',
          startColor: '#e3e3bdcc',
          rotation: '45deg',
        },
        text: '#191d23ff',
      },
      accent: {
        bg: '#562b99ff',
        text: '#ffffffff',
      },
    },
    accent: {
      default: {
        bg: '#1d4ed8ff',
        text: '#ffffffff',
      },
      warning: {
        bg: '#f7a600ff',
        text: '#191d23ff',
      },
      muted: {
        bg: '#98a0a8ff',
        text: '#2c2e30ff',
      },
    },
    banner: {
      bg: '#b8c0ccff',
      text: '#191d23ff',
    },
    friends: {
      bg: '#d1d6deff',
      text: '#191d23ff',
    },
    profile: {
      bg: '#e9eaf0ff',
    },
    actionSheet: {
      bg: '#d5d8dbff',
    },
    toggleSwitch: {
      bg: '#e1e4e8ff',
    },
    navigationBar: {
      bg: '#dfe2e5ff',
    },
    icons: {
      bg: '#dfe2e5ff',
    },
    border: {
      bg: '#717985ff',
    },
    inverse: {
      bg: '#191d23ff',
    },
    transparentbackdrop: {
      bg: '#191d2366',
    },
    linkbutton: {
      text: '#1d4ed8ff',
    },
    muted: {
      text: '#828b99ff',
    },
    semantic: {
      success: '#138761ff',
      error: '#dc2626ff',
    },
  },
  leaderboard: {
    standard: {
      selected: {
        bg: '#0f2d54ff',
        text: '#ffffffff',
      },
      default: {
        bg: '#f2f3f5ff',
        text: '#586069ff',
      },
    },
    vip: {
      default: {
        bg: '#9147ffff',
        text: '#ffffffff',
      },
      selected: {
        bg: '#6632b2ff',
        text: '#ffffffff',
      },
    },
  },
  navbar: {
    selected: {
      bg: '#08274fff',
      text: '#ffffffff',
    },
    default: {
      bg: '#e1e4e8ff',
      text: '#091b32ff',
    },
  },
  shades: {
    white: '#000',
    black: '#fff',
  },
  slider: {
    default: {
      bg: {
        startColor: '#ff0000',
        endColor: '#00ff00',
        rotation: '45deg',
      },
    },
    backdrop: {
      bg: '#ffffff',
    },
    pill: {
      text: '#ffffff',
      bg: '#ffffff',
    },
    pillPercentage: {
      text: '#ffffff',
    },
  },
  star: {
    default: {
      bg: {
        startColor: '#ff0000',
        endColor: '#00ff00',
        rotation: '45deg',
      },
    },
    backdrop: {
      bg: '#ffffff',
    },
  },
  table: {
    columnheader: {
      bg: '#ffffff',
      text: '#ffffff',
    },
    rowheader: {
      bg: '#ffffff',
      text: '#ffffff',
    },
  },
  timer: {
    background: '#ffffff',
    start: '#ffffff',
    middle: '#ffffff',
    end: '#ffffff',
  },
  variant: 'SportBuff',
  'font-base': '"Open Sans", sans-serif',
  'font-secondary': '"Open Sans", sans-serif',
}

export default baseTheme
