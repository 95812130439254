import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import { useMutation, useQuery } from 'react-query'
import ReactionsButton from '@components/atoms/ReactionsButton'
import ReactionsPanel from '@components/molecules/ReactionsPanel'
import { ConfigContext } from '@services/providers/ConfigProvider'
import { BuffContext } from '@services/providers/BuffProvider'
import { StreamContext } from '@services/providers/StreamProvider'
import { getStreamReactions, sendReaction } from '@services/requests/reactions'
import {
  LiveReactionAsset,
  ReactionEvent,
  ReactionSize,
  ReactionSpeed,
  ReactionsPosition,
} from '@interfaces/reactions'
import { PubSubEventType } from '@interfaces/pubSub'
import { useWebSocketClient } from '@utils/hooks/useWebSocketClient'
import { queryClient } from '@utils/reactQuery'
import { createReactionImg } from '@utils/createReactionImg'
import { ReactionsWrapperProps } from './types'

const DEFAULT_TTL = 5000

const ReactionsWrapper = ({ sdkContainer }: ReactionsWrapperProps) => {
  const [isPanelOpen, setIsPanelOpen] = useState(false)
  const [tabFocused, setTabFocused] = useState(true)
  const { widgetConfig, reactionsDisabled } = useContext(ConfigContext)
  const { snooze } = useContext(BuffContext)
  const { stream } = useContext(StreamContext)
  const overlayRef = useRef<HTMLDivElement>(null)

  const userReactionsRef = useRef<{ url: string; ttl: number }[]>([])

  const isTwitchMobile = widgetConfig?.player === 'twitch-mobile'
  const screenWidth = sdkContainer?.offsetWidth || 0

  const streamId = stream?.id ?? ''

  const { data: reactionsData } = useQuery(
    ['reactions', streamId],
    () => getStreamReactions(streamId),
    {
      enabled: !!streamId,
    }
  )

  const direction = reactionsData?.direction ?? ReactionsPosition.Horizontal
  const streamStyles =
    // @ts-ignore
    direction === ReactionsPosition.Horizontal
      ? 'w-[85%] h-[20%]'
      : 'w-[25%] h-[85%]'

  const channel = !!streamId ? `streams.${streamId}.reactions` : undefined

  const animationsChannel = !!streamId
    ? `streams.${streamId}.reactions.animations`
    : undefined

  const { mutate: sendReactionMutate } = useMutation((reactionId: string) =>
    sendReaction(streamId, reactionId)
  )

  const { subscribe, unsubscribe } = useWebSocketClient()

  const reactions = reactionsData?.availableReactions ?? []

  const right =
    isTwitchMobile || screenWidth < 500
      ? 'right-2 bottom-4'
      : 'right-8 bottom-12'

  const isEnabled = !!reactionsData?.enabled && !reactionsDisabled && !snooze

  const handleTogglePanel = () => {
    setIsPanelOpen(!isPanelOpen)
  }

  const handleRemoveFlyingEmoji = useCallback((node) => {
    if (!overlayRef.current) return
    overlayRef.current.removeChild(node)
  }, [])

  const handleDisplayFlyingEmoji = useCallback(
    ({
      imageUrl,
      direction,
      speed,
      size,
      local,
    }: {
      imageUrl: string
      direction?: ReactionsPosition
      speed?: ReactionSpeed
      size?: ReactionSize
      local?: boolean
    }) => {
      if (!overlayRef.current) {
        return
      }

      const reactionHTMLNode = createReactionImg(
        direction ?? ReactionsPosition.Horizontal,
        imageUrl,
        speed ?? ReactionSpeed.NORMAL,
        size ?? ReactionSize.SMALL,
        local ?? false
      )

      overlayRef.current.appendChild(reactionHTMLNode)

      reactionHTMLNode.addEventListener('animationend', (e) =>
        handleRemoveFlyingEmoji(e.target)
      )
    },
    [handleRemoveFlyingEmoji]
  )

  const handleSendReaction = (reaction: LiveReactionAsset) => {
    if (!streamId) return
    sendReactionMutate(reaction.id)

    userReactionsRef.current.push({
      url: reaction.imageUrl,
      ttl: Date.now() + DEFAULT_TTL,
    })

    handleDisplayFlyingEmoji({
      imageUrl: reaction.imageUrl,
      direction,
      size: ReactionSize.LARGE,
      speed: ReactionSpeed.NORMAL,
      local: true,
    })
  }

  const handleReactionMessages = useCallback(
    (message) => {
      const data = message?.data
      if (data?.name === PubSubEventType.REACTIONS_UPDATED) {
        queryClient.invalidateQueries(['reactions', streamId])
      }
    },
    [streamId]
  )

  const handleReactionAnimationMessages = useCallback(
    (message) => {
      const data = message?.data as ReactionEvent[]

      if (data?.length) {
        const userReactions = userReactionsRef.current.map((r) => r.url)

        // Filter out reactions that are already displayed on the screen by user click
        const filteredData = data.filter(
          (reaction) =>
            !(
              reaction.size === ReactionSize.SMALL &&
              userReactions.includes(reaction.reaction_image_url)
            )
        )

        // Display filtered reactions
        filteredData.forEach((reaction: ReactionEvent) => {
          setTimeout(() => {
            if (tabFocused) {
              handleDisplayFlyingEmoji({
                imageUrl: reaction.reaction_image_url,
                direction: direction,
                speed: reaction.speed,
                size: reaction.size,
              })
            }
          }, reaction.time_offset_seconds * 4000)
        })

        // Remove reactions from userReactionsRef that are already returned from the BE
        data.forEach((reaction: ReactionEvent) => {
          const index = userReactions.indexOf(reaction.reaction_image_url)
          if (index > -1) {
            userReactionsRef.current.splice(index, 1)
          }
        })
      }
    },
    [direction, handleDisplayFlyingEmoji, tabFocused]
  )

  useEffect(() => {
    if (!channel) return

    const subscription = subscribe(channel, handleReactionMessages)
    return () => {
      !!subscription && unsubscribe(subscription)
    }
  }, [channel, streamId, handleReactionMessages, subscribe, unsubscribe])

  useEffect(() => {
    if (reactionsDisabled || !animationsChannel || snooze) return

    const subscription = subscribe(
      animationsChannel,
      handleReactionAnimationMessages
    )
    return () => {
      !!subscription && unsubscribe(subscription)
    }
  }, [
    snooze,
    reactionsDisabled,
    animationsChannel,
    streamId,
    handleReactionAnimationMessages,
    subscribe,
    unsubscribe,
  ])

  // Remove expired reactions from userReactionsRef
  useEffect(() => {
    document.addEventListener('visibilitychange', () => {
      setTabFocused(document.visibilityState === 'visible')
    })

    const timeout = window.setTimeout(() => {
      const now = Date.now()
      userReactionsRef.current = userReactionsRef.current.filter(
        (reaction) => reaction.ttl > now
      )
    }, 1000)

    return () => {
      clearTimeout(timeout)
    }
  }, [])

  if (!isEnabled) return null

  return (
    <div className="pointer-events-auto antialiased">
      <AnimatePresence>
        {isPanelOpen ? (
          <motion.div
            className={`absolute bottom-12 ${right} z-20`}
            key="reactions-panel"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <ReactionsPanel
              onClose={handleTogglePanel}
              onSendReaction={handleSendReaction}
              reactions={reactions}
            />
          </motion.div>
        ) : (
          <motion.div
            className="absolute bottom-12 right-8 z-20"
            key="reactions-button"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <ReactionsButton onClick={handleTogglePanel} />
          </motion.div>
        )}
        <div
          ref={overlayRef}
          id="emoji-stream-track"
          className={`absolute right-0 z-10 bottom-0 ${streamStyles} pointer-events-none`}
        ></div>
      </AnimatePresence>
    </div>
  )
}

export default ReactionsWrapper
