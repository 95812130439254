import { LiveReactions } from '@interfaces/reactions'
import createClient, { Service } from '@utils/createClient'

export const getStreamReactions = async (streamId: string) => {
  const client = createClient(Service.LIVE)

  const data = await client.get<LiveReactions>(`/streams/${streamId}/reactions`)

  return data.data
}

export const sendReaction = async (streamId: string, reactionId: string) => {
  const client = createClient(Service.LIVE)

  const data = await client.post<LiveReactions>(
    `/streams/${streamId}/reactions/send`,
    [reactionId]
  )

  return data.data
}
