import { Centrifuge, Subscription } from 'centrifuge'
import { useSyncExternalStore } from 'use-sync-external-store/shim'
import { sendCounterEvent } from '@utils/metrics'
import { PROPERTY_EVENTS } from '@interfaces/metrics'
import { noop } from '@utils/noop'

let client: Centrifuge | null = null
let isClientConnected = false

const handleCentrifugeDisconnect = () => {
  sendCounterEvent(PROPERTY_EVENTS.socketDisconnected)
  isClientConnected = false
}

const handleCentrifugeConnect = () => {
  isClientConnected = true
}

export const initClient = (clientName?: string) => {
  if (!clientName) return

  if (client instanceof Centrifuge) {
    console.warn('client already initialised')
    return
  }

  const centrifugoClient =
    ['develop', 'qa'].indexOf(clientName) > -1 ? clientName : 'prod'

  const centrifugoURI = `wss://centrifugo.${centrifugoClient}.buffup.net/connection/websocket`
  client = new Centrifuge(centrifugoURI, {
    debug: true,
  })

  client.on('disconnected', handleCentrifugeDisconnect)
  client.on('connected', handleCentrifugeConnect)
}

export const destroyClient = () => {
  if (client instanceof Centrifuge) {
    client.disconnect()
    client.off('disconnected', handleCentrifugeDisconnect)
    client.off('connected', handleCentrifugeConnect)
    client.removeAllListeners()
    client = null
  } else {
    console.warn('client does not exist')
  }
}

const connectClient = () => {
  if (!(client instanceof Centrifuge)) {
    console.warn('client does not exist to connect to')
  } else {
    client.connect()
  }
}

const disconnectClient = () => {
  if (client instanceof Centrifuge) {
    client.disconnect()
  } else {
    console.warn('client does not exist')
  }
}

const subscribe = (
  channelName: string,
  callback: (message: unknown) => void
) => {
  if (!client) {
    console.warn('client does not exist to subscribe to')
    return null
  }

  const clientName = process.env.ACCOUNT_NAME
  const subChannel = `broadcaster.${clientName}.${channelName}`

  const sub = client.getSubscription(subChannel)

  if (!!sub) return

  const subscription = client
    .newSubscription(subChannel)
    .on('publication', callback)

  subscription.subscribe()

  return subscription
}

const unsubscribe = (sub: Subscription) => {
  if (!client) {
    console.warn('client does not exist to subscribe to')
    return null
  }

  client.removeSubscription(sub)
}

const publish = async (channelName: string, message: any) => {
  if (!client) {
    console.warn('client does not exist to subscribe to')
    return noop
  }

  const sub = client.getSubscription(channelName)

  if (sub) {
    await sub.publish(message)
  }
}

const getSnapshot = () => {
  if (!client) {
    return false
  }

  return isClientConnected
}

const subscribeClient = (callback: () => void) => {
  if (client) {
    client.on('connected', callback)
    client.on('disconnected', callback)
  }
  return () => {
    if (!client) return
    client.off('connected', callback)
    client.off('disconnected', callback)
  }
}

export const useWebSocketClient = () => {
  const isConnected = useSyncExternalStore(subscribeClient, getSnapshot)
  return {
    isConnected,
    disconnectClient,
    connectClient,
    subscribe,
    publish,
    unsubscribe,
  } as const
}
