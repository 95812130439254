export enum PercentageBarVariant {
  TALL = 'tall',
  WIDE = 'wide',
}

export enum PercentageBarBackgroundVariant {
  LIGHT = 'light',
  DARK = 'dark',
}

export interface PercentageBarProps {
  /**
   * The percentage the bar will show
   * From 0 - 100
   */
  percentage: number

  /**
   * The colour of the text displayed in the bar
   * @default 'text-shades-white'
   */
  textColor?: string

  /**
   * Displays the background of the bar
   * @default true
   */
  displayBackground?: boolean

  /**
   * Either a light or dark variant percentage bar
   * @default PercentageBarBackgroundVariant.LIGHT
   */
  barBackgroundVariant?: PercentageBarBackgroundVariant

  /**
   * Colour to use for the percentage bar
   */
  barColor?: string

  /**
   * Colour to use for the percentage bar bg
   */
  bgColor?: string

  /**
   * Variant of percentage bar. @default WIDE
   */
  variant?: PercentageBarVariant

  /**
   * Class name to be applied to element
   */
  className?: string
}
