import React, { useContext, useRef, useEffect, useState } from 'react'
import { AnimatePresence } from 'framer-motion'
import { useNavigate, useLocation } from 'react-router-dom'
import { Trans, useTranslation } from 'react-i18next'
import Modal from '@components/molecules/Modal'
import { UserContext } from '@services/providers/UserProvider'
import { ConfigContext } from '@services/providers/ConfigProvider'
import Button, { ButtonBackground } from '@components/atoms/Button'
import TermsAndPolicy from '@components/atoms/TermsAndPolicy'
import { RoutePaths } from '@interfaces/navigation'
import { OIDCProvider } from '@interfaces/auth'
import { ReactComponent as GoogleLogo } from '@assets/google-logo.svg'
import { ReactComponent as FacebookLogo } from '@assets/facebook-logo.svg'
import { refreshJwtToken } from '@services/requests/auth'
import { useRouteHistory } from '@utils/hooks/useRouteHistory'
import Checkbox from '@components/atoms/Checkbox'
import StaticOutlet from '@components/atoms/StaticOutlet'
import { logError } from '@utils/log'
import { getSlugByIndex } from '@utils/router'
import storage from '@utils/storage'
import { BUFFUP_SDK_REFRESH_TOKEN } from '../../../constants'

const enableFBLoginEnv = process.env.FB_LOGIN === 'true'
const enableCaracolTerms = process.env.CARACOL_TERMS_CONDITIONS === 'true'

const ChooseAuth = () => {
  const { setToken } = useContext(UserContext)
  const { widgetConfig } = useContext(ConfigContext)
  const navigate = useNavigate()
  const location = useLocation()
  const { t } = useTranslation()
  const removeWindowMessageListener = useRef<any>(null)
  const { getPreviousRoute } = useRouteHistory()

  const [caracolOptions, setCaracolOptions] = useState([false, false, false])

  const disableButtons =
    enableCaracolTerms &&
    (!caracolOptions[0] || !caracolOptions[1] || !caracolOptions[2])

  const isTwitch =
    widgetConfig.player === 'twitch-web' ||
    widgetConfig.player === 'twitch-mobile'

  const enableLogins = widgetConfig.anonymousLogin !== true
  const enableFBLogin = enableLogins && enableFBLoginEnv && !isTwitch
  const enableGoogleLogin = enableLogins && !isTwitch
  const enableEmailLogin = enableLogins && !isTwitch
  const enableTwitchLogin = enableLogins && isTwitch

  const handleBackClick = () => {
    // Match any previous routes that aren't in auth
    const previousRoute = getPreviousRoute(-1, /^((?!\/auth).)*$/)
    if (previousRoute) {
      navigate(previousRoute)
    }
  }

  const handleWithTwitch = () => {
    window.Twitch.ext.actions.requestIdShare()
  }

  const handleOIDC = (provider: OIDCProvider) => {
    const origin = `https://api.${widgetConfig.clientName}.buffup.net`
    const oidcURL = `${origin}/auth/oidc/login?providerName=${provider}`
    const oidcWindow = window.open(
      oidcURL,
      undefined,
      'popup, width=550, height=650'
    )

    const removeHandler = () => {
      window.removeEventListener('message', messageHandler, false)
    }

    const messageHandler = async (event: MessageEvent) => {
      if (event.origin !== origin) return
      const refreshToken = event.data
      storage.setItem(BUFFUP_SDK_REFRESH_TOKEN, refreshToken)

      try {
        const token = await refreshJwtToken()

        oidcWindow?.close()
        setToken(token)

        navigate(RoutePaths.WELCOME)

        removeHandler()
        removeWindowMessageListener.current = null
      } catch (error) {
        logError(error)
      }
    }

    window.addEventListener('message', messageHandler, false)

    removeWindowMessageListener.current = removeHandler
  }

  const handleWithEmail = () => {
    navigate(RoutePaths.EMAIL_LOGIN)
  }

  const handleOptionToggle = (index: number) => {
    const options = [...caracolOptions]
    const item = !options[index]
    options[index] = item
    setCaracolOptions(options)
  }

  const handleOnSignUp = () => {
    if (!widgetConfig.onSignUp) return

    widgetConfig.onSignUp()
  }

  useEffect(() => {
    return () => {
      removeWindowMessageListener.current &&
        removeWindowMessageListener.current()
    }
  }, [])

  const termsYPadding = enableCaracolTerms ? 'pb-2' : ''

  const screenTitle = isTwitch
    ? t('auth.linkYourAccount')
    : t('auth.chooseAuthTitle')

  return (
    <Modal
      centerTitle
      titleClassName="normal-case"
      title={screenTitle}
      onGoBack={handleBackClick}
      data-testid="choose-auth"
      routerChildren={
        <AnimatePresence>
          <StaticOutlet key={getSlugByIndex(location.pathname, -1)} />
        </AnimatePresence>
      }
      className="overflow-hidden overflow-y-auto"
    >
      <div className="flex flex-col flex-1">
        <div className="flex flex-col flex-1 justify-center px-6">
          {widgetConfig.onSignUp && (
            <Button
              keepBgOnDisabled
              disabled={disableButtons}
              data-testid="choose-auth__email-button"
              onClick={handleOnSignUp}
            >
              {t('auth.continueWithClient')}
            </Button>
          )}
          {!widgetConfig.onSignUp && (
            <>
              {enableGoogleLogin && (
                <Button
                  disabled={disableButtons}
                  data-testid="choose-auth__google-button"
                  onClick={() => handleOIDC(OIDCProvider.GOOGLE)}
                  background={ButtonBackground.NONE}
                  className="bg-shades-white mb-2 relative"
                >
                  <GoogleLogo className="w-5 h-5 absolute top-2/4 -translate-y-2/4 start-2" />
                  <span className="text-[#757575] block w-full ps-3">
                    {t('auth.continueWithGoogle')}
                  </span>
                </Button>
              )}
              {enableFBLogin && (
                <Button
                  disabled={disableButtons}
                  data-testid="choose-auth__facebook-button"
                  onClick={() => handleOIDC(OIDCProvider.FACEBOOK)}
                  background={ButtonBackground.NONE}
                  className="bg-[#1877F2] mb-2 relative"
                >
                  <FacebookLogo className="w-4 h-4 absolute top-2/4 -translate-y-2/4 start-2" />
                  <span className="text-shades-white block w-full ps-3">
                    {t('auth.continueWithFacebook')}
                  </span>
                </Button>
              )}
              {enableEmailLogin && (
                <Button
                  keepBgOnDisabled
                  disabled={disableButtons}
                  data-testid="choose-auth__email-button"
                  onClick={handleWithEmail}
                  background={ButtonBackground.NONE}
                  className="!text-floatingPanel-default-text"
                >
                  {t('auth.continueWithEmail')}
                </Button>
              )}
              {enableTwitchLogin && (
                <Button
                  disabled={disableButtons}
                  data-testid="choose-auth__twitch-button"
                  onClick={handleWithTwitch}
                  background={ButtonBackground.PRIMARY}
                >
                  {t('auth.continueWithTwitch')}
                </Button>
              )}
            </>
          )}
        </div>

        {enableCaracolTerms && (
          <div className="mb-4">
            <Checkbox
              id="option1"
              checked={caracolOptions[0]}
              label={
                <Trans
                  i18nKey="auth.caracolTerms.option1"
                  components={[
                    <a
                      href="https://publico.caracolnext.com/Terminos_y_condiciones_Caracol_Play.pdf"
                      key="index_1"
                      target="_blank"
                      rel="noreferrer"
                      className="underline"
                    ></a>,
                  ]}
                />
              }
              onChange={() => handleOptionToggle(0)}
            />
            <Checkbox
              id="option2"
              containerClassname="my-2"
              checked={caracolOptions[1]}
              label={
                <Trans
                  i18nKey="auth.caracolTerms.option2"
                  components={[
                    <a
                      href="https://publico.caracolnext.com/PoliticaTratamientoDeLaInformacion.pdf"
                      key="index_2"
                      target="_blank"
                      rel="noreferrer"
                      className="underline"
                    ></a>,
                  ]}
                />
              }
              onChange={() => handleOptionToggle(1)}
            />
            <Checkbox
              id="option3"
              checked={caracolOptions[2]}
              label={
                <Trans
                  i18nKey="auth.caracolTerms.option3"
                  components={[
                    <a
                      href="https://publico.caracolnext.com/AutorizacionCaracolPlay.pdf"
                      key="index_3"
                      target="_blank"
                      rel="noreferrer"
                      className="underline"
                    ></a>,
                  ]}
                />
              }
              onChange={() => handleOptionToggle(2)}
            />
          </div>
        )}
        <TermsAndPolicy className={`px-4 ${termsYPadding}`} />
      </div>
    </Modal>
  )
}

export default ChooseAuth
