export const BUFF_LOCAL_STORAGE_NAMESPACE = 'SportBuff'

export const BUFFUP_SDK_TOKEN = `SPORT_BUFF_TOKEN`
export const BUFFUP_SDK_REFRESH_TOKEN = `SPORT_BUFF_REFRESH_TOKEN`

export const BUFFUP_ANON_ID = 'SPORT_BUFF_ANON_ID'

export const TRANSACTION_COMPLETED = 'transaction_completed'
export const TRANSACTION_ID = 'TWTID'

export const QRCODE_URL = 'https://www.sportbuff.com/2ndscreenapp'

export const ANSWER_SHOW_STATE_MS = 3500

export const DEFAULT_REACTION_SPEED = 4

export const REACTION_CLICK_ANIMATION_DURATION_MS = 1000
