import React, { FC, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { ConfigContext } from '@services/providers/ConfigProvider'
import { getNumberWithOrdinal } from '@utils/getNumberWithOrdinal'
import PlayerPosition from '@components/atoms/PlayerPosition'
import { isStreamWinner } from '@utils/buff'
import {
  getPlayerPositionProps,
  shouldDisplayStreamWinnersSidePanel,
} from '@utils/streamWinners'
import { WidgetLayout } from '@interfaces/widget'
import { formatPercentage } from '@utils/leaderboard'
import { StreamWinnersSidePanelProps } from './types'

const getSidePanelWidth = (layout: WidgetLayout) => {
  switch (layout) {
    case WidgetLayout.DESKTOP:
      return 'w-32'

    case WidgetLayout.PORTRAIT_FULL_LEADERBOARD:
    case WidgetLayout.PORTRAIT_TOGGLE_LEADERBOARD:
      return 'w-21'

    default:
      throw new Error('Cannot get sidePanelWidth')
  }
}

const getTitleFontSize = (layout: WidgetLayout) => {
  switch (layout) {
    case WidgetLayout.DESKTOP:
      return 'text-sm'

    case WidgetLayout.PORTRAIT_FULL_LEADERBOARD:
    case WidgetLayout.PORTRAIT_TOGGLE_LEADERBOARD:
      return 'text-xs'
  }
}

const getTitleThumbFontSize = (layout: WidgetLayout) => {
  switch (layout) {
    case WidgetLayout.DESKTOP:
      return 'text-xl'

    case WidgetLayout.PORTRAIT_FULL_LEADERBOARD:
    case WidgetLayout.PORTRAIT_TOGGLE_LEADERBOARD:
      return 'text-md'
  }
}

const StreamWinnersSidePanel: FC<StreamWinnersSidePanelProps> = ({
  userStanding,
  widgetLayout,
}) => {
  const { t } = useTranslation()
  const { streamConfig } = useContext(ConfigContext)

  // const isTwitchMobile = widgetConfig?.player === 'twitch-mobile'

  const playerPositionProps = getPlayerPositionProps(
    widgetLayout,
    userStanding?.userId,
    userStanding,
    streamConfig?.config.hideProfilePicture
  )

  const userRank = userStanding?.position
  const userScore = userStanding?.percentile
  let title = ''
  if (userRank) {
    const rankText = getNumberWithOrdinal(userRank)
    title = t('streamWinners.youFinished', { rankText }) // `You finished {{rankText}}`
  } else if (userScore) {
    title = t('streamWinners.youFinishedPercentage', {
      percentage: formatPercentage(userScore),
    })
  }

  const sidePanelWidth = getSidePanelWidth(widgetLayout)
  const titleFontSize = getTitleFontSize(widgetLayout)
  const titleThumbSize = getTitleThumbFontSize(widgetLayout)

  return (
    <div
      className={`flex flex-col items-center ${sidePanelWidth} justify-between relative before:w-[1px] before:absolute before:top-2 before:bottom-2 before:start-0 before:bg-buff-inverse-bg before:opacity-10`}
      data-testid="stream-winners-side-panel"
    >
      <h2
        className={`text-center font-bold ${titleFontSize} text-buff-default-text font-secondary`}
      >
        {title} <span className={`${titleThumbSize}`}>👍</span>
      </h2>
      {playerPositionProps && (
        <PlayerPosition {...playerPositionProps} className="w-full" />
      )}
    </div>
  )
}

export default StreamWinnersSidePanel
