import { useQuery } from 'react-query'
import { getLeaderboardMetaById } from '@services/requests/leaderboard-v2'
import { LEADERBOARD_META_STALE_TIME } from '@utils/reactQuery'

export const useGetLeaderboardMeta = (
  leaderboardId?: string,
  streamId?: string
) => {
  return useQuery(
    ['leaderboard', leaderboardId, 'meta'],
    () => {
      return getLeaderboardMetaById(leaderboardId, streamId)
    },
    {
      staleTime: LEADERBOARD_META_STALE_TIME,
      enabled: Boolean(leaderboardId) && Boolean(streamId),
    }
  )
}
