import { useState, useCallback, useEffect } from 'react'
import storage from '@utils/storage'
import { BUFF_LOCAL_STORAGE_NAMESPACE } from '../../constants'
import { sendEvent } from '@utils/metrics'
import { METRIC_EVENTS } from '@interfaces/metrics'

export const localStorageKey = `${BUFF_LOCAL_STORAGE_NAMESPACE}.reactionsDisableStartDate`

export const useReactionsHandler = () => {
  const [startDate, setStartDate] = useState<number | null>(() => {
    const startDateString = storage.getItem(localStorageKey)
    if (!startDateString) return null
    const startDateNum = parseInt(startDateString)
    if (Number.isNaN(startDateNum)) return null
    const startDate = new Date(startDateNum)
    const prefExpiryDate = new Date(startDateNum)

    prefExpiryDate.setDate(startDate.getDate() + 7)

    if (Date.now() > prefExpiryDate.getTime()) return null
    return startDateNum
  })

  const disabled = Boolean(startDate)

  const update = useCallback((value: boolean) => {
    sendEvent(METRIC_EVENTS.reactionsSnoozed, { disabled: !value })
    setStartDate(!value ? Date.now() : null)
  }, [])

  useEffect(() => {
    if (startDate) {
      storage.setItem(localStorageKey, startDate.toString())
    } else {
      storage.removeItem(localStorageKey)
    }
  }, [startDate])

  return {
    disabled,
    update,
  }
}
