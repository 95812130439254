export enum ReactionsPosition {
  Vertical = 'VERTICAL',
  Horizontal = 'HORIZONTAL',
}

export enum ReactionSize {
  SMALL = 'SMALL',
  MEDIUM = 'MEDIUM',
  LARGE = 'LARGE',
  XLARGE = 'XLARGE',
}

export const MappedReactionSize = {
  [ReactionSize.SMALL]: '32px',
  [ReactionSize.MEDIUM]: '40px',
  [ReactionSize.LARGE]: '48px',
  [ReactionSize.XLARGE]: '64px',
}

export enum ReactionSpeed {
  SLOW = 'SLOW',
  NORMAL = 'NORMAL',
  FAST = 'FAST',
}

export const MappedReactionSpeed = {
  [ReactionSpeed.SLOW]: 0.8,
  [ReactionSpeed.NORMAL]: 1,
  [ReactionSpeed.FAST]: 1.2,
}

export interface LiveReactionAsset {
  id: string
  imageUrl: string
}

export interface LiveReactions {
  enabled: boolean
  direction: ReactionsPosition
  availableReactions: LiveReactionAsset[]
}

export interface Reaction {
  id: string
  imageUrl: string
}

export interface ReactionEvent {
  reaction_image_url: string
  size: ReactionSize
  speed: ReactionSpeed
  time_offset_seconds: number
}
