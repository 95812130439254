import { client } from '@services/requests/client'
import {
  LeaderboardStanding,
  DecoratedLeaderboardStanding,
} from '@interfaces/leaderboard'
import { getUsers, getUser } from '@services/requests/auth'
import { BatchUser } from '@interfaces/user'
import { getLeaderboardStandingByEntityId } from './leaderboard-v2'

const decorateLeaderboardStandings = async (
  standings: LeaderboardStanding[]
): Promise<DecoratedLeaderboardStanding[]> => {
  const userIds = standings.map((standing) => standing.userId)
  const users = userIds?.length ? await getUsers(userIds) : []

  const usersMap: Record<string, BatchUser> = {}
  users.forEach((user) => {
    usersMap[user.id] = user
  })

  return standings.map((standing) => {
    const user = usersMap[standing.userId]
    if (!user) return standing
    const { displayName, profilePicture } = user
    return {
      ...standing,
      displayName,
      profilePicture,
    }
  })
}

export const getLeaderboardStandingById = async (
  leaderboardId: string,
  pageSize: number = 50
): Promise<LeaderboardStanding[]> => {
  const response = await client.get(
    `/leaderboards/${leaderboardId}/standings?pagination.maxSize=${pageSize}`
  )
  return response.data.standings
}

export const getDecoratedLeaderboardStandingById = async (
  leaderboardId: string,
  pageSize: number = 50
): Promise<DecoratedLeaderboardStanding[]> => {
  const standings = await getLeaderboardStandingById(leaderboardId, pageSize)
  return decorateLeaderboardStandings(standings)
}

export const getDecoratedLeaderboardStandingByEntityId = async (
  streamId?: string,
  leaderboardId?: string,
  entityId?: string,
  groupId?: string
): Promise<DecoratedLeaderboardStanding | undefined> => {
  if (!leaderboardId) return
  if (!streamId) return

  const standing = await getLeaderboardStandingByEntityId(
    streamId,
    leaderboardId,
    entityId,
    groupId
  )

  const decoratedStandings = await decorateLeaderboardStandings([standing])
  return decoratedStandings[0]
}

export const getDecoratedLeaderboardStandingCurrentUser = async (
  streamId?: string,
  leaderboardId?: string,
  entityId?: string,
  groupId?: string
): Promise<DecoratedLeaderboardStanding | undefined> => {
  if (!leaderboardId) return
  if (!streamId) return

  const standing = await getLeaderboardStandingByEntityId(
    streamId,
    leaderboardId,
    entityId,
    groupId
  )

  const user = await getUser()

  return {
    ...standing,
    displayName: user.displayName,
    profilePicture: user.profilePicture,
  }
}
