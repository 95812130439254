import { initReactI18next } from 'react-i18next'
import i18n, { InitOptions } from 'i18next'
import LocalStorageBackend from 'i18next-localstorage-backend'
import HttpApi from 'i18next-http-backend'
import Backend from 'i18next-chained-backend'
import resourcesToBackend from 'i18next-resources-to-backend'
import { LanguageCode } from '@interfaces/languages'
import storage from '@utils/storage'
import translationEN from '../locales/en/translation.json'

const bundledResources = {
  en: {
    translation: translationEN,
  },
}

const options: InitOptions = {
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
  },
  partialBundledLanguages: true,
  supportedLngs: [...Object.keys(LanguageCode)],
  react: {
    useSuspense: false,
  },
  backend: {
    backends: [
      LocalStorageBackend,
      HttpApi,
      resourcesToBackend(bundledResources),
    ],
    backendOptions: [
      {
        prefix: 'i18next_res_',
        expirationTime: 24 * 60 * 60 * 1000, // 1 day,

        // Version applied to all languages, can be overriden using the option `versions`
        defaultVersion: '',

        // language versions
        versions: {},

        // can be either window.localStorage or window.sessionStorage. Default: window.localStorage
        store: storage,
      },
      {
        loadPath:
          'https://buffup-web-sdk.core.buffup.net/buffup-web-sdk/translations/WebSDK/{{lng}}.json',
        crossDomain: true,
      },
    ],
  },
}
i18n.use(Backend).use(initReactI18next).init(options)

export default i18n
