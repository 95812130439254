import EventEmitter from 'eventemitter3'
import axios from 'axios'
import {
  getDisplayDuration,
  isVodVoteable,
  isVoteable,
  isAnnouncement,
  isWelcomeBuff,
  isStreamWinner,
  isVodAnnouncement,
  getBuffType,
  getBuffId,
} from '@utils/buff'
import { Engagement } from '@interfaces/voteable'
import { CentrifugoEvent, PubSubEventType } from '@interfaces/pubSub'
import { LanguageCode } from '@interfaces/languages'
import {
  formatEventObject,
  isGamePubSubEvent,
  isStreamWinnerPubSubEvent,
} from '@utils/pubSub'
import { wait } from '@utils/wait'
import { Vote } from '@interfaces/vote'
import {
  BuffType,
  expiredTypes,
  resolveableTypes,
  Buff,
} from '@interfaces/buff'
import {
  getAnnouncementById,
  getVoteableById,
  getVoteableEngagementById,
  getVoteStatus,
  castVote,
} from '@services/requests/games'
import { getStreamWinner } from '@utils/streamWinners'
import { Announcement } from '@interfaces/announcement'
import { Voteable, VodVoteable, VoteableLifecycle } from '@interfaces/voteable'
import { logError } from '@utils/log'
import {
  UseBuffQueueArgs,
  BuffQueueItem,
  BuffQueueType,
  BuffQueueEventName,
  ANIMATION_BUFFER,
} from './types'
import { BUFF_LOCAL_STORAGE_NAMESPACE } from '../../../constants'

type VodBuff = VodVoteable | Announcement

export const missedBuffsStorageKey = `${BUFF_LOCAL_STORAGE_NAMESPACE}.missedBuffs`

const TIME_SYNC_MS = 2000

const TIME_SYNC_STREAM_WINNERS_BUFFER_SECONDS = 3

/**
 * A filter that returns true if the buff has enough time to vote before voting on the server is closed
 *
 * TODO: May need to account for a relative Date if want to fix issue with user's clock being out of sync
 *
 * @param {Date} now Time to check against?
 * @param {BuffQueueItem} queueItem queue item
 * @return {boolean}
 */
const hasBuffEnoughDisplayTimeToVote = ({ buff, eventType }: BuffQueueItem) => {
  if (!isVoteable(buff)) {
    return false
  }
  const now = Date.now()

  const closesAt = new Date(buff.closesAt)
  const displayDuration = getDisplayDuration(buff, eventType)
  const latestVisibleAt = closesAt.getTime() - displayDuration * 1000

  if (latestVisibleAt < now) {
    return false
  }

  return true
}

const cachedVoteables = new Map<string, Voteable>()

/**
 * Gets a voteable from cache otherwise will fetch it
 * @param {string} gameId If of the game
 * @param {string} voteableId If of the voteable to fetch
 * @param {PubSubEventType} name Event type
 * @return {Promise<Voteable>}
 */
const getVoteable = async (
  gameId: string,
  voteableId: string,
  name: PubSubEventType
) => {
  const key = `${gameId}.${voteableId}`
  const cachedVoteable = cachedVoteables.get(key)
  if (cachedVoteable && name !== PubSubEventType.VOTEABLE_RESOLVE) {
    return cachedVoteable
  }
  const voteable = await getVoteableById(gameId, voteableId)
  cachedVoteables.set(key, voteable)

  return voteable
}

/**
 * TODO
 */
export class BuffQueue extends EventEmitter {
  public queueList: BuffQueueItem[]

  public queueMessages: Record<string, CentrifugoEvent>

  public queueType?: BuffQueueType

  private language: LanguageCode

  /**
   * When snooze is enabled no items are added to the queue
   */
  private snooze: boolean

  public activeQueueItem?: BuffQueueItem

  private timeout?: number

  private removeTimeout?: number

  private queueLock?: boolean

  /**
   * Frequency of buffs appearing in pre game mode
   */
  public preStreamBuffFrequency: number

  /**
   * Current game time
   */
  private gameTime?: Date | number

  /**
   * Engagement data for voteables
   */
  private engagementData: Record<string, Engagement | undefined>

  /**
   * The vote status of a voteable
   */
  private voteStatus: Record<string, Vote | undefined>

  /**
   * A map of voteables displayedAt times.
   * Key being a voteable id
   * Number being number of ms timestamp
   */
  private displayedAtMap: Record<string, number | undefined>

  /**
   * Id of the games
   */
  private gameId?: string

  /**
   * Id of the stream
   */
  private streamId?: string

  private gameEndedMap: Map<string, boolean>

  private userId?: string

  /**
   * Initialiser
   * @param {UseBuffQueueArgs} props queue props
   */
  constructor(props: UseBuffQueueArgs) {
    super()

    this.activeQueueItem = undefined
    this.timeout = undefined
    this.queueList = []
    this.queueMessages = {}
    this.queueType = props.type
    this.queueLock = false
    this.gameTime = undefined
    this.snooze = props.snooze
    this.language = props.language
    this.preStreamBuffFrequency = props.preStreamBuffFrequency ?? 30

    this.gameEndedMap = new Map()
    this.userId = props.userId
    this.engagementData = {}
    this.voteStatus = {}
    this.displayedAtMap = {}

    this.clearQueue = this.clearQueue.bind(this)
    this.removeActiveQueueItem = this.removeActiveQueueItem.bind(this)
    this.addQueueItem = this.addQueueItem.bind(this)
    this.findActiveBuffLive = this.findActiveBuffLive.bind(this)
    this.findActiveBuffVOD = this.findActiveBuffVOD.bind(this)
    this.findActiveBuffPreStream = this.findActiveBuffPreStream.bind(this)
    this.findActiveBuffTimeSync = this.findActiveBuffTimeSync.bind(this)
    this.addQueueItems = this.addQueueItems.bind(this)
    this.updateGameTime = this.updateGameTime.bind(this)

    this.setGameEnded = this.setGameEnded.bind(this)
    this.setGameId = this.setGameId.bind(this)
    this.setStreamId = this.setStreamId.bind(this)
    this.getEngagement = this.getEngagement.bind(this)
    this.handleStreamPubSubMessage = this.handleStreamPubSubMessage.bind(this)
    this.handleStreamWinnerPubSubMessage =
      this.handleStreamWinnerPubSubMessage.bind(this)
    this.handleVODBuffs = this.handleVODBuffs.bind(this)
    this.handlePreStreamBuffs = this.handlePreStreamBuffs.bind(this)
    this.fetchEngagement = this.fetchEngagement.bind(this)
    this.fetchVoteStatus = this.fetchVoteStatus.bind(this)
    this.castVote = this.castVote.bind(this)
    this.setUserId = this.setUserId.bind(this)
  }

  /**
   * Used to add all VOD buffs at once
   * @param {BuffQueueItem[]} items
   */
  public addQueueItems(items: BuffQueueItem[]) {
    items.forEach((item) => {
      this.addQueueItem(item, true)
    })

    if (this.queueType === BuffQueueType.VOD) {
      this.sortVODQueue()
    }
  }

  /**
   * Sorts the vod queue
   */
  private sortVODQueue() {
    this.queueList = this.queueList.sort((a, b) => {
      if (!a.visibleAt) return -1
      if (!b.visibleAt) return -1

      if (typeof a.visibleAt !== 'number' || typeof b.visibleAt !== 'number') {
        console.warn('visibleAt not a number in vodQueue')
        return 0
      }

      return a.visibleAt - b.visibleAt
    })
  }

  /**
   * Sets snooze on the queue
   * @param {boolean} snooze
   */
  public setSnooze(snooze: boolean) {
    this.snooze = snooze

    if (snooze && this.queueType !== BuffQueueType.VOD) {
      this.clearQueue()
    }
  }

  /**
   * Sets the user id
   *
   * Will also clear internal vote state
   * @param {string | undefined} userId
   */
  public setUserId(userId?: string) {
    this.userId = userId

    this.voteStatus = {}
    this.emit(BuffQueueEventName.VOTE_STATE_CHANGE, this.voteStatus)
  }

  /**
   * Sets language on the queue
   * @param {LanguageCode} language
   */
  public setLanguage(language: LanguageCode) {
    this.language = language
  }

  /**
   * Adds an item to the queue
   * @param {BuffQueueItem} item
   * @param {boolean} skipQueueProcess
   */
  public addQueueItem(item: BuffQueueItem, skipQueueProcess: boolean = false) {
    if (this.queueType !== BuffQueueType.VOD && this.snooze) return

    const queueItem = { ...item }
    const shouldReceiveLiveBuffs =
      this.queueType === BuffQueueType.LIVE ||
      this.queueType === BuffQueueType.PRE_STREAM_MODE

    if (
      this.queueType === BuffQueueType.VOD &&
      isVodAnnouncement(queueItem.buff)
    ) {
      queueItem.visibleAt = queueItem.buff.openedAtSeconds
    }

    // Decorate queue item with vod timings on visibleAt field
    if (this.queueType === BuffQueueType.VOD && isVodVoteable(queueItem.buff)) {
      let visibleAt: number | undefined
      let closesAt: number | undefined

      // TODO: Add announcement???
      switch (queueItem.eventType) {
        case PubSubEventType.VOTEABLE_OPEN:
          visibleAt = queueItem.buff.openedAtSeconds
          closesAt = visibleAt
            ? visibleAt + queueItem.buff.voteDurationSeconds
            : undefined
          break

        case PubSubEventType.VOTEABLE_CLOSE:
          visibleAt = queueItem.buff.closedAtSeconds
          closesAt = visibleAt
            ? visibleAt + (queueItem.buff.summaryDurationSeconds ?? 0)
            : undefined
          break

        case PubSubEventType.VOTEABLE_RESOLVE:
          visibleAt = queueItem.buff.resolvedAtSeconds
          closesAt = visibleAt
            ? visibleAt + (queueItem.buff.resolvedAtSeconds ?? 0)
            : undefined
          break
      }

      queueItem.visibleAt = visibleAt
      queueItem.closesAt = closesAt
    }

    if (this.queueType === BuffQueueType.TIME_SYNC) {
      let visibleAt: Date | undefined

      if (isVoteable(queueItem.buff)) {
        switch (queueItem.eventType) {
          case PubSubEventType.VOTEABLE_OPEN:
            visibleAt = new Date(queueItem.buff.opensAt)
            break

          case PubSubEventType.VOTEABLE_CLOSE:
            visibleAt = new Date(queueItem.buff.closesAt)
            break

          case PubSubEventType.VOTEABLE_RESOLVE:
            if (queueItem.buff.resolvesAt) {
              visibleAt = new Date(queueItem.buff.resolvesAt)
            }
            break
        }
      }

      if (isAnnouncement(queueItem.buff)) {
        visibleAt = new Date(queueItem.buff.opensAt)
      }

      if (isStreamWinner(queueItem.buff)) {
        if (!(this.gameTime instanceof Date)) {
          return
        }

        this.clearQueue()

        // Set visibleAt time of stream winner relative to current game time
        const newGameTime = new Date(this.gameTime)
        newGameTime.setSeconds(
          newGameTime.getSeconds() + TIME_SYNC_STREAM_WINNERS_BUFFER_SECONDS
        )
        visibleAt = newGameTime
      }

      if (visibleAt) queueItem.visibleAt = visibleAt
    }

    // move to front of queue in LIVE scenario if published buff
    if (
      item.eventType === PubSubEventType.VOTEABLE_OPEN &&
      shouldReceiveLiveBuffs
    ) {
      this.queueList.unshift(queueItem)
    } else {
      this.queueList.push(queueItem)
    }

    if (!this.activeQueueItem && !skipQueueProcess && shouldReceiveLiveBuffs) {
      this.processQueue()
    }
  }

  /**
   * finds active buff for LIVE stream
   * @return {Buff | undefined}
   */
  private findActiveBuffLive() {
    const possiblePublishedBuffs = this.queueList
      .filter(({ eventType }) => {
        return eventType === PubSubEventType.VOTEABLE_OPEN
      })
      .sort((a, b) => {
        if (
          !a.visibleAt ||
          !(a.visibleAt instanceof Date) ||
          !b.visibleAt ||
          !(b.visibleAt instanceof Date)
        )
          return 0
        return a.visibleAt?.getTime() - b.visibleAt?.getTime()
      })

    const publishedBuffsWithTime = possiblePublishedBuffs.filter(
      hasBuffEnoughDisplayTimeToVote
    )

    const publishedBuffsWithoutTime = possiblePublishedBuffs.filter(
      (item) => !hasBuffEnoughDisplayTimeToVote(item)
    )

    // Remove published buffs from queue list which have expired
    if (publishedBuffsWithoutTime.length !== 0) {
      this.queueList = this.queueList.filter(
        (item) => !publishedBuffsWithoutTime.includes(item)
      )
    }

    if (publishedBuffsWithTime) {
      const activeQueueItem = publishedBuffsWithTime?.[0]

      if (activeQueueItem) {
        this.queueList = this.queueList.filter(
          (queueItem) => queueItem !== activeQueueItem
        )

        return activeQueueItem
      }
    }

    // Just grab whatever is at top of queue. Feel like we should be sorting the queue somehow??
    if (this.queueList.length !== 0) {
      const activeQueueItem = this.queueList.shift()!

      return activeQueueItem
    }
  }

  /**
   * Finds the active Time sync buff
   * TODO: Check how long this takes compared to old binary search method
   * @return {Buff | undefined}
   */
  private findActiveBuffTimeSync() {
    const { gameTime } = this
    if (!(gameTime instanceof Date)) {
      throw new Error('game time is not a date')
    }

    const possiblePublishedBuffs = this.queueList
      .filter(({ eventType }) => {
        return eventType === PubSubEventType.VOTEABLE_OPEN
      })
      .sort((a, b) => {
        if (
          !a.visibleAt ||
          !(a.visibleAt instanceof Date) ||
          !b.visibleAt ||
          !(b.visibleAt instanceof Date)
        )
          return 0
        return a.visibleAt?.getTime() - b.visibleAt?.getTime()
      })

    const publishedBuffsWithTime = possiblePublishedBuffs.filter(
      hasBuffEnoughDisplayTimeToVote
    )

    const publishedBuffsWithoutTime = possiblePublishedBuffs.filter(
      (item) => !hasBuffEnoughDisplayTimeToVote(item)
    )

    const nextActiveBuff = publishedBuffsWithTime.find(
      ({ visibleAt, buff }) => {
        if (!(visibleAt instanceof Date)) {
          throw new Error('No visibleAt on publishedBuffsWithTime queue item')
        }

        if (!isVoteable(buff)) return false

        const msDiff = gameTime.getTime() - visibleAt.getTime()
        return msDiff <= TIME_SYNC_MS && msDiff >= 0
      }
    )

    // Remove published buffs from queue list which have expired
    if (publishedBuffsWithoutTime.length !== 0) {
      this.queueList = this.queueList.filter(
        (item) => !publishedBuffsWithoutTime.includes(item)
      )
    }

    if (nextActiveBuff) {
      this.queueList = this.queueList.filter(
        (queueItem) => queueItem !== nextActiveBuff
      )

      return nextActiveBuff
    }

    if (this.queueList.length !== 0) {
      const possibleOtherBuffs = this.queueList
        .filter(({ eventType, visibleAt }) => {
          if (!(visibleAt instanceof Date)) {
            throw new Error('visibleAt is not a date')
          }

          const msDiff = gameTime.getTime() - visibleAt.getTime()

          return (
            // show welcome buff always
            eventType === PubSubEventType.welcomeBuff ||
            // anything that is not a published buff
            (eventType !== PubSubEventType.VOTEABLE_OPEN &&
              // anything that should have displayed by now
              msDiff <= TIME_SYNC_MS &&
              msDiff >= 0)
          )
        })
        .sort((a, b) => {
          if (
            !a.visibleAt ||
            !(a.visibleAt instanceof Date) ||
            !b.visibleAt ||
            !(b.visibleAt instanceof Date)
          )
            return 0
          return a.visibleAt?.getTime() - b.visibleAt?.getTime()
        })

      const activeQueueItem =
        possibleOtherBuffs.length === 0 ? undefined : possibleOtherBuffs[0]

      if (activeQueueItem) {
        this.queueList = this.queueList.filter((queueItem) => {
          return queueItem !== activeQueueItem
        })
      }

      return activeQueueItem
    }

    return undefined
  }

  /**
   * Finds the active VOD buff
   * TODO: Check how long this takes compared to old binary search method
   * @return {BuffQueueItem | undefined}
   */
  private findActiveBuffVOD() {
    const { gameTime } = this
    if (typeof gameTime !== 'number') {
      throw new Error('game time is not a number')
    }

    const closest = this.queueList.reduce<
      { item: BuffQueueItem; index: number } | undefined
    >((acc, queueItem, index) => {
      if (typeof queueItem.visibleAt !== 'number') {
        throw new Error('visibleAt is not a number in VOD')
      }

      const diff = Math.abs(queueItem.visibleAt - gameTime)
      const prevVisibleAt =
        typeof acc?.item?.visibleAt === 'number' ? acc?.item?.visibleAt : 0

      const prevDiff = acc ? Math.abs(prevVisibleAt - gameTime) : undefined

      if (
        queueItem.visibleAt <= gameTime &&
        queueItem.closesAt &&
        Number(queueItem.closesAt) > gameTime &&
        diff >= 0 &&
        (prevDiff === undefined || diff < prevDiff)
      ) {
        return { item: queueItem, index }
      }
      return acc
    }, undefined)

    if (closest) {
      this.queueList.splice(closest.index, 1)
    }

    return closest?.item
  }

  /**
   * Finds the active buff for PRE_STREAM_MODE queue type
   * @return {BuffQueueItem | undefined}
   */
  private findActiveBuffPreStream() {
    const queue = [...this.queueList].sort((a, b) => {
      if (
        !a.visibleAt ||
        !(a.visibleAt instanceof Date) ||
        !b.visibleAt ||
        !(b.visibleAt instanceof Date)
      ) {
        return 0
      }
      return a.visibleAt?.getTime() - b.visibleAt?.getTime()
    })

    // Remove voteables that have expired
    const filteredQueue = queue.filter((queueItem) => {
      if (
        isVoteable(queueItem.buff) &&
        queueItem.eventType === PubSubEventType.VOTEABLE_OPEN &&
        !hasBuffEnoughDisplayTimeToVote({
          buff: queueItem.buff,
          eventType: queueItem.eventType,
        })
      ) {
        return false
      }

      return true
    })

    // remove expired voteables from queue list
    this.queueList = filteredQueue

    const now = Date.now()

    const queueItemReadyToShow = this.queueList.filter((queueItem) => {
      if (!(queueItem.visibleAt instanceof Date)) {
        return false
      }
      return queueItem.visibleAt.getTime() <= now
    })

    if (queueItemReadyToShow.length !== 0) {
      const activeQueueItem = queueItemReadyToShow[0]
      this.queueList = this.queueList.filter(
        (queueItem) => queueItem !== activeQueueItem
      )
      return activeQueueItem
    } else {
      return undefined
    }
  }

  /**
   * Process queue
   * @param {number} delay Number of ms to wait before processing queue. Helpful to avoid animation jank
   * @return {void}
   */
  private async processQueue(delay: number = 0) {
    if (this.queueLock) {
      console.warn('queue running')
      return
    }

    if (this.activeQueueItem) {
      console.warn('trying to process queue when active buff')
      return
    }

    this.queueLock = true

    if (delay) {
      await wait(delay)
    }

    const findFn = (() => {
      switch (this.queueType) {
        case BuffQueueType.VOD:
          return this.findActiveBuffVOD

        case BuffQueueType.TIME_SYNC:
          return this.findActiveBuffTimeSync

        case BuffQueueType.PRE_STREAM_MODE:
          return this.findActiveBuffPreStream

        case BuffQueueType.LIVE:
        default:
          return this.findActiveBuffLive
      }
    })()

    const activeQueueItem = findFn()

    const buff = activeQueueItem?.buff

    /**
     * User language check
     *
     * Stream winners has no langs on it so skipping
     */
    if (buff && !isStreamWinner(buff)) {
      let buffLanguages: LanguageCode[] = []

      if (isVoteable(buff) || isVodVoteable(buff) || isWelcomeBuff(buff)) {
        buffLanguages = Object.keys(
          buff.question.localisations
        ) as LanguageCode[]
      }

      if (isAnnouncement(buff)) {
        buffLanguages = Object.keys(
          buff.content.localisations
        ) as LanguageCode[]
      }

      // Remove and process queue if not in user lang
      if (!buffLanguages.includes(this.language)) {
        this.queueLock = false
        this.processQueue()
        return
      }
    }

    if (activeQueueItem?.beforeActive) {
      const display = await Promise.resolve(
        activeQueueItem.beforeActive(activeQueueItem)
      ).catch((error) => {
        logError(error)
      })

      // Item is not to be displayed so remove and process queue
      if (display === false) {
        this.queueLock = false
        this.processQueue()
        return
      }
    }

    if (activeQueueItem) {
      const now = Date.now()
      this.activeQueueItem = activeQueueItem
      this.activeQueueItem.displayedAt = now
      this.displayedAtMap[getBuffId(activeQueueItem.buff)] = now
      this.emit(BuffQueueEventName.ACTIVE_QUEUE_CHANGE, activeQueueItem)

      const duration =
        getDisplayDuration(activeQueueItem.buff, activeQueueItem.eventType) *
          1000 +
        ANIMATION_BUFFER

      this.timeout = window.setTimeout(() => {
        let queueDelay = ANIMATION_BUFFER

        if (this.queueType === BuffQueueType.PRE_STREAM_MODE) {
          queueDelay = this.preStreamBuffFrequency * 1000
        }

        this.activeQueueItem = undefined
        this.emit(BuffQueueEventName.ACTIVE_QUEUE_CHANGE, undefined)
        this.processQueue(queueDelay)
        clearTimeout(this.removeTimeout)
      }, duration)
    }

    this.queueLock = false
  }

  /**
   * Removes the active queue item
   *
   * @param {number} duration
   * @param {boolean} skipQueueCheck
   */
  public removeActiveQueueItem(
    duration: number = 0,
    skipQueueCheck: boolean = false
  ) {
    if (!this.activeQueueItem) {
      console.warn('no buff to remove')
      return
    }

    clearTimeout(this.timeout)
    clearTimeout(this.removeTimeout)

    const remove = () => {
      this.activeQueueItem = undefined
      this.emit(BuffQueueEventName.ACTIVE_QUEUE_CHANGE, undefined)
      if (!skipQueueCheck) {
        let queueDelay = ANIMATION_BUFFER

        if (this.queueType === BuffQueueType.PRE_STREAM_MODE) {
          queueDelay = this.preStreamBuffFrequency * 1000
        }
        this.processQueue(queueDelay)
      }
    }

    // TODO: Decide if want to keep this
    if (duration) {
      this.removeTimeout = window.setTimeout(() => {
        remove()
      }, duration)
    } else {
      remove()
    }
  }

  /**
   * removes the active buff and clears queue
   */
  public clearQueue() {
    if (this.activeQueueItem) this.removeActiveQueueItem(0, true)
    this.queueList = []
    this.queueMessages = {}
    clearTimeout(this.timeout)
    clearTimeout(this.removeTimeout)
  }

  /**
   * TODO:
   * @param {number} time Time in a unix timestamp string when a TIME_SYNC stream. Otherwise time in seconds for a VOD stream
   */
  public updateGameTime(time: number) {
    if (
      this.queueType === BuffQueueType.LIVE ||
      this.queueType === BuffQueueType.PRE_STREAM_MODE
    ) {
      return
    }

    if (this.queueType === BuffQueueType.TIME_SYNC) {
      this.gameTime = new Date(time)
    } else if (this.queueType === BuffQueueType.VOD) {
      this.gameTime = time
    }

    if (!this.activeQueueItem && !this.snooze) {
      this.processQueue()
    }
  }

  /**
   * Function called when game has ended
   * @param {boolean} ended
   */
  public setGameEnded(ended: boolean) {
    if (this.gameId) {
      this.gameEndedMap.set(this.gameId, ended)
    }
    this.clearQueue()
  }

  /**
   * Function called to update the current game id
   * TODO: Clear queue when game id is updated?
   * @param {string} gameId
   */
  public setGameId(gameId?: string) {
    this.gameId = gameId
  }

  /**
   * Function called to update the current stream id
   * @param {string} streamId
   */
  public setStreamId(streamId?: string) {
    this.streamId = streamId
  }

  /**
   * Returns an engagement stored in the queue class
   * @param {string} voteableId
   * @return {Engagement | undefined}
   */
  public getEngagement(voteableId: string) {
    return this.engagementData[voteableId]
  }

  /**
   * Fetches engagement data and stores in class
   * @param {string} gameId
   * @param {string} voteableId
   * @return {Engagement}
   */
  private async fetchEngagement(gameId: string, voteableId: string) {
    const engagement = await getVoteableEngagementById(gameId, voteableId)
    this.engagementData[voteableId] = engagement
    return engagement
  }

  /**
   * Fetches vote status and stores in class
   * @param {string} gameId
   * @param {string} voteableId
   * @return {Vote}
   */
  private async fetchVoteStatus(gameId: string, voteableId: string) {
    if (!this.userId) return
    try {
      const vote = await getVoteStatus(gameId, voteableId, this.userId)
      this.voteStatus[voteableId] = vote
      this.emit(BuffQueueEventName.VOTE_STATE_CHANGE, this.voteStatus)
      return vote
    } catch (error) {
      // User has not voted when a 404 is returned
      if (axios.isAxiosError(error) && error.response?.status === 404) {
        return undefined
      }

      throw new Error(error)
    }
  }

  /**
   * Handles adding items to queue after receiving messages from pubsub
   * @param {unknown} dirtyEvent Event to format and decided what to do with
   */
  public async handleStreamPubSubMessage(dirtyEvent: unknown) {
    try {
      const event = formatEventObject(dirtyEvent)
      if (event === false || !isGamePubSubEvent(event) || !this.gameId) {
        return
      }

      this.queueMessages[event.id] = dirtyEvent as CentrifugoEvent

      if (this.snooze) {
        if (event.name === PubSubEventType.VOTEABLE_OPEN) {
          const missedBuffs = Number(
            localStorage.getItem(missedBuffsStorageKey) ?? 0
          )
          const missedBuffIncremented = missedBuffs + 1
          localStorage.setItem(
            missedBuffsStorageKey,
            missedBuffIncremented.toString()
          )
        }
        return
      }

      const loadVoteableTypes = [
        PubSubEventType.VOTEABLE_OPEN,
        PubSubEventType.VOTEABLE_CLOSE,
        PubSubEventType.VOTEABLE_RESOLVE,
      ]

      if (event.name === PubSubEventType.END_GAME) {
        if (event.body.gameId === this.gameId) {
          this.setGameEnded(true)
          this.clearQueue()
        }
      }

      if (
        event.name === PubSubEventType.ANNOUNCEMENT_OPEN &&
        event.body.announcementId
      ) {
        let announcement: Announcement | undefined = undefined
        try {
          announcement = await getAnnouncementById(
            event.body.gameId,
            event.body.announcementId
          )
        } catch (error) {
          logError(error)
        }

        if (!announcement) return

        const publishTime = new Date(announcement.opensAt)

        this.addQueueItem({
          eventId: event.id,
          eventType: event.name,
          buff: announcement,
          visibleAt: publishTime,
        })
      }

      const gameEnded = Boolean(this.gameEndedMap.get(this.gameId))

      if (
        loadVoteableTypes.includes(event.name) &&
        event.body.voteableId &&
        !gameEnded
      ) {
        let voteable: Voteable | undefined = undefined
        try {
          voteable = await getVoteable(
            event.body.gameId,
            event.body.voteableId,
            event.name
          )

          // Hide expire state for quiz and popular votes
          const buffType = getBuffType(voteable)
          const typesToHideExpired = [BuffType.QUIZ, BuffType.POPULAR_VOTE]
          if (
            event.name === PubSubEventType.VOTEABLE_CLOSE &&
            buffType &&
            typesToHideExpired.includes(buffType)
          ) {
            return
          }
        } catch (error) {
          logError(error)
        }

        if (!voteable) return
        const publishTime = new Date(voteable.opensAt)
        const expiryTime = new Date(voteable.closesAt)
        const eventType = event.name

        this.addQueueItem({
          eventId: event.id,
          eventType,
          buff: voteable,

          visibleAt: publishTime,
          closesAt: expiryTime,

          beforeActive: async () => {
            if (!voteable) return
            const buffType = getBuffType(voteable)
            if (
              eventType !== PubSubEventType.VOTEABLE_RESOLVE &&
              eventType !== PubSubEventType.VOTEABLE_CLOSE
            ) {
              return
            }

            const [engagement] = await Promise.all([
              this.fetchEngagement(event.body.gameId, voteable.voteableId),
              this.fetchVoteStatus(event.body.gameId, voteable.voteableId),
            ])

            const buffsToHideOnNoStats = [
              BuffType.POLL,
              BuffType.EMOJI,
              BuffType.STAR,
            ]

            // Hide polls which have no votes on them when they show expired state
            if (
              buffType &&
              buffsToHideOnNoStats.includes(buffType) &&
              eventType === PubSubEventType.VOTEABLE_CLOSE &&
              engagement &&
              (engagement.votesCounted === undefined ||
                engagement.votesCounted === 0)
            ) {
              // removes item from queue
              return false
            }
          },
        })
      }
    } catch (error) {
      logError(error)
    }
  }

  /**
   * Handles adding items to queue after receiving messages from pubsub for stream winners
   * @param {unknown} dirtyEvent Event to format and decided what to do with
   */
  public async handleStreamWinnerPubSubMessage(dirtyEvent: unknown) {
    const event = formatEventObject(dirtyEvent)

    if (event && isStreamWinnerPubSubEvent(event)) {
      try {
        const leaderboardId = event.body.leaderboardId
        const winnerIds = event.body.winners
        if (!leaderboardId || !winnerIds) return

        const streamWinner = await getStreamWinner(
          leaderboardId,
          winnerIds,
          this.userId,
          this.streamId
        )

        if (streamWinner) {
          this.addQueueItem({
            eventId: event.id,
            eventType: event.name,
            buff: streamWinner,
            visibleAt: new Date(),
          })
        }

        return
      } catch (error) {
        logError(error)
      }
    }
  }

  /**
   * Handler to transform and add VOD buffs to the queue
   * @param {VodBuff[]} buffs
   */
  public handleVODBuffs(buffs: VodBuff[]) {
    const gameId = this.gameId
    if (!gameId) {
      return
    }

    const list: BuffQueueItem[] = buffs
      .map((buff) => {
        if (isVodVoteable(buff)) {
          const items: BuffQueueItem[] = []
          const type = getBuffType(buff)

          items.push({
            buff,
            eventType: PubSubEventType.VOTEABLE_OPEN,
          })

          if (type && expiredTypes.includes(type) && buff.closedAtSeconds) {
            items.push({
              buff,
              eventType: PubSubEventType.VOTEABLE_CLOSE,
              beforeActive: async () => {
                const [engagement] = await Promise.all([
                  this.fetchEngagement(gameId, buff.id),
                  this.fetchVoteStatus(gameId, buff.id),
                ])

                // Don't show poll types that have no votes on them
                if (
                  buff.lifecycle ===
                    VoteableLifecycle.VOTEABLE_LIFECYCLE_POLL &&
                  engagement &&
                  !engagement.votesCounted
                ) {
                  return false
                }
              },
            })
          }

          if (
            type &&
            resolveableTypes.includes(type) &&
            buff.resolvedAtSeconds
          ) {
            items.push({
              buff,
              eventType: PubSubEventType.VOTEABLE_RESOLVE,
              beforeActive: async () => {
                await Promise.all([
                  this.fetchEngagement(gameId, buff.id),
                  this.fetchVoteStatus(gameId, buff.id),
                ])
              },
            })
          }

          return items
        }

        if (isAnnouncement(buff)) {
          return [
            {
              buff,
              eventType: PubSubEventType.ANNOUNCEMENT_OPEN,
            },
          ]
        }

        throw new Error('Cannot transform vod list')
      })
      .flat()

    this.addQueueItems(list)
  }

  /**
   * Handler to transform and add buffs to the queue
   * @param {Buff[]} buffs
   */
  public handlePreStreamBuffs(buffs: Buff[]) {
    const gameId = this.gameId
    if (!gameId) {
      return
    }

    const list: BuffQueueItem[] = buffs
      .map((buff) => {
        if (isVoteable(buff)) {
          const items: BuffQueueItem[] = []
          const type = getBuffType(buff)

          items.push({
            buff,
            eventType: PubSubEventType.VOTEABLE_OPEN,
            visibleAt: new Date(buff.opensAt),
            beforeActive: async () => {
              const vote = await this.fetchVoteStatus(gameId, buff.voteableId)
              if (vote) return false
            },
          })

          if (type && expiredTypes.includes(type) && buff.closesAt) {
            items.push({
              buff,
              eventType: PubSubEventType.VOTEABLE_CLOSE,
              visibleAt: new Date(buff.closesAt),
              beforeActive: async () => {
                await this.fetchVoteStatus(gameId, buff.voteableId)
                const engagement = await this.fetchEngagement(
                  gameId,
                  getBuffId(buff)
                )

                // Don't show poll types that have no votes on them
                if (
                  buff.lifecycle ===
                    VoteableLifecycle.VOTEABLE_LIFECYCLE_POLL &&
                  engagement &&
                  !engagement.votesCounted
                ) {
                  return false
                }
              },
            })
          }

          if (type && resolveableTypes.includes(type) && buff.resolvesAt) {
            items.push({
              buff,
              visibleAt: new Date(buff.resolvesAt),
              eventType: PubSubEventType.VOTEABLE_RESOLVE,
              beforeActive: async () => {
                await Promise.all([
                  this.fetchVoteStatus(gameId, buff.voteableId),
                  this.fetchEngagement(gameId, buff.voteableId),
                ])
              },
            })
          }

          return items
        }

        if (isAnnouncement(buff)) {
          return [
            {
              buff,
              visibleAt: new Date(buff.opensAt),
              eventType: PubSubEventType.ANNOUNCEMENT_OPEN,
            },
          ]
        }

        throw new Error('Cannot transform pre game buff list')
      })
      .flat()
    this.addQueueItems(list)
    this.processQueue()
  }

  /**
   * Cast vote
   * @param {string} gameId
   * @param {string} buffId
   * @param {string} answerId
   * @param {string | undefined}userId
   * @return {Promise<Vote | undefined>}
   */
  public async castVote(
    gameId: string,
    buffId: string,
    answerId: string,
    userId?: string
  ) {
    if (!userId) return
    const displayedAt = this.displayedAtMap[buffId]
    let viewedAt: string | undefined = undefined

    if (typeof displayedAt === 'number') {
      const date = new Date(displayedAt)

      // Setting viewedAt to be 2s later than actual time as we delay voting by 2s
      date.setSeconds(date.getSeconds() + 2)

      viewedAt = `${date.toISOString().split('.')[0]}Z`
    }

    const vote = await castVote(gameId, buffId, answerId, userId, viewedAt)
    this.voteStatus[buffId] = vote
    this.emit(BuffQueueEventName.VOTE_STATE_CHANGE, this.voteStatus)
    return vote
  }
}
