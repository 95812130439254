import React, {
  createContext,
  FC,
  useEffect,
  useContext,
  useReducer,
  Dispatch,
  useMemo,
  useState,
} from 'react'
import { StreamContext } from '@services/providers/StreamProvider'
import {
  leaderboardReducer,
  initialState,
  LeaderboardActionTypes,
  LeaderboardActions,
} from './leaderboardReducer'
import { Currency, Leaderboard, PaymentOption } from '@interfaces/leaderboard'
import { useGetStreamLeaderboardsById } from '@utils/hooks/useGetStreamLeaderboardsById'
import { claimTwitchPayment } from '@services/requests/leaderboard-v2'
import { TRANSACTION_COMPLETED, TRANSACTION_ID } from '../../../constants'
import { queryClient } from '@utils/reactQuery'
import { useWebSocketClient } from '@utils/hooks/useWebSocketClient'
import { formatEvent, isPubSubEvent } from '@utils/pubSub'
import { useValueAsRef } from '@utils/hooks/useValueAsRef'
import { UserContext } from '../UserProvider'
import { useNavigate } from 'react-router-dom'
import { RoutePaths } from '@interfaces/navigation'
import { useFloatingTabsContext } from '../FloatingTabsProvider'

export interface LeaderboardContextProps {
  leaderboards: Leaderboard[]
  leaderboardIds: string[]
  /**
   * Active leaderboard id
   */
  activeLeaderboardId: string
  isVipLeaderboard: boolean
  /**
   * Dispatcher
   */
  dispatch: Dispatch<LeaderboardActions>
}

export const LeaderboardContext = createContext<LeaderboardContextProps>(
  {} as LeaderboardContextProps
)

const emptyArray: string[] = []

export const LeaderboardProvider: FC = ({ children }) => {
  const navigate = useNavigate()
  const [isVipLeaderboard, setIsVipLeaderboard] = useState(false)
  const { setOpen } = useFloatingTabsContext()
  const { stream } = useContext(StreamContext)
  const { userId, token } = useContext(UserContext)
  const { data: leaderboards, refetch } = useGetStreamLeaderboardsById(
    stream?.id
  )
  const [state, dispatch] = useReducer(leaderboardReducer, initialState)

  const { subscribe, unsubscribe } = useWebSocketClient()

  const { activeLeaderboardId } = state

  const leaderboardIds = useMemo(() => {
    return leaderboards?.length > 0
      ? leaderboards.map((leaderboard: Leaderboard) => leaderboard.id)
      : emptyArray
  }, [leaderboards])

  const leaderboardIdsRef = useValueAsRef(leaderboardIds)

  const props = {
    leaderboardIds,
    leaderboards,
    activeLeaderboardId,
    isVipLeaderboard,
    dispatch,
  }

  useEffect(() => {
    if (!leaderboards) return

    const currentLeaderboardIdx = leaderboards.findIndex(
      (leaderboard: Leaderboard) => leaderboard.id === activeLeaderboardId
    )

    const checkVIPLeaderboard = async () => {
      // TODO: remove true flag when we find a way to test bits
      if (window.Twitch?.ext?.features.isBitsEnabled || true) {
        const products = await window.Twitch?.ext?.bits.getProducts()
        const matchingProduct = products?.findIndex((product) => {
          return leaderboards?.[currentLeaderboardIdx]?.paymentOptions.some(
            (po: PaymentOption) => po.twitchSku === product.sku
          )
        })
        if (matchingProduct > -1) {
          setIsVipLeaderboard(true)
        }
      }
    }
    if (currentLeaderboardIdx === -1) {
      dispatch({
        type: LeaderboardActionTypes.ACTIVE_LEADERBOARD,
        payload: {
          leaderboardId: leaderboards[0]?.id,
        },
      })
    } else {
      const lastItemIndex =
        leaderboards?.[currentLeaderboardIdx]?.paymentOptions?.length - 1
      if (
        lastItemIndex > -1 &&
        leaderboards?.[currentLeaderboardIdx]?.paymentOptions?.[lastItemIndex]
          ?.currency === Currency.TWITCH_BITS
      ) {
        checkVIPLeaderboard()
      }
    }
  }, [activeLeaderboardId, leaderboards])

  useEffect(() => {
    if (!stream?.id) return

    refetch()
  }, [refetch, stream?.id])

  useEffect(() => {
    const streamId = stream?.id ?? ''
    const leaderboardsChannel = !!streamId
      ? `streams.${streamId}.leaderboards.updated`
      : undefined

    if (!leaderboardsChannel) return

    const subscription = subscribe(leaderboardsChannel, (message) => {
      const event = formatEvent(message)
      if (!isPubSubEvent(event)) return
      queryClient.invalidateQueries(['stream-leaderboards', streamId])
      if (leaderboardIdsRef?.current?.length) {
        leaderboardIdsRef.current.forEach((leaderboardId: string) => {
          queryClient.invalidateQueries(['leaderboard', leaderboardId, 'meta'])
        })
      }
    })

    return () => {
      !!subscription && unsubscribe(subscription)
    }
  }, [subscribe, unsubscribe, stream?.id, leaderboardIdsRef])

  useEffect(() => {
    window.Twitch?.ext?.bits.onTransactionComplete(async (transaction) => {
      // @ts-ignore
      localStorage.setItem(TRANSACTION_ID, transaction.transactionId)
    })

    window.Twitch?.ext?.bits.onTransactionCancelled(() => {
      console.log('transaction cancelled')
    })
  }, [])

  useEffect(() => {
    const claimTwitchTransaction = async (id: string) => {
      try {
        const leaderboardId = localStorage.getItem('ACTIVE_LEADERBOARD')

        if (leaderboardId) {
          await claimTwitchPayment({
            streamId: stream?.id ?? '',
            leaderboardId: leaderboardId,
            transactionId: id,
            jwt: token,
          })
          queryClient.invalidateQueries([
            'leaderboard-standing',
            leaderboardId,
            userId,
          ])
          localStorage.setItem(TRANSACTION_COMPLETED, leaderboardId)
          navigate(RoutePaths.PURCHASE)
          setOpen(true)
        }
      } catch (error) {
        console.error(error)
      }
    }
    const transactionId = localStorage.getItem(TRANSACTION_ID)
    if (!!token?.length && !!transactionId?.length) {
      claimTwitchTransaction(transactionId)
      localStorage.removeItem(TRANSACTION_ID)
    }
  }, [token, navigate, setOpen, stream?.id, userId])

  return (
    <LeaderboardContext.Provider value={props}>
      {children}
    </LeaderboardContext.Provider>
  )
}
