import React, { ReactNode, useContext, useEffect, useState } from 'react'
import { UserContext } from '@services/providers/UserProvider'
import { ConfigContext } from '@services/providers/ConfigProvider'
import { languagesMap } from '@utils/language'
import { Trans, useTranslation } from 'react-i18next'
import { TFunction } from 'i18next'
import { LanguageCode } from '@interfaces/languages'

interface UseSignupText {
  text: string | ReactNode
  actionText: string
}

type Placement =
  | 'buffInfoPill'
  | 'leaderboardBanner'
  | 'settingsTab'
  | 'profileTab'
  | 'friendsTab'
  | 'reactionsTab'

interface UseSignupTextArgs {
  placement: Placement
}

const getDefaultSignupText = (
  placement: Placement,
  isTwitch: boolean,
  t: TFunction
) => {
  const twitchActionText = t('settings.linkAccountOffer')
  const twitchText = t('settings.linkAccount')

  const signUpActionText = isTwitch ? twitchText : t('settings.loginOrSignup')
  const signUpText = isTwitch ? twitchActionText : t('settings.signupOffer')

  const buffInfoPillText = (
    <Trans
      i18nKey={isTwitch ? 'auth.signUpToEarnTwitch' : 'auth.signUpToEarn'}
      components={[
        <span key="index_1" className="text-infopill-action-text"></span>,
      ]}
    />
  )

  const reactionsText = (
    <Trans
      i18nKey={'auth.signUpToSendReaction'}
      components={[
        <span
          key="index_1"
          className="text-reactionPanel-action-text font-semibold"
        ></span>,
      ]}
    />
  )

  switch (placement) {
    case 'buffInfoPill':
      return {
        text: buffInfoPillText,
        actionText: '',
      }
    case 'reactionsTab':
      return {
        text: reactionsText,
        actionText: '',
      }
    case 'leaderboardBanner':
      return {
        text: isTwitch
          ? twitchActionText
          : t('settings.leaderboardSignupOffer'),
        actionText: isTwitch ? twitchText : t('settings.signup'),
      }
    default:
      return {
        text: signUpText,
        actionText: signUpActionText,
      }
  }
}

export const useSignupText = ({
  placement,
}: UseSignupTextArgs): UseSignupText => {
  const { userLanguage } = useContext(UserContext)
  const { widgetConfig, clientConfig } = useContext(ConfigContext)
  const { t } = useTranslation()

  const isTwitch =
    widgetConfig?.player === 'twitch-mobile' ||
    widgetConfig?.player === 'twitch-web'

  const [text, setText] = useState(() => {
    return getDefaultSignupText(placement, isTwitch, t).text
  })
  const [actionText] = useState(() => {
    return getDefaultSignupText(placement, isTwitch, t).actionText
  })

  const language = languagesMap[userLanguage]?.locale as LanguageCode

  useEffect(() => {
    const signupTexts =
      clientConfig?.signup?.localisations?.[language]?.[placement]
    if (!!signupTexts) {
      setText(signupTexts)
    }
  }, [language, clientConfig?.signup?.localisations, placement])

  return { text, actionText }
}
