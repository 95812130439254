const getGradientContainerStyles = (
  from: string,
  to: string,
  rotation: string
) => {
  const gradientVars: { [key: string]: string } = {
    '--gradient-from': from,
    '--gradient-to': to,
    '--gradient-rotation': rotation,
  }

  return {
    ...gradientVars,
  }
}

export default getGradientContainerStyles
