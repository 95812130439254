import React, { FC, ReactNode } from 'react'
import Switch from '@components/atoms/Switch'
import ConditionalWrapper from '@components/atoms/ConditionalWrapper'
import { IconButtonProps } from './types'

const IconButton: FC<IconButtonProps> = ({
  content,
  active,
  text,
  subtext,
  onClick,
  rightActionIcon,
  className = '',
  disabled = false,
  fullRowClickable = false,
  'data-testid': testId = 'icon-button',
}) => {
  const bgOpacity = disabled ? 'opacity-20' : 'opacity-100'

  return (
    <ConditionalWrapper
      condition={fullRowClickable}
      wrapper={(children: ReactNode) => (
        <button type="button" data-testid={testId} onClick={onClick}>
          {children}
        </button>
      )}
    >
      <div
        className={`flex items-center justify-between w-full ${className} ${bgOpacity}`}
      >
        <span className="inline-flex items-center">
          <span className="icon-button__button flex items-center justify-center h-8 w-8 rounded-full overflow-hidden text-2xl bg-floatingPanel-inverse-bg">
            {content}
          </span>
          {text && (
            <span className="ms-2 text-sm text-floatingPanel-default-text">
              {text}
            </span>
          )}
        </span>
        {rightActionIcon ? (
          <ConditionalWrapper
            condition={!fullRowClickable}
            wrapper={(children: ReactNode) => (
              <button type="button" data-testid={testId} onClick={onClick}>
                {children}
              </button>
            )}
          >
            <div className="p-2">{rightActionIcon}</div>
          </ConditionalWrapper>
        ) : (
          <Switch
            readOnly
            isChecked={active}
            data-testid={testId}
            data-active={active}
            text={subtext}
            onClick={onClick}
          />
        )}
      </div>
    </ConditionalWrapper>
  )
}

export default IconButton
