import React, { ReactElement, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { Buff, BuffType } from '@interfaces/buff'
import {
  InfoBubbleProps,
  InfoBubbleHighlightColors,
} from '@components/atoms/InfoBubble'
import { PubSubEventType } from '@interfaces/pubSub'
import {
  getBuffType,
  getBuffTypeKeyName,
  isAnnouncement,
  isStreamWinner,
  isVodVoteable,
  isVoteable,
} from '@utils/buff'
import { LanguageCode } from '@interfaces/languages'
import { ConfigContext } from '@services/providers/ConfigProvider'
import { Vote } from '@interfaces/vote'
import { useSignupText } from './useSignupTexts'

export const HURRY_UP_SHOW_DURATION = 6

export const useBuffInfoBubble = (
  buff?: Buff,
  pubSubEventType?: PubSubEventType,
  selectedAnswerId?: string,
  activeLanguage?: LanguageCode,
  vote?: Vote,
  isAuth: boolean = true,
  isTwitch: boolean = false,
  allowGamePoints: boolean = true,
  duration?: number
): InfoBubbleProps | false => {
  const { t } = useTranslation()
  const type = getBuffType(buff)
  const { widgetConfig } = useContext(ConfigContext)

  const { text: signUpText } = useSignupText({
    placement: 'buffInfoPill',
  })

  if (!buff) return false
  if (widgetConfig?.tvDeviceId) return false
  const commonProps = {
    className:
      'mb-1 absolute bottom-full text-infopill-default-text font-secondary start-0',
    'data-testid': 'BuffCore__InfoBubble',
    bg: `bg-gradient-to-r from-infopill-default-bg-startColor to-infopill-default-bg-endColor`,
  }

  if ((isVoteable(buff) || isVodVoteable(buff)) && !isAuth) {
    return {
      ...commonProps,
      text: signUpText as ReactElement,
      'data-testid': 'infoBubble__non-auth',
      className: `${commonProps.className} cursor-pointer text-infopill-action-text`,
    }
  }

  if (isStreamWinner(buff)) {
    return false
  }

  if (
    isAnnouncement(buff) &&
    activeLanguage &&
    buff?.content?.localisations?.[activeLanguage]?.titleText
  ) {
    return {
      ...commonProps,
      'data-testid': 'infoBubble__announcement',
      text: buff?.content?.localisations?.[activeLanguage]?.titleText ?? '',
    }
  }

  if (!(isVoteable(buff) || isVodVoteable(buff))) return false

  if (
    pubSubEventType === PubSubEventType.VOTEABLE_OPEN &&
    Number(vote?.participationPoints) > 0 &&
    allowGamePoints
  ) {
    const text =
      vote?.participationPoints === 1
        ? t('buff.bonusTimePoint')
        : t('buff.bonusTimePoints')

    return {
      ...commonProps,
      text: `${text}`,
      highlightText: `+${vote?.participationPoints}`,
      highlightColor: InfoBubbleHighlightColors.SUCCESS,
      'data-testid': 'infoBubble__participation-points',
    }
  }

  if (
    pubSubEventType === PubSubEventType.VOTEABLE_OPEN &&
    !!isAuth &&
    !vote &&
    Number(duration) <= HURRY_UP_SHOW_DURATION
  ) {
    const text = t('buff.hurryUp')

    return {
      ...commonProps,
      text: `${text}`,
      'data-testid': 'infoBubble__hurry-up',
    }
  }

  if (pubSubEventType === PubSubEventType.VOTEABLE_OPEN && !!isAuth) {
    const type = getBuffType(buff)
    const text = getBuffTypeKeyName(type)

    return {
      ...commonProps,
      text: t(text),
      'data-testid': 'infoBubble__buff-type',
    }
  }

  const getPointsText = (points: number) => {
    return points === 1
      ? t('buff.singlePoint', { points })
      : t('buff.multiplePoints', { points })
  }

  const usersAnswer = buff?.answers?.find(
    (answer) => answer.id === selectedAnswerId
  )
  const hasSelectedCorrectAnswer = usersAnswer?.correct ?? false
  const hasSelectedAnswerHasPoints = Number(usersAnswer?.points) > 0

  // Resolved type should show feedback to the user for Quizzes, Predictions and Popular Votes
  if (
    pubSubEventType === PubSubEventType.VOTEABLE_RESOLVE &&
    selectedAnswerId !== undefined
  ) {
    let text = t('buff.thanksForVoting')
    let highlightText = undefined

    if (hasSelectedCorrectAnswer && hasSelectedAnswerHasPoints) {
      const correctText =
        type !== BuffType.POPULAR_VOTE ? `${t('general.correct')}!` : ''
      text = t('buff.niceWork')
      highlightText = allowGamePoints
        ? getPointsText(usersAnswer?.points ?? 0)
        : correctText
    }

    if (!hasSelectedCorrectAnswer && type !== BuffType.POPULAR_VOTE) {
      text = t('buff.niceTry')
      highlightText = t('buff.incorrect')
    }

    const highlightColor = hasSelectedCorrectAnswer
      ? InfoBubbleHighlightColors.SUCCESS
      : InfoBubbleHighlightColors.ERROR

    const testId = hasSelectedCorrectAnswer
      ? 'infoBubble__correct-user-feedback'
      : 'infoBubble__incorrect-user-feedback'

    return {
      ...commonProps,
      text,
      highlightText,
      highlightColor,
      'data-testid': testId,
    }
  }

  if (
    pubSubEventType === PubSubEventType.VOTEABLE_CLOSE &&
    selectedAnswerId !== undefined
  ) {
    const text = t('buff.thanksForVoting')
    return {
      ...commonProps,
      text,
      highlightColor: InfoBubbleHighlightColors.PARTICIPATION,
      'data-testid': 'infoBubble__participation-points',
    }
  }

  return false
}
