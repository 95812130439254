import './createShadowDOM'

import React, { MutableRefObject, Suspense } from 'react'
import { createRoot, Root } from 'react-dom/client'
import * as Sentry from '@sentry/react'
import Widget, { IWidgetProps } from '@components/Widget'
import { initPlayerOverlay } from '@utils/initPlayerOverlay'
import { getVideoPlayer } from '@utils/videoPlayer'
import { WidgetConfig, WidgetLayout } from '@interfaces/widget'
import { initSentry } from '@utils/sentry'
import { loadPolyfills } from '@utils/polyfills'
import { BuffProviderRef } from '@services/providers/BuffProvider'
import { checkValidConfig } from '@utils/widgetConfig'
import { VideoPlayer } from '@interfaces/videoPlayer'
import './i18n'
import './styles.css'
import { BUFFUP_SDK_REFRESH_TOKEN } from './constants'
import storage from '@utils/storage'
import { refreshJwtToken } from '@services/requests/auth'

export const App = (props: IWidgetProps) => {
  return (
    <Sentry.ErrorBoundary showDialog={false}>
      <Widget {...props} />
    </Sentry.ErrorBoundary>
  )
}

App.displayName = 'App'

/**
 * Needs to be called after user clicking an element otherwise requesting full screen won't work
 * @param {WidgetConfig} config
 * @return {void}
 */
const toggleFullScreenHandler = (config: WidgetConfig) => {
  const doc: any = document
  let wrapperFullscreen: any

  if (config.fullScreenElement instanceof Element) {
    wrapperFullscreen = config.fullScreenElement
  } else if (typeof config.fullScreenElement === 'string') {
    const element = document.querySelector(config.fullScreenElement)
    if (element === null) {
      throw new Error(
        `cannot find fullScreenElement with ${config.fullScreenElement}`
      )
    }
    wrapperFullscreen = element
  }

  return () => {
    if (!wrapperFullscreen) return
    if (!doc.fullscreenElement && !doc['webkitFullscreenElement']) {
      if (wrapperFullscreen?.requestFullscreen) {
        wrapperFullscreen?.requestFullscreen()
      } else if (wrapperFullscreen?.mozRequestFullScreen) {
        wrapperFullscreen?.mozRequestFullScreen()
      } else if (wrapperFullscreen?.webkitRequestFullscreen) {
        wrapperFullscreen?.webkitRequestFullscreen()
      } else if (wrapperFullscreen?.msRequestFullscreen) {
        wrapperFullscreen?.msRequestFullscreen()
      } else if (wrapperFullscreen?.webkitEnterFullscreen) {
        wrapperFullscreen?.webkitEnterFullscreen() // for iphone/tablet (iOS)
      }
    }

    if (doc?.fullscreenElement || doc['webkitFullscreenElement']) {
      if (doc?.exitFullscreen) {
        doc?.exitFullscreen()
      } else if (doc?.mozCancelFullScreen) {
        doc.mozCancelFullScreen()
      } else if (doc?.webkitExitFullscreen) {
        doc.webkitExitFullscreen()
      } else if (doc?.msExitFullscreen) {
        doc.msExitFullscreen()
      }
    }
  }
}

type UpdateArgs = Partial<
  Pick<WidgetConfig, 'streamId' | 'streamSourceId' | 'layout'>
>

interface BuffInstance {
  controls: VideoPlayer['controls']
  update: (args: UpdateArgs) => void
  destroy: () => void
  toggleFullScreen: () => void
  signUserIn: (refreshToken: string) => void
}

let buffInstance: BuffInstance | null = null

export const init = async (config: WidgetConfig) => {
  // Destroys old instance if still about
  if (buffInstance !== null) {
    console.warn(
      'Buff initialised before being destroyed. Please make sure the current instance is destroyed before initialising a new one.'
    )
    buffInstance.destroy()
    buffInstance = null
  }

  initSentry()
  const patchedConfig = {
    ...config,
    loadPolyfills: config.loadPolyfills ?? true,
    layout: config.layout ?? WidgetLayout.AUTO,
  }

  checkValidConfig(patchedConfig)

  if (patchedConfig.loadPolyfills) {
    await loadPolyfills()
  }

  const videoPlayer = await getVideoPlayer(patchedConfig)
  const configWithVideoPlayer = { ...patchedConfig, videoPlayer }
  const toggleFullScreen = toggleFullScreenHandler(configWithVideoPlayer)
  const overlay = await initPlayerOverlay(configWithVideoPlayer)
  if (!overlay) return

  const { container, reset } = overlay

  let streamId = patchedConfig.streamId
  let streamSourceId = patchedConfig.streamSourceId
  let layout = patchedConfig.layout
  let isAutoLayout = false
  let root: Root | undefined = undefined

  const mediaQuery = window.matchMedia('(min-width: 600px)')

  const handleMediaQueryChange = (e: MediaQueryListEvent) => {
    const newLayout = e.matches
      ? WidgetLayout.DESKTOP
      : WidgetLayout.PORTRAIT_TOGGLE_LEADERBOARD

    layout = newLayout
    render()
  }

  const addMediaQueryHandler = () => {
    mediaQuery.addEventListener('change', handleMediaQueryChange)
  }

  const removeMediaQueryHandler = () => {
    mediaQuery.removeEventListener('change', handleMediaQueryChange)
  }

  if (layout === WidgetLayout.AUTO) {
    layout = mediaQuery.matches
      ? WidgetLayout.DESKTOP
      : WidgetLayout.PORTRAIT_TOGGLE_LEADERBOARD

    isAutoLayout = true
    addMediaQueryHandler()
  }

  // patchedConfig.s
  // let streamTitle = config.streamTitle
  // let providerId = config.providerId
  const widgetRef: MutableRefObject<BuffProviderRef | undefined> = {
    current: undefined,
  }

  const render = () => {
    const { player = 'default' } = patchedConfig
    if (!player || player === 'twitch-mobile' || player === 'twitch-web') {
      throw new Error(`Cannot initialise SDK with ${player}`)
    }

    if (!root) {
      return
    }

    root.render(
      <Suspense fallback={'Loading...'}>
        <App
          innerRef={widgetRef}
          config={{
            ...patchedConfig,
            player,
            streamId,
            streamSourceId,
            videoPlayer,
            layout,
          }}
          toggleFullScreen={toggleFullScreen}
          container={container}
        />
      </Suspense>
    )
  }

  const update = (args: UpdateArgs) => {
    if (args.streamId !== undefined) {
      streamId = args.streamId
    }

    if (args.streamSourceId !== undefined) {
      streamSourceId = args.streamSourceId
    }

    if (args.layout) {
      layout = args.layout
      if (isAutoLayout && layout !== WidgetLayout.AUTO) {
        isAutoLayout = false
        removeMediaQueryHandler()
      }

      if (!isAutoLayout && layout === WidgetLayout.AUTO) {
        isAutoLayout = true
        layout = mediaQuery.matches
          ? WidgetLayout.DESKTOP
          : WidgetLayout.PORTRAIT_TOGGLE_LEADERBOARD
        addMediaQueryHandler()
      }
    }
    return render()
  }

  /**
   * Destroys the instance of the sdk
   */
  const destroy = () => {
    removeMediaQueryHandler()

    if (root) {
      root.unmount()
    }
    widgetRef.current = undefined
    reset()
    buffInstance = null
  }

  const signUserIn = async (refreshToken: string) => {
    videoPlayer?.controls?.customSignupCompleted(refreshToken)
  }

  root = createRoot(container)
  await render()

  const instance: BuffInstance = {
    toggleFullScreen,
    update,
    destroy,
    controls: videoPlayer?.controls,
    signUserIn,
  }

  buffInstance = instance

  return instance
}

export default {
  buffWidget: {
    new: init,
  },
}

setTimeout(() => {
  const win: any = window
  if (win.onSportBuffReady && typeof win.onSportBuffReady === 'function') {
    win.onSportBuffReady()
  }
}, 0)
