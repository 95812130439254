import React, { FC } from 'react'
import { SignupBannerProps } from './types'
import Button, { ButtonBackground, ButtonVariant } from '../Button'

export const SignupBanner: FC<SignupBannerProps> = ({
  text,
  buttonText,
  className,
  onClick,
}) => {
  return (
    <div
      data-testid="signup-banner"
      className={`flex flex-col p-4 bg-floatingPanel-banner-bg rounded border border-floatingPanel-border-bg items-center text-center ${className}`}
    >
      <p className="text-floatingPanel-banner-text text-sm">{text}</p>
      <Button
        onClick={onClick}
        background={ButtonBackground.PRIMARY}
        data-testid="signup-button"
        className="mt-4"
      >
        <span className="text-sm font-semibold">{buttonText}</span>
      </Button>
    </div>
  )
}

export const SmallSignupBanner: FC<SignupBannerProps> = ({
  text,
  buttonText,
  className,
  onClick,
}) => {
  return (
    <div
      data-testid="signup-banner"
      className={`flex flex-row w-full py-1 pe-2 ps-4 bg-floatingPanel-accent-default-bg items-center justify-between ${className}`}
    >
      <p className="text-floatingPanel-accent-default-text text-xs font-semibold">
        {text}
      </p>
      <Button
        onClick={onClick}
        variant={ButtonVariant.THIN}
        background={ButtonBackground.CTA}
        data-testid="signup-button"
        className="flex-shrink-0 px-1.5 py-1"
      >
        <span className="text-xs font-semibold">{buttonText}</span>
      </Button>
    </div>
  )
}
