import React, { useContext, useEffect, useState } from 'react'
import Image from '@components/atoms/Image'
import { ReactComponent as PersonIcon } from '@assets/icons/person.svg'
import { DebugContext } from '@services/providers/DebugProvider'
import { UserSummaryProps } from './types'

const UserSummary = ({ image, username }: UserSummaryProps) => {
  const [debugCount, setDebugCount] = useState<number>(0)
  const { debugInfo } = useContext(DebugContext)

  useEffect(() => {
    setTimeout(() => {
      if (debugCount === 5) {
        console.log(debugInfo)
        setDebugCount(0)
      }
    }, 2000)
  }, [debugCount, debugInfo])

  const handleDebug = () => {
    setDebugCount(debugCount + 1)
  }

  return (
    <div
      data-testid="user-summary"
      className="flex justify-between items-center w-full"
    >
      <div className="flex items-center me-2 flex-1">
        <div className="flex items-center justify-center rounded-md overflow-hidden me-1">
          <button
            className="cursor-default flex-shrink-0 w-5 h-5 rounded-md flex items-center justify-center bg-[#D1D6DE] overflow-hidden"
            onClick={handleDebug}
          >
            <Image
              data-testid="userSummary__image"
              className="w-full h-full object-cover"
              src={image}
              fallbackComponent={
                <PersonIcon
                  className="w-3 h-3 text-[#212121]"
                  data-testid="points-deck__placeholder-image"
                />
              }
            />
          </button>
        </div>
        <div className="flex justify-center flex-col flex-1 w-10 truncate">
          <span className="text-floatingPanel-default-text font-bold text-sm truncate">
            {username}
          </span>
        </div>
      </div>
    </div>
  )
}

export default UserSummary
