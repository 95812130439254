import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  useRef,
} from 'react'
import { ConfigContext } from '@services/providers/ConfigProvider'
import { StreamContext } from '@services/providers/StreamProvider'
import { registerScreen } from '@services/requests/screen'
import { Screen } from '@interfaces/screen'
import { VideoPlayerEvent } from '@interfaces/videoPlayer'
import { useValueAsRef } from '@utils/hooks/useValueAsRef'
import { TVClientConfig } from '@interfaces/clientConfig'
import { getClientConfig } from '@services/requests/clientConfig'
import { ScreenContextProps, ScreenProviderProps } from './types'

export const ScreenContext = createContext<ScreenContextProps>(
  {} as ScreenContextProps
)

export const ScreenProvider = ({ children }: ScreenProviderProps) => {
  const [screenDetails, setScreenDetails] = useState<Screen>()
  const [tvConfig, setTVConfig] = useState<TVClientConfig>()
  const [isQRVisible, setIsQRVisible] = useState<boolean>(true)
  const isScreenUpdatingRef = useRef<boolean>(false)
  const { stream } = useContext(StreamContext)
  const { widgetConfig } = useContext(ConfigContext)
  const { tvDeviceId } = widgetConfig

  const screenDetailsRef = useValueAsRef(screenDetails)

  const { qrCodeVisibleDuration, qrCodeVisibleFrequency, videoPlayer } =
    widgetConfig

  const streamId = stream?.id

  const props = {
    screenDetails,
    tvConfig,
    isQRVisible,
  }

  useEffect(() => {
    if (!tvDeviceId || !streamId || isScreenUpdatingRef.current) return
    isScreenUpdatingRef.current = true

    const fetchScreen = async () => {
      const screen = await registerScreen(tvDeviceId, streamId)

      setScreenDetails(screen)
      isScreenUpdatingRef.current = false
    }

    fetchScreen()
  }, [tvDeviceId, streamId, screenDetailsRef, isScreenUpdatingRef])

  useEffect(() => {
    if (!tvDeviceId) return

    const fetchClientConfig = async () => {
      const data = await getClientConfig()

      setTVConfig(data.tv)
    }

    fetchClientConfig()
  }, [tvDeviceId])

  /**
   * Triggers displaying QR code based on widget config props
   */
  useEffect(() => {
    if (!videoPlayer || !qrCodeVisibleFrequency || isQRVisible) return
    const ms = qrCodeVisibleFrequency * 1000
    const timeout = window.setTimeout(() => {
      videoPlayer.emit(VideoPlayerEvent.SHOW_QR_CODE)
    }, ms)

    return () => {
      clearTimeout(timeout)
    }
  }, [qrCodeVisibleFrequency, videoPlayer, isQRVisible])

  useEffect(() => {
    if (!videoPlayer || !qrCodeVisibleDuration) return
    let timeout: number

    const ms = qrCodeVisibleDuration * 1000

    const handleShowQRCode = () => {
      setIsQRVisible(true)
      clearTimeout(timeout)

      timeout = window.setTimeout(() => {
        setIsQRVisible(false)
      }, ms)
    }

    handleShowQRCode()
    videoPlayer.on(VideoPlayerEvent.SHOW_QR_CODE, handleShowQRCode)

    return () => {
      clearTimeout(timeout)
      videoPlayer.off(VideoPlayerEvent.SHOW_QR_CODE, handleShowQRCode)
    }
  }, [qrCodeVisibleDuration, videoPlayer])

  return (
    <ScreenContext.Provider value={props}>{children}</ScreenContext.Provider>
  )
}
