import { BuffSize } from '@interfaces/theme'
import { VideoPlayer } from '@interfaces/videoPlayer'

export type WidgetPlayer =
  | 'default'
  | 'jwplayer'
  | 'youtube'
  | 'videojs'
  | 'twitch-embed'
  | 'twitch-web'
  | 'twitch-mobile'
  | 'no-video'
  | 'custom'
  | 'custom-functions'

export interface LocalDebugUtilities {
  logs?: boolean
  player?: WidgetPlayer
  theme?: string
  /**
   * Size of the sdk, used for local dev purposes
   */
  localDevSDKSize?: BuffSize
}

export enum WidgetLayout {
  /**
   * Automatically changes layout based on media query.
   * NOTE: React should never see this value
   */
  AUTO = 'auto',

  DESKTOP = 'desktop',

  /**
   * Twitch mobile layout
   */
  PORTRAIT_FULL_LEADERBOARD = 'portrait-full-leaderboard',

  /**
   * Portrait layout with a leaderboard that's visibility can be toggled
   */
  PORTRAIT_TOGGLE_LEADERBOARD = 'portrait-toggle-leaderboard',
}

export interface WidgetConfig {
  /**
   * Refresh token provided by client from BE to BE call
   */
  token?: string

  /**
   * Element that should be made full screen when full screen button is clicked
   */
  fullScreenElement?: Element | string

  /**
   * Type of player being used
   * @default 'default'
   */
  player: WidgetPlayer

  /**
   * The name of the client used to direct api traffic to correct endpoints
   */
  clientName: string

  /**
   * Player instance required for one of the following player types:
   * - videojs
   * - jwplayer
   * @deprecated
   */
  playerInstance?: any

  /**
   * Video player instance. Only used internally currently
   * NOTE: Used to allow videojs player to be passed in here. No longer the case
   */
  videoPlayer?: VideoPlayer

  /**
   * Selector of the container to add the sdk to
   * Required for following player options:
   * - default
   * - youtube
   * - no-video
   * @deprecated
   */
  containerQuerySelector?: string

  /**
   * Will add a custom full screen button to the video container
   * Only supported in default & youtube mode
   * @default false
   */
  addFullScreenButton?: boolean

  /**
   * Id of stream that we want to initialize buff
   */
  streamId?: string

  /**
   * A secondary way to query for the stream sdk should initialise with.
   * streamId takes priority over this
   */
  streamSourceId?: string

  /**
   * Uses internal timer instead of stream playback time to fire vod buffs
   */
  playlistMode?: boolean

  /**
   * Used for custom-functions player type
   */
  appendContainer?: (containerToAppend: HTMLElement) => void

  /**
   * Used for custom-functions player type
   */
  destroyContainer?: () => void

  /**
   * Method that gets called when Buff UI is shown
   */
  onBuffUiVisible?: () => void

  /**
   * Method that gets called when Buff UI is hidden
   */
  onBuffUiHidden?: () => void

  /**
   * Method that gets called when the login button is clicked
   */
  onSignUp?: () => void

  /**
   * Unique ID that should be passed in when using web sdk on a TV
   */
  tvDeviceId?: string

  /**
   * ID that should be passed in when using web sdk in second screen mode
   */
  secondScreenDeviceId?: string

  /**
   * Number of seconds to display QR code for. In seconds
   */
  qrCodeVisibleDuration?: number

  /**
   * How frequently code is shown. In seconds
   */
  qrCodeVisibleFrequency?: number

  /**
   * Id of the twitch channel displaying the extension
   */
  twitchChannelId?: string

  /**
   * Id of the twitch user. Only provided if identity is shared with us
   */
  twitchUserId?: string

  /**
   * Layout of the SDK to render
   * @default WidgetLayout.DESKTOP
   */
  layout?: WidgetLayout

  /**
   * If the buffs should be fixed to the whole screen in portrait mode
   */
  fixedPortraitBuffs?: boolean

  /**
   * Log in user by generating anonymous login
   */
  anonymousLogin?: boolean

  /**
   * How frequently a buff is shown in seconds. Will put the SDK into a PRE_STREAM_MODE buff queue type
   */
  preStreamBuffFrequency?: number

  /**
   * If polyfills should be loaded when .init is called
   * @default true
   */
  loadPolyfills?: boolean
}
