import React from 'react'
import { CheckboxProps } from './types'

const Checkbox = ({
  containerClassname,
  label,
  id,
  ...props
}: CheckboxProps) => {
  return (
    <div
      className={`flex items-center ${containerClassname}`}
      data-testid="checkbox-container"
    >
      <input
        data-testid="checkbox-input"
        className="form-check-input appearance-none h-5 w-5 border !border-[#959DA5] rounded-[4px] bg-transparent checked:bg-floatingPanel-accent-default-bg text-floatingPanel-accent-default-bg focus:ring-0 focus:ring-offset-0 focus:outline-none !ring-transparent transition duration-200 align-top bg-no-repeat bg-center bg-contain float-left cursor-pointer"
        type="checkbox"
        value=""
        id={id}
        {...props}
      />
      {!!label && (
        <label
          data-testid="checkbox-label"
          className="inline-block text-floatingPanel-default-text text-xs ms-2 sb-break-words line-clamp-2"
          htmlFor={id}
        >
          {label}
        </label>
      )}
    </div>
  )
}

export default Checkbox
