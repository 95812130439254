import React, { FC } from 'react'
import { IBulletListProps } from './types'

const BulletList: FC<IBulletListProps> = ({ list, className = 'text-sm' }) => {
  return (
    <ul className={className}>
      {list.map(({ icon: Icon, iconBg, text }, i) => (
        <li key={i} className="flex items-center mb-2">
          <Icon className="me-2 w-5 h-5" fill={iconBg} />
          <span className="flex-1 text-floatingPanel-default-text">{text}</span>
        </li>
      ))}
    </ul>
  )
}

export default BulletList
