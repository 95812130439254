import React, { FC } from 'react'
import {
  PercentageBarProps,
  PercentageBarVariant,
  PercentageBarBackgroundVariant,
} from './types'

const PercentageBar: FC<PercentageBarProps> = ({
  percentage,
  textColor = 'text-answertile-percentage-text',
  barColor,
  bgColor = 'bg-answertile-percentage-bg',
  displayBackground = true,
  variant = PercentageBarVariant.WIDE,
  barBackgroundVariant = PercentageBarBackgroundVariant.LIGHT,
  className = '',
}) => {
  const percentageString = `${Math.round(percentage)}%`

  const render = () => {
    switch (variant) {
      case PercentageBarVariant.TALL: {
        const tallBarStyles = {
          height: percentageString,
        }

        const barVariantColor = 'before:bg-shades-black/[.36]'

        const bgStyles = `${bgColor} before:absolute before:inset-0 ${barVariantColor}`

        // -1px on positioning to fix sub pixel rendering issues
        return (
          <div
            className={`absolute -top-px -bottom-px right-0 w-8.5 overflow-hidden flex items-end flex-shrink-0 shadow-horizontalAnswerTilePercentageBorder ${className} ${bgStyles}`}
            data-testid="percentage-bar"
          >
            <div
              className={`${barColor} absolute left-0 right-0 bottom-0`}
              style={tallBarStyles}
              data-testid="percentage-bar__bar"
            />
            <span
              className={`text-xs relative z-10 block ${textColor} font-bold mb-1 text-center w-full`}
            >
              {percentageString}
            </span>
          </div>
        )
      }

      case PercentageBarVariant.WIDE:
      default:
        const wideBarStyles = {
          width: percentageString,
        }

        const barVariantColor = 'before:bg-shades-black/[.36]'

        const bgStyles = `${bgColor} before:absolute before:inset-0 ${barVariantColor}`

        return (
          <div
            className={`relative h-4.5 overflow-hidden flex ${bgStyles} ${className}`}
            data-testid="percentage-bar"
          >
            <div
              className={`absolute inset-0 ${barColor}`}
              data-testid="percentage-bar__bar"
              style={wideBarStyles}
            />
            <p
              className={`${textColor} text-xs text-center relative z-10 px-1 block font-bold w-full`}
            >
              {percentageString}
            </p>
          </div>
        )
    }
  }

  return render()
}

export default PercentageBar
