import React, { FC, useContext } from 'react'
import { useNavigate, useOutlet } from 'react-router-dom'
import { LayoutGroup } from 'framer-motion'
import Tabs from '@components/molecules/Tabs'
import LeaderboardList from '@components/organisms/LeaderboardList'
import {
  LeaderboardContext,
  LeaderboardActionTypes,
} from '@services/providers/LeaderboardProvider'
import { UserContext } from '@services/providers/UserProvider'
import LeaderboardTab from '@components/atoms/LeaderboardTab'
import { ConfigContext } from '@services/providers/ConfigProvider'
import { StreamContext } from '@services/providers/StreamProvider'
import AnimatedView from '@components/atoms/AnimatedView'
import { RoutePaths } from '@interfaces/navigation'
import Carousel from '@components/molecules/Carousel'
import { SmallSignupBanner } from '@components/atoms/SignupBanner'
import { useTranslation } from 'react-i18next'
import { useSignupText } from '@utils/hooks/useSignupTexts'
import { useSDKFeatures } from '@utils/hooks/useSDKFeatures'
import { ILeaderboardProps } from './types'
import './Leaderboard.styles.css'
import getGradientContainerStyles from '@utils/getContainerStyles'

const LeaderBoard: FC<ILeaderboardProps> = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const outlet = useOutlet()
  const { widgetConfig } = useContext(ConfigContext)
  const { stream } = useContext(StreamContext)
  const { profile, userLanguage, token, userId, isLinkedTwitchUser } =
    useContext(UserContext)
  const { getRedirectPath } = useSDKFeatures()

  const { text: signUpText, actionText: signUpActionText } = useSignupText({
    placement: 'leaderboardBanner',
  })

  const isTwitch =
    widgetConfig.player === 'twitch-mobile' ||
    widgetConfig.player === 'twitch-web'

  const isUserAuthenticated = isTwitch ? isLinkedTwitchUser : Boolean(token)

  const { leaderboards, dispatch, activeLeaderboardId } =
    useContext(LeaderboardContext)

  const activeLeaderboardIndex = leaderboards?.findIndex(
    (leaderboard) => leaderboard.id === activeLeaderboardId
  )

  const setActiveTab = (index: number) => {
    const id = leaderboards?.[index].id
    dispatch({
      type: LeaderboardActionTypes.ACTIVE_LEADERBOARD,
      payload: { leaderboardId: id },
    })
  }

  const onGoBack = () => navigate(RoutePaths.LEADERBOARDS)

  const handleSignup = () => {
    navigate(RoutePaths.AUTH, {
      state: {
        onGoBack: onGoBack,
      },
    })
  }

  const handleLeaderboardItemClick = (entityId: string) => {
    if (!entityId) return

    if (profile?.id === entityId) {
      navigate(getRedirectPath(RoutePaths.PROFILE, 'profile'), {
        state: {
          prevPath: RoutePaths.LEADERBOARDS,
        },
      })
      return
    }

    navigate(
      `${RoutePaths.LEADERBOARDS_EXTENDED}/${activeLeaderboardId}/${entityId}`,
      { state: { streamId: stream?.id } }
    )
  }

  const checkIfLeaderboardIsVip = (leaderboardId: string) => {
    const leaderboard = leaderboards?.find(
      (leaderboard) => leaderboard.id === leaderboardId
    )
    const paymentOption = !!leaderboard?.paymentOptions?.length
      ? leaderboard?.paymentOptions[leaderboard?.paymentOptions.length - 1]
      : undefined

    const dateNowMS = new Date().getTime()
    const availableUntilMS = paymentOption?.availableUntil
      ? new Date(paymentOption?.availableUntil).getTime()
      : 0
    const isExpired = dateNowMS > availableUntilMS

    return !!paymentOption && !!leaderboard?.paymentRequired && !isExpired
  }

  const isActiveLeaderboardVip = checkIfLeaderboardIsVip(activeLeaderboardId)

  const containerStyles = getGradientContainerStyles(
    'var(--color-floatingPanel-vip-default-bg-startColor)',
    'var(--color-floatingPanel-vip-default-bg-endColor)',
    'var(--color-floatingPanel-vip-default-bg-rotation)'
  )

  const bgClasses = isActiveLeaderboardVip ? 'bg-gradient-to-e' : ''

  if (!leaderboards) return null

  return (
    <div style={containerStyles}>
      <LayoutGroup>
        <AnimatedView
          viewPath={RoutePaths.LEADERBOARDS}
          data-testid="leaderboard"
          className={bgClasses}
        >
          <Tabs
            className="h-full flex flex-col"
            activeTab={activeLeaderboardIndex}
            onTabChange={setActiveTab}
          >
            {({ selectedTab }: { selectedTab: number }) => (
              <>
                <div
                  className={`flex relative items-center justify-center self-center w-11/12 my-4`}
                >
                  <Carousel
                    className="tabs-fade rounded-md"
                    showLeftArrow
                    showRightArrow
                  >
                    {({ addChildRef, scrollIntoItem }) => {
                      return leaderboards.map((leaderboard, i) => {
                        const isActive = selectedTab === i

                        const isVip = checkIfLeaderboardIsVip(leaderboard?.id)

                        const bgClasses = isVip
                          ? 'bg-leaderboard-vip-default-bg'
                          : 'bg-leaderboard-standard-default-bg'

                        return (
                          <Tabs.Tab
                            data-index={i}
                            ref={addChildRef(i)}
                            onClick={() => {
                              setActiveTab(i)
                              scrollIntoItem(i)
                            }}
                            key={i}
                            className={`flex items-center z-20 relative px-4 max-w-tab cursor-pointer flex-shrink-0 rounded-md me-2 last:me-0 h-9 ${bgClasses}`}
                          >
                            <LeaderboardTab
                              isVip={isVip}
                              leaderboardId={leaderboard.id}
                              activeLanguage={userLanguage}
                              isActive={isActive}
                            />
                          </Tabs.Tab>
                        )
                      })
                    }}
                  </Carousel>
                </div>

                {leaderboards.map((leaderboard, i) => {
                  return (
                    <Tabs.Panel
                      key={leaderboard.id}
                      className="flex-grow flex flex-col"
                    >
                      <div
                        data-testid={`leaderboard__tab${i}`}
                        className={`relative overflow-auto hide-scrollbar h-full`}
                      >
                        <LeaderboardList
                          leaderboardId={leaderboard.id}
                          userId={userId}
                          className="absolute inset-0 flex flex-col"
                          userLanguage={userLanguage}
                          onLeaderboardItemClick={handleLeaderboardItemClick}
                          footer={
                            !isUserAuthenticated && (
                              <SmallSignupBanner
                                className="sticky bottom-0 z-20 mt-auto"
                                onClick={handleSignup}
                                text={signUpText}
                                buttonText={signUpActionText}
                              />
                            )
                          }
                        />
                      </div>
                    </Tabs.Panel>
                  )
                })}
              </>
            )}
          </Tabs>
          {outlet}
        </AnimatedView>
      </LayoutGroup>
    </div>
  )
}

export default LeaderBoard
