import React, { FC } from 'react'
import { useTranslation, Trans } from 'react-i18next'
import { getNumberWithOrdinal } from '@utils/getNumberWithOrdinal'
import { formatPercentage } from '@utils/leaderboard'
import { RanksProps } from './types'

const Ranks: FC<RanksProps> = ({
  leaderboardName,
  userRank,
  userPoints,
  userPercentage,
}) => {
  const { t } = useTranslation()

  const userRankText = userRank ? (
    getNumberWithOrdinal(userRank)
  ) : userPercentage ? (
    <Trans
      i18nKey="leaderboard.percentageText"
      values={{ percentage: formatPercentage(userPercentage) }}
      components={[<span key={0}>$1</span>]}
    />
  ) : undefined

  return (
    <div className="text-floatingPanel-default-text flex items-center">
      <p className="me-2">
        <strong>{leaderboardName ?? '-'}:</strong> {userRankText}
      </p>
      <p>
        <strong>{t('leaderboard.extendedViewPoints')}:</strong> {userPoints}
      </p>
    </div>
  )
}

export default Ranks
