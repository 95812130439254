import { useQuery } from 'react-query'
import { getStreamLeaderboards } from '@services/requests/leaderboard-v2'
import { LEADERBOARD_META_STALE_TIME } from '@utils/reactQuery'

export const useGetStreamLeaderboardsById = (streamId?: string) => {
  return useQuery(
    ['stream-leaderboards', streamId],
    () => {
      return getStreamLeaderboards(streamId)
    },
    { staleTime: LEADERBOARD_META_STALE_TIME, enabled: Boolean(streamId) }
  )
}
