import React, { useState } from 'react'
import { useNavigate, Outlet } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import Modal from '@components/molecules/Modal'
import { RoutePaths } from '@interfaces/navigation'
import ProfilePhoto from '@components/atoms/ProfilePhoto'
import EditInput, { nameSchema } from '@components/atoms/EditInput'
import Button, { ButtonBackground } from '@components/atoms/Button'
import { useUpdateUser } from '@utils/hooks/useUpdateUser'
import { useSDKFeatures } from '@utils/hooks/useSDKFeatures'

const Welcome = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const {
    profilePhoto,
    isPhotoChangeEnabled,
    handleNameChangeRequest,
    name,
    errorName,
    loadingName,
    setName,
  } = useUpdateUser()
  const { getRedirectPath } = useSDKFeatures()

  const [isEditing, setIsEditing] = useState(false)

  const handleFinish = () => {
    navigate(getRedirectPath(RoutePaths.LEADERBOARDS, 'leaderboard'))
  }

  const handleProfilePictureClick = () => {
    navigate(RoutePaths.WELCOME_ASSETS)
  }

  const nameValidationSchema = nameSchema(t)

  return (
    <Modal
      centerTitle
      titleClassName="normal-case"
      title={t('auth.welcomeTitle')}
      data-testid="welcome"
      routerChildren={<Outlet />}
    >
      <div className="flex flex-col flex-1 items-center">
        <div className="flex flex-col flex-1 w-4/5 items-center">
          <p className="mb-2 text-center text-xs">
            {t('auth.chooseNameAndPicture')}
          </p>

          <ProfilePhoto
            image={profilePhoto}
            disabled={!isPhotoChangeEnabled}
            onClick={handleProfilePictureClick}
          />

          <EditInput
            label={t('general.name')}
            schema={nameValidationSchema}
            error={errorName}
            loading={loadingName}
            className="mt-1"
            value={name}
            onChange={setName}
            onSave={handleNameChangeRequest}
            onIsEditing={setIsEditing}
          />

          <Button
            disabled={loadingName || isEditing}
            onClick={handleFinish}
            data-testid="welcome__submit-button"
            keepBgOnDisabled
            background={ButtonBackground.PRIMARY}
            className="mt-auto mb-2 w-full"
          >
            <span className="font-semibold text-sm">{t('general.finish')}</span>
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default Welcome
