import React, { useContext, useState, MouseEvent } from 'react'
import { ReactComponent as DismissIcon } from '@assets/icons/dismiss.svg'
import { UserContext } from '@services/providers/UserProvider'
import { useFloatingTabsContext } from '@services/providers/FloatingTabsProvider'
import { useSignupText } from '@utils/hooks/useSignupTexts'
import { useTranslation } from 'react-i18next'
import { LiveReactionAsset } from '@interfaces/reactions'
import getGradientContainerStyles from '@utils/getContainerStyles'
import { RoutePaths } from '@interfaces/navigation'
import { useNavigate } from 'react-router-dom'
import { sendEvent } from '@utils/metrics'
import { METRIC_EVENTS } from '@interfaces/metrics'
import { REACTION_CLICK_ANIMATION_DURATION_MS } from '../../../constants'
import { ReactionsPanelProps } from './types'

const ReactionsPanel = ({
  onClose,
  onSendReaction,
  reactions,
}: ReactionsPanelProps) => {
  const { token } = useContext(UserContext)
  const { isOpen, setOpen } = useFloatingTabsContext()

  const [selectedId, setSelectedId] = useState<string | null>(null)
  const { t } = useTranslation()
  const navigate = useNavigate()

  const { text: signUpText } = useSignupText({
    placement: 'reactionsTab',
  })

  const containerStyles = getGradientContainerStyles(
    'var(--color-reactionPanel-default-bg-startColor)',
    'var(--color-reactionPanel-default-bg-endColor)',
    'var(--color-reactionPanel-default-bg-rotation)'
  )

  const title = !!token ? t('reactions.sendReaction') : signUpText

  const handleSignUp = () => {
    if (!!token) return

    if (!isOpen) {
      setOpen(true)
    }

    navigate(RoutePaths.SETTINGS)
  }

  const handleClickAnimation = (
    imageUrl: string,
    parentNode: HTMLElement | null
  ) => {
    const node = document.createElement('img')
    node.src = imageUrl
    node.className = 'absolute w-6 h-6 object-cover'
    node.style.animation = `click-n-fly ${REACTION_CLICK_ANIMATION_DURATION_MS}ms linear`
    parentNode?.appendChild(node)

    node.addEventListener('animationend', () => parentNode?.removeChild(node))
  }

  const handleClick = (
    reaction: LiveReactionAsset,
    e: MouseEvent<HTMLImageElement>
  ) => {
    sendEvent(METRIC_EVENTS.reactionsTriggered, { reactionId: reaction.id })
    const reactionNode = e.currentTarget.parentNode as HTMLElement
    handleClickAnimation(reaction.imageUrl, reactionNode)
    setSelectedId(reaction.id)
    onSendReaction(reaction)
    setTimeout(() => {
      setSelectedId(null)
    }, REACTION_CLICK_ANIMATION_DURATION_MS)
  }

  return (
    <div style={containerStyles}>
      <div className="p-3 min-h-[calc(var(--spacing)*15)] max-w-[calc(var(--spacing)*85)] min-w-[calc(var(--spacing)*50)] rounded-lg flex flex-col items-start bg-gradient-to-r from-reactionPanel-default-bg-startColor to-reactionPanel-default-bg-endColor text-reactionPanel-default-text ml-2">
        <div className="flex items-center justify-between w-full gap-x-2">
          <span
            onClick={handleSignUp}
            className={`text-xs ${!token && 'cursor-pointer'}`}
          >
            {title}
          </span>
          <DismissIcon
            className="flex-shrink-0 cursor-pointer w-4"
            onClick={onClose}
            fill="var(--color-reactionPanel-default-text)"
          />
        </div>
        <div className="flex flex-wrap items-center justify-center w-full gap-2 mt-2">
          {reactions.map((reaction) => {
            return (
              <div
                id={reaction.id}
                key={reaction.id}
                className="relative w-6 h-6 flex items-center justify-center"
              >
                <img
                  src={reaction.imageUrl}
                  className={`panel-reaction w-6 h-6 object-cover ${
                    !!token && 'cursor-pointer'
                  }`}
                  style={{
                    opacity: !!selectedId || !token ? '0.5' : '1',
                    borderRadius:
                      !!selectedId && selectedId === reaction.id
                        ? '50%'
                        : '100%',
                    animation:
                      !!selectedId && selectedId === reaction.id
                        ? `pulse ${
                            REACTION_CLICK_ANIMATION_DURATION_MS / 2
                          }ms ease-out`
                        : '',
                  }}
                  onClick={(e) =>
                    !selectedId && !!token && handleClick(reaction, e)
                  }
                />
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default ReactionsPanel
