import React, { FC, useEffect, useState, useCallback, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import Range from '@components/atoms/Range'
import { ConfigContext } from '@services/providers/ConfigProvider'
import { IEmojiSliderProps } from './types'
import { Engagement, VoteableAnswer } from '@interfaces/voteable'
import { PubSubEventType } from '@interfaces/pubSub'
import InfoBubble from '@components/atoms/InfoBubble'
import InfoPill from '@components/atoms/InfoPill'
import { AnimatePresence, motion } from 'framer-motion'

const EmojiSlider: FC<IEmojiSliderProps> = ({
  buff,
  pubSubEventType,
  activeLanguage,
  engagement,
  votedAnswerId,
  setSelectedAnswerId,
}) => {
  const { t } = useTranslation()
  const { widgetConfig } = useContext(ConfigContext)
  const isTwitchMobile = widgetConfig?.player === 'twitch-mobile'

  const [localSelectedAnswerId, setLocalSelectedAnswerId] = useState<
    string | null
  >(null)
  const { answers } = buff
  const [image, setImage] = useState('')
  const [showMostVoted, setShowMostVoted] = useState(true)
  const [thumbScale, setThumbScale] = useState(1)
  const [isTouched, setIsTouched] = useState(false)
  const [labelText, setLabelText] = useState('')
  const [value, setValue] = useState<number>(1)
  const [selectedAnswer, setSelectedAnswer] = useState<VoteableAnswer>()

  const isPublishedBuff =
    pubSubEventType === PubSubEventType.VOTEABLE_OPEN ||
    pubSubEventType === PubSubEventType.welcomeBuff

  const calculateMostVotedAnswer = useCallback((engagement?: Engagement) => {
    return Object.values(engagement?.perAnswer ?? {})
      .reverse()
      .sort((a, b) => (b.votesCounted ?? 0) - (a.votesCounted ?? 0))?.[0]
  }, [])

  const calcInitialPositionIndex = useCallback(
    (answers: VoteableAnswer[], answerId?: string) => {
      if (!answerId) return Math.ceil(answers?.length / 2)
      const index = answers?.findIndex((ans) => ans.id === answerId)
      return index + 1
    },
    []
  )

  useEffect(() => {
    if (!answers || answers?.length < 2) return
    const mostVotedAnswer = isPublishedBuff
      ? { answerId: votedAnswerId }
      : calculateMostVotedAnswer(engagement)
    const index = calcInitialPositionIndex(answers, mostVotedAnswer?.answerId)
    if (!index) return
    setSelectedAnswer(answers[index - 1])
    const item = answers[index - 1]
    const image = item?.localisations?.[activeLanguage]?.imageUrl
    const text = item?.localisations?.[activeLanguage]?.text ?? ''
    const url = image ? `url(${image})` : ''
    setImage(url)
    setLabelText(text)
    setValue(index)
  }, [
    answers,
    engagement,
    isPublishedBuff,
    calcInitialPositionIndex,
    calculateMostVotedAnswer,
    activeLanguage,
    votedAnswerId,
  ])

  useEffect(() => {
    if (!isPublishedBuff) {
      const tutorialTimer = setTimeout(() => {
        setShowMostVoted(false)
      }, 3000)

      return () => {
        clearTimeout(tutorialTimer)
      }
    }
  }, [pubSubEventType, setShowMostVoted, isPublishedBuff])

  useEffect(() => {
    if (!isPublishedBuff) {
      const scaleTimer = setTimeout(() => {
        setThumbScale(1.4)
      }, 1000)

      return () => {
        clearTimeout(scaleTimer)
      }
    }
  }, [pubSubEventType, setThumbScale, isPublishedBuff])

  const showAnswerDetails =
    (isPublishedBuff && (isTouched || !!votedAnswerId)) ||
    (!isPublishedBuff && !showMostVoted)

  const answerContent = selectedAnswer?.localisations?.[activeLanguage]
  const textStyles =
    showAnswerDetails && answerContent?.textColor
      ? { color: answerContent?.textColor }
      : undefined
  const backgroundStyles =
    showAnswerDetails && answerContent?.backgroundColor
      ? { background: answerContent?.backgroundColor }
      : undefined

  const thumbSize = isTwitchMobile
    ? 'calc(var(--spacing) * 12.5)'
    : 'calc(var(--spacing) * 9.5)'
  const trackSize = 'calc(var(--spacing) * 3)'

  const renderFooter = () => {
    const commonProps = {
      bg: 'bg-slider-pill-bg',
      className: 'text-xs text-slider-pill-text !rounded-full',
      'data-testid': 'EmojiSlider__InfoBubble',
      style: {
        ...textStyles,
        ...backgroundStyles,
      },
    }

    if (isPublishedBuff) {
      const text =
        isTouched || !!votedAnswerId ? labelText : t('emojiSlider.slideToPlay')
      return <InfoBubble {...commonProps} text={text} />
    }

    const text = !showMostVoted ? labelText : t('emojiSlider.mostCommon')
    if (!selectedAnswer) return null
    const percentage =
      engagement?.perAnswer?.[selectedAnswer?.id]?.percentage ?? 0

    const textTransition = {
      x: { type: 'spring', stiffness: 300, damping: 30 },
    }

    const maxAnimPosition = 1000
    const variants = {
      initial: (initial: boolean) => {
        return {
          x: initial ? 0 : maxAnimPosition,
        }
      },
      animate: (initial: boolean) => {
        return {
          x: initial ? maxAnimPosition * -1 : 0,
        }
      },
      exit: (initial: number) => {
        return {
          x: initial ? maxAnimPosition * -1 : 0,
        }
      },
    }
    if (text) {
      return (
        <InfoBubble {...commonProps} text="">
          <AnimatePresence initial={false} custom={showMostVoted}>
            {showMostVoted ? (
              <motion.span
                custom={showMostVoted}
                variants={variants}
                initial="initial"
                animate="animate"
                transition={textTransition}
              >
                {text}
              </motion.span>
            ) : (
              <motion.p
                custom={showMostVoted}
                variants={variants}
                initial="initial"
                animate="animate"
                transition={textTransition}
                className="inline-flex"
              >
                <span className="leading-normal">{text}</span>
                <InfoPill
                  deg="var(--color-slider-default-bg-rotation)"
                  bgColor="var(--color-slider-default-bg-startColor)"
                  shadeColor="var(--color-slider-default-bg-endColor)"
                  textColor="var(--color-slider-pillPercentage-text)"
                  className="ms-1 !px-1 !py-0 -me-0.5 !rounded-full inline"
                  text={`${Math.round(percentage)}%`}
                />
              </motion.p>
            )}
          </AnimatePresence>
        </InfoBubble>
      )
    }

    return (
      <InfoPill
        deg="90deg"
        bgColor="#173ead"
        shadeColor="#6183e4"
        textColor="white"
        text={`${Math.round(percentage)}%`}
        className="!rounded-full"
      />
    )
  }

  const showImage =
    !isPublishedBuff || (isPublishedBuff && (isTouched || !!votedAnswerId))

  if (!answers || answers?.length < 2) return null

  return (
    <div data-testid="emoji" className="relative">
      <div className="w-full">
        <Range
          id="emoji-slider"
          min={1}
          value={value}
          image={showImage ? image : undefined}
          label={labelText}
          max={answers?.length}
          step={1}
          thumbSize={thumbSize}
          trackSize={trackSize}
          thumbScale={thumbScale}
          onChange={(e) => {
            const val = Number(e.target.value)
            setValue(val)
            const answer = answers[val - 1]
            setSelectedAnswer(answer)
            setLocalSelectedAnswerId(answer?.id)
            const image = answer?.localisations?.[activeLanguage]?.imageUrl
            const url = image ? `url(${image})` : ''
            setImage(url)
            setLabelText(answer?.localisations?.[activeLanguage]?.text ?? '')
          }}
          onClick={() => {
            localSelectedAnswerId &&
              setSelectedAnswerId &&
              setSelectedAnswerId(localSelectedAnswerId)
          }}
          onMouseDown={() => setIsTouched(true)}
          disabled={!isPublishedBuff || !!votedAnswerId}
        />
      </div>
      <div className="flex items-center justify-center font-secondary">
        {renderFooter()}
      </div>
    </div>
  )
}

export default EmojiSlider
