import React, { FC } from 'react'
import Skeleton from '@components/atoms/Skeleton'
import { LeaderboardSponsorProps } from './types'

const LeaderboardSponsor: FC<LeaderboardSponsorProps> = ({ banner }) => {
  const image = banner?.imageUrl ? (
    <img
      src={banner?.imageUrl}
      alt={banner?.imageAltText}
      className="absolute inset-0 object-cover w-full h-full"
      data-testid="leaderboard-banner__image"
    />
  ) : null

  const children = (
    <>
      <Skeleton
        className="absolute inset-0"
        data-testid="leaderboard-banner__skeleton"
      />
      {image}
    </>
  )

  return (
    <li
      className="relative before:block before:pb-[33.3333%]"
      data-testid="leaderboard-banner"
    >
      <div className="absolute inset-0">
        {banner?.linkTarget ? (
          <a
            href={banner.linkTarget}
            target="_blank"
            rel="noreferrer"
            data-testid="leaderboard-banner__link"
          >
            {children}
          </a>
        ) : (
          children
        )}
      </div>
    </li>
  )
}

export default LeaderboardSponsor
