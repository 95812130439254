import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import Button, {
  ButtonVariant,
  ButtonBackground,
} from '@components/atoms/Button'
import Modal from '@components/molecules/Modal'
import { ReactComponent as ErrorIcon } from '@assets/icons/error.svg'
import { useSearchQuery } from '@utils/hooks/useSearchQuery'
import { ErrorProps } from './types'

const Error = (props: ErrorProps) => {
  const { t } = useTranslation()
  const params = useSearchQuery()
  const navigate = useNavigate()

  const title = params.get('title') ?? ''
  const descriptionLine1 = params.get('descriptionLine1') ?? ''
  const descriptionLine2 = params.get('descriptionLine2') ?? ''

  const iconStyles: any = {
    '--fill-color-1': 'var(--color-floatingPanel-semantic-error)',
    '--fill-color-2': 'var(--color-shades-white)',
  }

  const handleClose = () => {
    // There is an issue on react router v6, this logic below does not work, so we are using navigate(-1) instead
    // const lastEntry = getPreviousRoute()
    // if (lastEntry && !lastEntry.includes('error')) {
    //   navigate(lastEntry)
    // }
    navigate(-1)
  }

  return (
    <Modal onGoBack={handleClose} title={t(title)}>
      <div className="flex justify-center items-center flex-col px-7 w-full text-floatingPanel-default-text">
        <ErrorIcon style={iconStyles} className="mb-4 w-17" />
        <p className="mb-2 text-center text-sm font-bold">
          {t(descriptionLine1)}
        </p>
        <p className="mb-2 text-center text-xs">{t(descriptionLine2)}</p>
        <Button
          data-testid="confirm-button"
          className="my-2"
          background={ButtonBackground.PRIMARY}
          variant={ButtonVariant.FULL_WIDTH}
          onClick={handleClose}
        >
          {t('general.okay')}
        </Button>
      </div>
    </Modal>
  )
}

export default Error
