import { MappedTheme, ITheme } from '@interfaces/theme'

export const extend = (extending: ITheme, newTheme: ITheme): ITheme => {
  return { ...extending, ...newTheme }
}

export const mapTheme = (variables: ITheme): MappedTheme => {
  const themeObj: MappedTheme = {
    '--color-answertile-default-bg': variables.answertile.default.bg,
    '--color-answertile-default-text': variables.answertile.default.text,
    '--color-answertile-body-bg': variables.answertile.body.bg,
    '--color-answertile-body-text': variables.answertile.body.text,
    '--color-answertile-selected-bg': variables.answertile.selected.bg,
    '--color-answertile-selected-text': variables.answertile.selected.text,
    '--color-answertile-selected-percentageBg':
      variables.answertile.selected.percentageBg,
    '--color-answertile-success-bg': variables.answertile.success.bg,
    '--color-answertile-success-text': variables.answertile.success.text,
    '--color-answertile-success-percentageBg':
      variables.answertile.success.percentageBg,
    '--color-answertile-wrong-bg': variables.answertile.wrong.bg,
    '--color-answertile-wrong-text': variables.answertile.wrong.text,
    '--color-answertile-wrong-percentageBg':
      variables.answertile.wrong.percentageBg,
    '--color-answertile-winner-bg': variables.answertile.winner.bg,
    '--color-answertile-winner-text': variables.answertile.winner.text,
    '--color-answertile-winner-percentageBg':
      variables.answertile.winner.percentageBg,
    '--color-answertile-imageplaceholder-bg':
      variables.answertile.imageplaceholder.bg,
    '--color-answertile-bodysupporting-bg':
      variables.answertile.bodysupporting.bg,
    '--color-answertile-percentage-bg': variables.answertile.percentage.bg,
    '--color-answertile-percentage-text': variables.answertile.percentage.text,
    '--color-answertile-percentage-percentageBg':
      variables.answertile.percentage.bar ||
      variables.answertile.percentage.percentageBg,

    '--color-buff-default-bg-startColor': variables.buff.default.bg.startColor,
    '--color-buff-default-bg-endColor': variables.buff.default.bg.endColor,
    '--color-buff-default-bg-rotation': variables.buff.default.bg.rotation,
    '--color-buff-default-text': variables.buff.default.text,
    '--color-buff-inverse-bg': variables.buff.inverse.bg,

    '--color-button-primary-bg': variables.button.primary.bg,
    '--color-button-primary-text': variables.button.primary.text,
    '--color-button-secondary-bg': variables.button.secondary.bg,
    '--color-button-secondary-text': variables.button.secondary.text,
    '--color-button-tertiary-bg': variables.button.tertiary.bg,
    '--color-button-tertiary-text': variables.button.tertiary.text,
    '--color-button-cta-bg': variables.button.cta.bg,
    '--color-button-cta-text': variables.button.cta.text,
    '--color-button-expand-bg': variables.button.expand.bg,
    '--color-button-expand-text': variables.button.expand.text,
    '--color-button-danger-bg': variables.button.danger.bg,
    '--color-button-danger-text': variables.button.danger.text,
    '--color-button-announcement-bg': variables.button.announcement.bg,
    '--color-button-announcement-text': variables.button.announcement.text,
    '--color-button-share-bg': variables.button.share.bg,
    '--color-button-share-text': variables.button.share.text,
    '--color-button-tabnavigation-bg': variables.button.tabnavigation.bg,
    '--color-button-tabnavigation-text': variables.button.tabnavigation.text,
    '--color-button-reaction-bg': variables.button.reaction.bg,
    '--color-button-reaction-text': variables.button.reaction.text,

    '--color-infopill-default-bg-startColor':
      variables.infopill.default.bg.startColor,
    '--color-infopill-default-bg-endColor':
      variables.infopill.default.bg.endColor,
    '--color-infopill-default-text': variables.infopill.default.text,
    '--color-infopill-action-text': variables.infopill.action.text,
    '--color-infopill-semantic-error': variables.infopill.semantic.error,
    '--color-infopill-semantic-success': variables.infopill.semantic.success,

    '--color-floatingPanel-default-bg-startColor':
      variables.floatingPanel.default.bg.startColor,
    '--color-floatingPanel-default-bg-endColor':
      variables.floatingPanel.default.bg.endColor,
    '--color-floatingPanel-default-bg-rotation':
      variables.floatingPanel.default.bg.rotation,
    '--color-floatingPanel-default-text': variables.floatingPanel.default.text,
    '--color-floatingPanel-vip-default-bg-startColor':
      variables.floatingPanel.vip.default.bg.startColor,
    '--color-floatingPanel-vip-default-bg-endColor':
      variables.floatingPanel.vip.default.bg.endColor,
    '--color-floatingPanel-vip-default-bg-rotation':
      variables.floatingPanel.vip.default.bg.rotation,
    '--color-floatingPanel-vip-default-text':
      variables.floatingPanel.vip.default.text,
    '--color-floatingPanel-vip-accent-bg':
      variables.floatingPanel.vip.accent.bg,
    '--color-floatingPanel-vip-accent-text':
      variables.floatingPanel.vip.accent.text,
    '--color-floatingPanel-accent-default-bg':
      variables.floatingPanel.accent.default.bg,
    '--color-floatingPanel-accent-default-text':
      variables.floatingPanel.accent.default.text,
    '--color-floatingPanel-accent-warning-bg':
      variables.floatingPanel.accent.warning.bg,
    '--color-floatingPanel-accent-warning-text':
      variables.floatingPanel.accent.warning.text,
    '--color-floatingPanel-accent-muted-bg':
      variables.floatingPanel.accent.muted.bg,
    '--color-floatingPanel-accent-muted-text':
      variables.floatingPanel.accent.muted.text,
    '--color-floatingPanel-banner-bg': variables.floatingPanel.banner.bg,
    '--color-floatingPanel-banner-text': variables.floatingPanel.banner.text,
    '--color-floatingPanel-friends-bg': variables.floatingPanel.friends.bg,
    '--color-floatingPanel-friends-text': variables.floatingPanel.friends.text,
    '--color-floatingPanel-profile-bg': variables.floatingPanel.profile.bg,
    '--color-floatingPanel-actionSheet-bg':
      variables.floatingPanel.actionSheet.bg,
    '--color-floatingPanel-toggleSwitch-bg':
      variables.floatingPanel.toggleSwitch.bg,
    '--color-floatingPanel-navigationBar-bg':
      variables.floatingPanel.navigationBar.bg,
    '--color-floatingPanel-icons-bg': variables.floatingPanel.icons.bg,
    '--color-floatingPanel-border-bg': variables.floatingPanel.border.bg,
    '--color-floatingPanel-inverse-bg': variables.floatingPanel.inverse.bg,
    '--color-floatingPanel-transparentbackdrop-bg':
      variables.floatingPanel.transparentbackdrop.bg,
    '--color-floatingPanel-linkbutton-text':
      variables.floatingPanel.linkbutton.text,
    '--color-floatingPanel-muted-text': variables.floatingPanel.muted.text,
    '--color-floatingPanel-semantic-success':
      variables.floatingPanel.semantic.success,
    '--color-floatingPanel-semantic-error':
      variables.floatingPanel.semantic.error,

    '--color-leaderboard-standard-selected-bg':
      variables.leaderboard.standard.selected.bg,
    '--color-leaderboard-standard-default-bg':
      variables.leaderboard.standard.default.bg,
    '--color-leaderboard-vip-selected-bg':
      variables.leaderboard.vip.selected.bg,
    '--color-leaderboard-vip-default-bg': variables.leaderboard.vip.default.bg,
    '--color-leaderboard-standard-selected-text':
      variables.leaderboard.standard.selected.text,
    '--color-leaderboard-standard-default-text':
      variables.leaderboard.standard.default.text,
    '--color-leaderboard-vip-selected-text':
      variables.leaderboard.vip.selected.text,
    '--color-leaderboard-vip-default-text':
      variables.leaderboard.vip.default.text,

    '--color-navbar-selected-bg': variables.navbar.selected.bg,
    '--color-navbar-default-bg': variables.navbar.default.bg,
    '--color-navbar-selected-text': variables.navbar.selected.text,
    '--color-navbar-default-text': variables.navbar.default.text,

    '--color-shades-black': variables.shades.black,
    '--color-shades-white': variables.shades.white,

    '--color-pointsdeck-default-bg-startColor':
      variables.pointsdeck.default.bg.startColor,
    '--color-pointsdeck-default-bg-endColor':
      variables.pointsdeck.default.bg.endColor,
    '--color-pointsdeck-default-bg-rotation':
      variables.pointsdeck.default.bg.rotation,
    '--color-pointsdeck-default-text': variables.pointsdeck.default.text,

    '--color-reactionPanel-default-bg-startColor':
      variables.reactionPanel.default.bg.startColor,
    '--color-reactionPanel-default-bg-endColor':
      variables.reactionPanel.default.bg.endColor,
    '--color-reactionPanel-default-bg-rotation':
      variables.reactionPanel.default.bg.rotation,
    '--color-reactionPanel-default-text': variables.reactionPanel.default.text,
    '--color-reactionPanel-action-text': variables.reactionPanel.action.text,

    '--color-slider-default-bg-startColor':
      variables.slider.default.bg.startColor,
    '--color-slider-default-bg-endColor': variables.slider.default.bg.endColor,
    '--color-slider-default-bg-rotation': variables.slider.default.bg.rotation,
    '--color-slider-backdrop-bg': variables.slider.backdrop.bg,
    '--color-slider-pill-bg': variables.slider.pill.bg,
    '--color-slider-pill-text': variables.slider.pill.text,
    '--color-slider-pillPercentage-text': variables.slider.pillPercentage.text,

    '--color-star-default-bg-startColor': variables.star.default.bg.startColor,
    '--color-star-default-bg-endColor': variables.star.default.bg.endColor,
    '--color-star-default-bg-rotation': variables.star.default.bg.rotation,
    '--color-star-backdrop-bg': variables.star.backdrop.bg,

    '--color-table-columnheader-bg': variables.table.columnheader.bg,
    '--color-table-columnheader-text': variables.table.columnheader.text,
    '--color-table-rowheader-bg': variables.table.rowheader.bg,
    '--color-table-rowheader-text': variables.table.rowheader.text,

    '--color-timer-background': variables.timer.background,
    '--color-timer-start': variables.timer.start,
    '--color-timer-middle': variables.timer.middle,
    '--color-timer-end': variables.timer.end,

    '--font-font-base': variables['font-base'],
    '--font-font-secondary': variables['font-secondary'],
  }

  // Mutates themeObject to include rgb variants of colors
  Object.keys(themeObj).forEach((property) => {
    if (property === 'name') {
      return
    }

    const rgbColor = hexToRgb(themeObj[property])
    if (rgbColor) {
      const rgb = `${rgbColor.r}, ${rgbColor.g}, ${rgbColor.b}`
      themeObj[`${property}-rgb`] = rgb

      if (rgbColor.a) {
        themeObj[`${property}-rgb-alpha`] = String(rgbColor.a)
      }
    }
  })

  return themeObj
}

const hexToRgb = (
  hex: string | null
): { r: number; g: number; b: number; a?: number } | null => {
  if (!hex) return null

  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})?$/i.exec(
    hex
  )

  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
        ...(!!result?.[4] && { a: parseInt(result[4], 16) / 255 }),
      }
    : null
}
