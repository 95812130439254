import React, { FC, useContext, useMemo } from 'react'
import LeaderboardItem from '@components/molecules/LeaderboardItem'
import LeaderboardSponsor from '@components/molecules/LeaderboardSponsor'
import {
  LeaberboardBannerType,
  LeaderboardStanding,
  LeaderboardStandingMethod,
} from '@interfaces/leaderboard'
import { useGetLeaderboardStandings } from '@utils/hooks/useGetLeaderboardStandings'
import { useGetLeaderboardUserStanding } from '@utils/hooks/useGetLeaderboardUserStanding'
import { useGetLeaderboardMeta } from '@utils/hooks/useGetLeaderboardMeta'
import VipLeaderboardBanner, {
  UnsupportedVipLeaderboardBanner,
  UnavailableVipLeaderboardBanner,
} from '@components/atoms/VipLeaderboardBanner'
import { StreamContext } from '@services/providers/StreamProvider'
import { UserContext } from '@services/providers/UserProvider'
import { LeaderboardContext } from '@services/providers/LeaderboardProvider'
import { LeaderboardListProps } from './types'
import { ConfigContext } from '@services/providers/ConfigProvider'

export const MIN_LEADERBOARD_ITEM_COUNT = 15

export const getPreparedStreamList = <T extends LeaderboardStanding>(
  leaderboard: T[],
  userRanking?: T
): T[] => {
  const list = [...leaderboard]
  if (userRanking) {
    list.push(userRanking)
  }

  const ids: string[] = []
  const dedupedList = list.filter((item) => {
    if (ids.includes(item.userId)) return false

    ids.push(item.userId)
    return true
  })

  dedupedList.sort((rankingA, rankingB) => {
    if (rankingA.rank === undefined && rankingB.rank === undefined) {
      return 0
    }

    if (rankingA.rank === undefined) return 1
    if (rankingB.rank === undefined) return -1
    return rankingA.rank - rankingB.rank
  })

  const placeholders = !dedupedList?.length
    ? new Array(MIN_LEADERBOARD_ITEM_COUNT).fill(null)
    : []

  return dedupedList.concat(placeholders)
}

const LeaderboardList: FC<LeaderboardListProps> = ({
  groupId,
  leaderboardId,
  userId: userIdProps,
  isGroupLeaderboard = false,
  isGroupOwner = false,
  onLeaderboardItemClick,
  onLeaderboardItemDelete,
  userLanguage,
  className = '',
  footer,
}) => {
  const { stream } = useContext(StreamContext)
  const { isVipLeaderboard } = useContext(LeaderboardContext)
  const { widgetConfig } = useContext(ConfigContext)
  const { userId: ctxUserId, token } = useContext(UserContext)

  /**
   * isGroupLeaderboard should be a static prop, changing on fly will make react go boom
   */
  /* eslint-disable react-hooks/rules-of-hooks */
  const { data: leaderboardData } = isGroupLeaderboard
    ? useGetLeaderboardStandings(leaderboardId, stream?.id, groupId)
    : useGetLeaderboardStandings(leaderboardId, stream?.id)

  const { data: userStandingData } = useGetLeaderboardUserStanding(
    stream?.id,
    leaderboardId,
    userIdProps,
    groupId,
    true
  )

  const list = getPreparedStreamList(leaderboardData ?? [], userStandingData)

  const { data: leaderboardMeta } = useGetLeaderboardMeta(leaderboardId)

  const banner = leaderboardMeta?.content[userLanguage]?.banner

  const isTwitch =
    widgetConfig.player === 'twitch-web' ||
    widgetConfig.player === 'twitch-mobile'

  // Due to Dashboard restrictions the last element will always be the only active element
  const paymentOption = !!leaderboardMeta?.paymentOptions?.length
    ? leaderboardMeta?.paymentOptions[
        leaderboardMeta?.paymentOptions.length - 1
      ]
    : undefined

  const dateNowMS = new Date().getTime()
  const availableUntilMS = paymentOption?.availableUntil
    ? new Date(paymentOption?.availableUntil).getTime()
    : 0
  const isExpired = dateNowMS > availableUntilMS

  const showVipClasses =
    !isGroupLeaderboard &&
    !!paymentOption &&
    !!leaderboardMeta?.paymentRequired &&
    !isExpired &&
    isTwitch &&
    !!isVipLeaderboard

  const handleVipBannerClick = () => {
    paymentOption?.twitchSku &&
      window.Twitch?.ext.bits.useBits(paymentOption?.twitchSku)
    localStorage.setItem('ACTIVE_LEADERBOARD', leaderboardId)
  }

  const showSponsor = !!banner && !isGroupLeaderboard

  const paymentBanner = useMemo(() => {
    const commonRules =
      !isGroupLeaderboard &&
      !footer &&
      !!paymentOption &&
      !!leaderboardMeta?.paymentRequired &&
      (userStandingData?.method ===
        LeaderboardStandingMethod.REQUIRES_PAYMENT ||
        userStandingData?.method === LeaderboardStandingMethod.NOT_PRESENT)

    if (commonRules) {
      if (
        !isExpired &&
        (!!window.Twitch?.ext?.features?.isBitsEnabled || true) &&
        isTwitch &&
        !!isVipLeaderboard
      ) {
        return LeaberboardBannerType.BUY
      }

      if (!isExpired && !isTwitch) {
        return LeaberboardBannerType.UNSUPPORTED
      }

      if (isExpired) {
        return LeaberboardBannerType.UNAVAILABLE
      }
    }

    return undefined
  }, [
    paymentOption,
    isGroupLeaderboard,
    footer,
    leaderboardMeta?.paymentRequired,
    userStandingData?.method,
    isTwitch,
    isVipLeaderboard,
    isExpired,
  ]) as LeaberboardBannerType

  return (
    <ul className={`m-0 p-0 ${className}`}>
      {showSponsor && <LeaderboardSponsor banner={banner} />}
      {list &&
        list.map((item: LeaderboardStanding, i: number) => {
          if (!item) return <LeaderboardItem key={`skeleton-${i}`} />

          const {
            userId,
            profilePicture,
            displayName,
            position,
            points,
            percentile,
          } = item
          const photo = profilePicture ?? ''
          const name = displayName ?? userId

          const handleDelete =
            userId === userIdProps ? undefined : onLeaderboardItemDelete

          return (
            <LeaderboardItem
              key={userId ?? displayName}
              id={userId}
              points={points}
              image={photo}
              username={name}
              rank={position}
              percentage={percentile}
              isActive={userId === ctxUserId}
              isVip={showVipClasses}
              isGroupOwner={isGroupOwner}
              onClick={onLeaderboardItemClick}
              onDelete={handleDelete}
            />
          )
        })}

      {!!footer && footer}
      {paymentBanner === LeaberboardBannerType.BUY && (
        <VipLeaderboardBanner
          amount={Number(paymentOption?.cost)}
          className="sticky bottom-0 z-20 mt-auto"
          onClick={handleVipBannerClick}
        />
      )}

      {paymentBanner === LeaberboardBannerType.UNSUPPORTED && (
        <UnsupportedVipLeaderboardBanner
          amount={Number(paymentOption?.cost)}
          className="sticky bottom-0 z-20 mt-auto"
        />
      )}

      {paymentBanner === LeaberboardBannerType.UNAVAILABLE && (
        <UnavailableVipLeaderboardBanner className="sticky bottom-0 z-20 mt-auto" />
      )}
    </ul>
  )
}

export default LeaderboardList
