import React, { useContext } from 'react'
import { useNavigate, useOutlet } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { TFunction } from 'i18next'
import Button, {
  ButtonBackground,
  ButtonVariant,
} from '@components/atoms/Button'
import BulletList from '@components/atoms/BulletList'
import ShareCode from '@components/molecules/ShareCode'
import { ReactComponent as CheckBulletIcon } from '@assets/icons/bullet-list-check.svg'
import { UserContext } from '@services/providers/UserProvider'
import { ConfigContext } from '@services/providers/ConfigProvider'
import { SignupBanner } from '@components/atoms/SignupBanner'
import { queryClient } from '@utils/reactQuery'
import { FriendGroup } from '@interfaces/friendGroups'
import { useUserGroups } from '@utils/hooks/useUserGroups'
import { useGetFriendGroup } from '@utils/hooks/useGetFriendGroup'
import {
  RoutePaths,
  RouteSubPaths,
  RouteMainPaths,
} from '@interfaces/navigation'
import { createGroup } from '@services/requests/friendGroups'
import { sendEvent } from '@utils/metrics'
import { METRIC_EVENTS } from '@interfaces/metrics'
import { logError } from '@utils/log'
import { useSignupText } from '@utils/hooks/useSignupTexts'
import { FriendsGroupWelcomeScreenProps } from './types'

const BULLET_COLOR = 'var(--color-floatingPanel-accent-default-bg)'

const getBulletList = (t: TFunction) => {
  return [
    {
      text: t('friends.bulletList1'),
      icon: CheckBulletIcon,
      iconBg: BULLET_COLOR,
    },
    {
      text: t('friends.bulletList2'),
      icon: CheckBulletIcon,
      iconBg: BULLET_COLOR,
    },
    {
      text: t('friends.bulletList3'),
      icon: CheckBulletIcon,
      iconBg: BULLET_COLOR,
    },
  ]
}

interface FriendsGroupCodeProps {
  group: FriendGroup
}

const FriendsGroupCode = ({ group }: FriendsGroupCodeProps) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const handleRotateCode = () => {
    navigate(
      `${RouteMainPaths.FRIENDS}/${RouteSubPaths.WELCOME}/${group.id}/${RouteSubPaths.ROTATE_CODE}`
    )
  }

  return (
    <ShareCode
      code={group?.code}
      showRefreshCodeButton={true}
      onRotateCode={handleRotateCode}
      subtext={t('friends.waitingFriendsToJoin')}
      isGroupLocked={group.locked}
    />
  )
}

const FriendsGroupWelcomeScreen = (props: FriendsGroupWelcomeScreenProps) => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { token, userId } = useContext(UserContext)
  const { widgetConfig } = useContext(ConfigContext)
  const outlet = useOutlet()

  const { text: signUpText, actionText: signUpActionText } = useSignupText({
    placement: 'friendsTab',
  })

  const isTwitch =
    widgetConfig.player === 'twitch-mobile' ||
    widgetConfig.player === 'twitch-web'

  const isUserAuthenticated = isTwitch
    ? Boolean(widgetConfig.twitchUserId)
    : Boolean(token)

  const contentClasses = isUserAuthenticated ? '' : 'blur-[6px]'

  const { data: friendGroups } = useUserGroups(userId)
  const firstGroupId = friendGroups?.[0]?.id

  const { data: group } = useGetFriendGroup(firstGroupId)

  const handleJoinGroup = () => {
    if (group === undefined) {
      navigate(RoutePaths.FRIENDS_JOIN)
      return
    }

    // Condition should only happen if user is group owner
    navigate(`/friends/welcome/${firstGroupId}/${RouteSubPaths.LEAVE}`)
  }

  const handleCreateGroup = async () => {
    try {
      const group = await createGroup()
      sendEvent(METRIC_EVENTS.privateGroupCreated, { id: group?.id })
      queryClient.setQueryData(['friends.info', group.id], () => {
        return group
      })
      queryClient.invalidateQueries(['user-groups', userId])
    } catch (error) {
      logError(error)
    }
  }

  const handleSignup = () => {
    navigate(RoutePaths.AUTH)
  }

  const bulletList = getBulletList(t)

  return (
    <div className="p-4 h-full overflow-auto hide-scrollbar">
      <div className={contentClasses}>
        <div className="flex justify-between mb-4">
          <span className="text-lg text-floatingPanel-default-text font-bold">
            {t('friends.playWithFriends')}
          </span>
          <Button
            data-testid="welcome__join-button"
            onClick={handleJoinGroup}
            variant={ButtonVariant.THIN}
          >
            {t('friends.joinGroup')}
          </Button>
        </div>

        <div className="mb-4">
          <BulletList list={bulletList} />
        </div>

        {group?.code ? (
          <FriendsGroupCode group={group} />
        ) : (
          <div className="flex flex-col mb-4 bg-floatingPanel-friends-bg p-2 rounded">
            <span className="mb-2 text-sm text-floatingPanel-friends-text font-bold">
              {t('friends.inviteFriends')}
            </span>
            <Button
              data-testid="welcome__create-group-button"
              onClick={handleCreateGroup}
              background={ButtonBackground.PRIMARY}
            >
              {t('friends.createGroup')}
            </Button>
          </div>
        )}
      </div>
      {!isUserAuthenticated && (
        <div className="absolute inset-0 z-20 p-4">
          <SignupBanner
            text={signUpText}
            buttonText={signUpActionText}
            onClick={handleSignup}
          />
        </div>
      )}

      {outlet}
    </div>
  )
}

export default FriendsGroupWelcomeScreen
