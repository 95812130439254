import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { ReactComponent as BitsIcon } from '@assets/bits.svg'
import { ReactComponent as LockIcon } from '@assets/icons/lock.svg'
import { VipLeaderboardBannerProps } from './types'
import Button, { ButtonBackground, ButtonVariant } from '../Button'

export const VipLeaderboardBanner: FC<VipLeaderboardBannerProps> = ({
  className,
  amount,
  onClick,
}) => {
  const { t } = useTranslation()

  const formattedAmount = amount?.toLocaleString('en-US')

  return (
    <div
      data-testid="vip-leaderboard-banner"
      className={`flex flex-row w-full py-1 pe-2 ps-4 bg-floatingPanel-vip-accent-bg items-center justify-between ${className}`}
    >
      <p className="text-floatingPanel-vip-accent-text text-xs font-semibold">
        {t('leaderboard.vip.unlock')}
      </p>
      <Button
        onClick={onClick}
        variant={ButtonVariant.THIN}
        background={ButtonBackground.CTA}
        data-testid="vip-leaderboard-banner-button"
        className="flex-shrink-0 px-1.5 py-1"
      >
        <div className="flex items-center">
          <BitsIcon className="w-4 h-4" />
          <span className="text-xs font-semibold">{formattedAmount}</span>
        </div>
      </Button>
    </div>
  )
}

export const UnsupportedVipLeaderboardBanner: FC<
  Omit<VipLeaderboardBannerProps, 'onClick'>
> = ({ className, amount }) => {
  const { t } = useTranslation()

  const formattedAmount = amount?.toLocaleString('en-US')
  return (
    <div
      data-testid="vip-leaderboard-banner"
      className={`flex flex-row w-full py-1 pe-2 ps-4 bg-floatingPanel-accent-muted-bg items-center justify-between ${className}`}
    >
      <p className="text-floatingPanel-accent-muted-text text-xs font-semibold">
        {t('leaderboard.vip.unsupported')}
      </p>
      <div
        data-testid="vip-leaderboard-banner-button"
        className="flex items-center outline-none cursor-pointer flex-shrink-0 ms-2 border-none justify-center rounded px-1.5 py-1"
      >
        <BitsIcon className="w-4 h-4" />
        <span className="text-floatingPanel-accent-muted-text text-xs font-semibold">
          {formattedAmount}
        </span>
      </div>
    </div>
  )
}

export const UnavailableVipLeaderboardBanner: FC<
  Omit<VipLeaderboardBannerProps, 'onClick' | 'amount'>
> = ({ className }) => {
  const { t } = useTranslation()

  return (
    <div
      data-testid="vip-leaderboard-banner"
      className={`flex flex-row w-full py-1 pe-2 ps-4 gap-x-2 bg-floatingPanel-accent-warning-bg items-center ${className}`}
    >
      <LockIcon className="w-4 h-4 flex-shrink-0 text-floatingPanel-accent-warning-text" />
      <p className="text-floatingPanel-accent-warning-text text-xs font-semibold">
        {t('leaderboard.vip.unavailable')}
      </p>
    </div>
  )
}

export default VipLeaderboardBanner
