import { BatchUser } from '@interfaces/user'
import {
  LocalisedSponsorContent,
  LocalisedContent,
} from '@interfaces/localisedContent'
import { LanguageCode } from '@interfaces/languages'

export enum LeaderboardStandingMethod {
  RANKED = 'RANKED',
  PERCENTILE = 'PERCENTILE',
  NOT_PRESENT = 'NOT_PRESENT',
  REQUIRES_PAYMENT = 'REQUIRES_PAYMENT',
}

export interface LeaderboardStanding {
  userId: string
  rank?: number
  position?: number
  points?: number
  percentile?: number
  method: LeaderboardStandingMethod
  displayName: string
  profilePicture?: string
}

export interface LeaderboardStandingResponse {
  standings: LeaderboardStanding[]
  pagination?: {
    nextPageToken: string
  }
}

export type DecoratedLeaderboardStanding = LeaderboardStanding &
  Partial<Pick<BatchUser, 'displayName' | 'profilePicture'>>

export enum PaymentCurrency {
  TWITCH_BITS = 'TWITCH_BITS',
}

export interface PaymentOptions {
  currency: PaymentCurrency
  cost: number
  sku: string
}

export enum Currency {
  TWITCH_BITS = 'TWITCH_BITS',
  UNDEFINED = 'UNDEFINED',
}

export enum LeaberboardBannerType {
  BUY = 'buy',
  UNAVAILABLE = 'unavailable',
  UNSUPPORTED = 'unsupported',
}

interface PointsSource {
  streamID: string
  after: Date
}

export interface PaymentOption {
  currency: Currency
  cost: number
  twitchSku: string
  bonusPoints: number
  availableUntil: string
}

export interface Banner {
  text?: string
  imageUrl: string
  imageAltText: string
  linkTarget?: string
}

export interface LocalisedLeaderboardContent {
  title: string
  banner: Banner
}

interface LocalisableContentItem {
  [languageCode: string]: LocalisedLeaderboardContent
}

export type LocalisableContent = LocalisableContentItem & {
  languages: string[]
}

export interface Leaderboard {
  id: string
  name: string
  pointsSources: PointsSource[]
  paymentOptions: PaymentOption[]
  content: LocalisableContent
  paymentRequired: boolean
}

export interface RawLeaderboardMeta {
  id: string
  name: string
  gameIds: string[]
  restrictEntityIds: string[]
  sponsor?: {
    localisations: LocalisedSponsorContent[]
  }
  title?: {
    localisations: LocalisedContent[]
  }
  createdAt: string
  updatedAt?: string
  deletedAt?: string
  requirePayment?: {
    options: PaymentOptions[]
  }
}

export type LeaderboardMeta = Omit<RawLeaderboardMeta, 'sponsor' | 'title'> & {
  sponsor?: {
    localisations: Partial<Record<LanguageCode, LocalisedSponsorContent>>
  }
  title?: {
    localisations: Partial<Record<LanguageCode, LocalisedContent>>
  }
}
