import { LanguageCode } from '@interfaces/languages'

export enum PubSubEventType {
  /**
   * Event fired when voteable is created
   */
  VOTEABLE_CREATE = 'games:voteable.create',

  /**
   * Event fired when voting opens on voteable
   */
  VOTEABLE_OPEN = 'games:voteable.open',

  /**
   * Event fired when voting is closed on voteable
   */
  VOTEABLE_CLOSE = 'games:voteable.close',

  /**
   * Event fired when voteable resolves
   */
  VOTEABLE_RESOLVE = 'games:voteable.resolve',

  /**
   * Event fired when announcement is created
   */
  ANNOUNCEMENT_CREATE = 'games:announcement.create',

  /**
   * Event fired when announcement is open
   */
  ANNOUNCEMENT_OPEN = 'games:announcement.open',

  /**
   * Event fired when winner should be announced
   */
  ANNOUNCE_WINNER = 'winners-announced',

  /**
   * Event fired when game is ended
   */
  END_GAME = 'games:game.ended',

  /**
   * TODO: change??
   */
  welcomeBuff = '__queue-welcome-buff-hack__',

  /**
   * Event fired when stream config changes
   */
  STREAM_CONFIG_CHANGE = 'configs:config.updated',

  UNLOCK_FRIEND_GROUP = 'groups:group.unlock',

  /**
   * Event fired when friend group is locked
   */
  LOCK_FRIEND_GROUP = 'groups:group.lock',

  /**
   * Event fired when friend group code is rotated
   */
  CODE_ROTATE_FRIEND_GROUP = 'groups:group.code_rotate',

  /**
   * Event fired when friend group is deleted
   */
  DELETE_FRIEND_GROUP = 'groups:group.delete',

  /**
   * Event fired when someone joins the friend group
   */
  JOIN_FRIEND_GROUP = 'groups:group.join',

  /**
   * Event fired when someone leaves the friend group
   */
  LEAVE_FRIEND_GROUP = 'groups:group.leave',

  /**
   * Event fired when user is kicked from group
   */
  REMOVE_FRIEND_GROUP = 'groups:group.remove',

  /**
   * Event fired when the listened to provider id has been updated on a stream
   */
  PROVIDER_UPDATE = 'providers:provider.update',

  /**
   * Event fired when the listened to provider id has been removed from a stream
   * NOTE: SDK will not do anything against this event
   */
  PROVIDER_REMOVE = 'providers:provider.remove',

  /**
   * Event fired when a reaction is updated
   */
  REACTIONS_UPDATED = 'reactions:updated',
}

export interface PubSubEvent {
  id: string
  name: PubSubEventType
  eventAt: string
}

export type GamePubSubEvent = PubSubEvent & {
  body: {
    gameId: string
    voteableId?: string
    announcementId?: string
    localisations: LanguageCode[]
    winners?: string[]
  }
}

export type StreamPubSubEvent = PubSubEvent & {
  body: {
    streamId: string
  }
}

export type ProviderPubSubEvent = PubSubEvent & {
  body: {
    action: string
    streamID: string
  }
}

export type StreamWinnersPubSubEvent = PubSubEvent & {
  body: {
    gameId: string
    winners: string[] | null
    leaderboardId: string
  }
}

export interface CentrifugoEvent {
  data: {
    id: string
    name: PubSubEventType
    event_at: string
    body: unknown
  }
}
