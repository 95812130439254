import React, { ChangeEvent, SyntheticEvent, useState } from 'react'
import Modal from '@components/molecules/Modal'
import { useNavigate, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import * as yup from 'yup'
import TermsAndPolicy from '@components/atoms/TermsAndPolicy'
import Button, { ButtonBackground } from '@components/atoms/Button'
import { sendOTP } from '@services/requests/auth'
import { AnimatePresence } from 'framer-motion'
import { RoutePaths } from '@interfaces/navigation'
import StaticOutlet from '@components/atoms/StaticOutlet'
import { getSlugByIndex } from '@utils/router'
import InfoText from '@components/atoms/InfoText'
import { logError } from '@utils/log'

const schema = yup.object().shape({
  email: yup.string().email().required(),
})

const EmailLogin = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const location = useLocation()

  const [error, setError] = useState('')
  const [emailInputValue, setEmailInputValue] = useState('')
  const [loading, setLoading] = useState(false)

  const resetError = () => setError('')

  const handleEmailSubmit = async (e: SyntheticEvent) => {
    e.preventDefault()

    try {
      const isValid = await schema.isValid({
        email: emailInputValue,
      })

      if (!isValid) return setError(t('error.validEmail'))
    } catch (error) {
      logError(error)
    }

    try {
      resetError()
      setLoading(true)
      await sendOTP(emailInputValue)
      navigate(
        `${RoutePaths.PASSCODE}?email=${encodeURIComponent(emailInputValue)}`
      )
    } catch (err) {
      const errMessage = t('error.unknown')
      setError(errMessage)
    } finally {
      setLoading(false)
    }
  }

  const handleEmailInput = (e: ChangeEvent<HTMLInputElement>) => {
    setEmailInputValue(e.target.value)
  }

  const handleGoBack = () => {
    if (loading) return
    navigate(-1)
  }

  const inputBorder = error
    ? 'border border-floatingPanel-semantic-error'
    : 'border-none'

  return (
    <Modal
      centerTitle
      titleClassName="normal-case"
      className="overflow-hidden"
      title={t('auth.continueWithEmail')}
      onGoBack={handleGoBack}
      data-testid="email-login"
      routerChildren={
        // <Outlet />
        <AnimatePresence>
          <StaticOutlet key={getSlugByIndex(location.pathname, -1)} />
        </AnimatePresence>
      }
    >
      <div className="flex flex-col flex-1 mt-4">
        <form
          noValidate
          className="flex flex-1 flex-col px-4"
          onSubmit={handleEmailSubmit}
        >
          <label htmlFor="email" className="sr-only">
            {t('general.emailAddress')}
          </label>
          <input
            data-testid="email-login__email-input"
            id="email"
            type="email"
            name="email"
            className={`text-sm block w-full rounded p-2 text-shades-black ${inputBorder}`}
            placeholder={t('general.emailAddress')}
            formNoValidate
            value={emailInputValue}
            disabled={loading}
            onFocus={resetError}
            onChange={handleEmailInput}
            aria-describedby={error ? 'email-error' : 'sign-up'}
          />
          <InfoText text={error} />
          <Button
            data-testid="email-login__submit-button"
            keepBgOnDisabled
            type="submit"
            disabled={loading}
            background={ButtonBackground.PRIMARY}
            className="mt-4"
          >
            {loading ? t('auth.sendingPasscode') : t('general.continue')}
          </Button>
        </form>
        <TermsAndPolicy className="px-4" />
      </div>
    </Modal>
  )
}

export default EmailLogin
