import {
  MappedReactionSize,
  MappedReactionSpeed,
  ReactionSize,
  ReactionSpeed,
  ReactionsPosition,
} from '@interfaces/reactions'
import { DEFAULT_REACTION_SPEED } from '../constants'
import i18n from '../i18n'

/**
 * Returns an HTML image with the expected animation
 * @param {ReactionsPosition} direction - Animation direction (Vertical | Horizontal)
 * @param {string} imageUrl - Emoji to be shown
 * @param {ReactionSpeed} speed - Emoji speed
 * @param {ReactionSize} size - Emoji size
 * @param {boolean} local - Is the emoji user's clicked emoji
 * @return {HTMLImageElement} - HTML image
 */
export function createReactionImg(
  direction: ReactionsPosition,
  imageUrl: string,
  speed: ReactionSpeed,
  size: ReactionSize,
  local: boolean
) {
  const container = document.createElement('div')
  const node = document.createElement('img')

  const emojiStyles =
    direction === ReactionsPosition.Horizontal
      ? 'right-[100px]'
      : 'bottom-[100px]'

  const mappedSpeed = MappedReactionSpeed[speed]
  const mappedSize = MappedReactionSize[size]

  container.className = `absolute z-10 pointer-events-none overflow-hidden flex-col flex items-center justify-center ${emojiStyles}`
  container.style.width = mappedSize

  container.style.animation = `${
    direction === ReactionsPosition.Horizontal ? 'fly-left' : 'fly-up'
  } ${DEFAULT_REACTION_SPEED * mappedSpeed}s linear`

  if (direction === ReactionsPosition.Horizontal) {
    container.style.bottom = `${Math.random() * 100}%`
  } else {
    container.style.right = `${Math.random() * 100}%`
  }

  node.className = `object-cover`
  node.style.width = mappedSize
  node.style.height = mappedSize
  node.src = imageUrl

  container.appendChild(node)

  if (local) {
    const youNode = document.createElement('div')
    youNode.textContent = i18n.t('general.you')
    youNode.className =
      'rounded-full text-reactionPanel-default-text font-secondary font-semibold text-xs leading-normal bg-gradient-to-r from-reactionPanel-default-bg-startColor to-reactionPanel-default-bg-endColor px-2 py-0.5 mt-0.5 capitalize'
    container.appendChild(youNode)
  }

  return container
}
