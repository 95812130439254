import React, { FC, useContext } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import PlayerPosition from '@components/atoms/PlayerPosition'
import {
  getPlayerPositionProps,
  shouldDisplayStreamWinnersSidePanel,
} from '@utils/streamWinners'
import { ConfigContext } from '@services/providers/ConfigProvider'
import { WidgetLayout } from '@interfaces/widget'
import { getLeaderboardName } from '@utils/getLeaderboardName'
import StreamWinnersSidePanel from '../StreamWinnersSidePanel'
import { StreamWinnersProps } from './types'

const Placeholder = () => <div className="w-1/3" />

const getLayoutStyles = (layout: WidgetLayout) => {
  switch (layout) {
    case WidgetLayout.PORTRAIT_FULL_LEADERBOARD:
    case WidgetLayout.PORTRAIT_TOGGLE_LEADERBOARD:
      return {
        titleFontSize: 'text-sm',
        playerPositionClassName: 'w-1/3 !px-1',
        containerMargin: '-mx-1',
      }

    case WidgetLayout.DESKTOP:
    default:
      return {
        titleFontSize: 'text-sm',
        playerPositionClassName: 'w-1/3',
        containerMargin: '',
      }
  }
}

const StreamWinners: FC<StreamWinnersProps> = ({
  buff,
  widgetLayout,
  activeLanguage,
}) => {
  const { t } = useTranslation()
  const { streamConfig } = useContext(ConfigContext)

  const { standings, userStanding, leaderboard } = buff

  const firstPlaceProps = getPlayerPositionProps(
    widgetLayout,
    userStanding?.userId,
    standings?.[0],
    streamConfig?.config.hideProfilePicture
  )

  const secondPlaceProps = getPlayerPositionProps(
    widgetLayout,
    userStanding?.userId,
    standings?.[1],
    streamConfig?.config.hideProfilePicture
  )

  const thirdPlaceProps = getPlayerPositionProps(
    widgetLayout,
    userStanding?.userId,
    standings?.[2],
    streamConfig?.config.hideProfilePicture
  )

  const leaderboardName = getLeaderboardName(activeLanguage, leaderboard)

  const { titleFontSize, playerPositionClassName, containerMargin } =
    getLayoutStyles(widgetLayout)

  return (
    <div data-testid="stream-winners">
      <div className={`flex justify-center`}>
        <div className={`flex flex-col ${containerMargin} pe-2`}>
          <div className="w-full">
            <h2
              data-testid="stream-winners-title"
              className={`text-center font-semibold ${titleFontSize} mb-3 font-secondary text-buff-default-text`}
            >
              <Trans
                i18nKey="streamWinners.hereAreWinners"
                values={{ name: leaderboardName }}
                components={[<br key={0} />]}
              />

              <span className="inline-flex mx-1">🏆</span>
            </h2>
          </div>

          <div className="flex">
            {firstPlaceProps ? (
              <PlayerPosition
                {...firstPlaceProps}
                className={playerPositionClassName}
              />
            ) : (
              <Placeholder />
            )}
            {secondPlaceProps ? (
              <PlayerPosition
                {...secondPlaceProps}
                className={playerPositionClassName}
              />
            ) : (
              <Placeholder />
            )}
            {thirdPlaceProps ? (
              <PlayerPosition
                {...thirdPlaceProps}
                className={playerPositionClassName}
              />
            ) : (
              <Placeholder />
            )}
          </div>
        </div>
        {shouldDisplayStreamWinnersSidePanel(buff.userStanding) && (
          <StreamWinnersSidePanel
            userStanding={buff.userStanding}
            widgetLayout={widgetLayout}
          />
        )}
      </div>
    </div>
  )
}

export default StreamWinners
