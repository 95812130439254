import React, { FC } from 'react'
import { ButtonProps, ButtonBackground, ButtonVariant } from './types'

const disabledBackgroundMap: Partial<
  Record<ButtonBackground, ButtonBackground>
> = {
  [ButtonBackground.PRIMARY]: ButtonBackground.PRIMARY_DISABLED,
}

const Button: FC<ButtonProps> = ({
  children,
  type = 'button',
  disabled = false,
  keepBgOnDisabled = false,
  className = '',
  background = ButtonBackground.SECONDARY,
  textSize = 'text-sm',
  variant = ButtonVariant.DEFAULT,
  ...otherProps
}) => {
  const backgroundMap: Partial<Record<ButtonBackground, string>> = {
    [ButtonBackground.SECONDARY]: 'bg-button-secondary-bg',
    [ButtonBackground.PRIMARY]: 'bg-button-primary-bg',
    [ButtonBackground.TERTIARY]: 'bg-button-tertiary-bg',
    [ButtonBackground.ERROR]: 'bg-button-danger-bg',
    [ButtonBackground.CTA]: 'bg-button-cta-bg',
    [ButtonBackground.NONE]: '',

    [ButtonBackground.PRIMARY_DISABLED]: 'bg-button-primary-bg',
  }

  const selectedBackground: ButtonBackground =
    disabled && !keepBgOnDisabled
      ? disabledBackgroundMap?.[background] ?? ButtonBackground.SECONDARY
      : background

  const bgColor = backgroundMap[selectedBackground]

  const textColorMap: Partial<Record<ButtonBackground, string>> = {
    [ButtonBackground.SECONDARY]: 'text-button-secondary-text',
    [ButtonBackground.PRIMARY]: 'text-button-primary-text',
    [ButtonBackground.TERTIARY]: 'text-button-tertiary-text',
    [ButtonBackground.ERROR]: 'text-button-danger-text',
    [ButtonBackground.CTA]: 'text-button-cta-text',
    [ButtonBackground.NONE]: 'text-button-secondary-text',
    [ButtonBackground.PRIMARY_DISABLED]: 'text-button-primary-text',
  }

  const textColor = textColorMap[selectedBackground]

  const cursor = disabled ? 'cursor-default' : 'cursor-pointer'
  const opacity = disabled ? 'opacity-60' : 'opacity-100'

  const paddingY = variant === ButtonVariant.THIN ? 'py-2' : 'py-3'
  const paddingX = variant === ButtonVariant.WIDE ? 'px-5' : 'px-3'

  const width = variant === ButtonVariant.FULL_WIDTH ? 'w-full' : ''

  return (
    <button
      type={type}
      className={`inline-block flex-shrink-0 ${paddingX} ${paddingY} font-semibold rounded leading-none ${cursor} ${textColor} ${bgColor} ${width} ${textSize} ${className} ${opacity}`}
      disabled={disabled}
      {...otherProps}
    >
      {children}
    </button>
  )
}

export default Button
