import React, {
  createContext,
  FC,
  useEffect,
  useState,
  useMemo,
  useContext,
} from 'react'
import { ResizeObserver } from '@juggle/resize-observer'
import {
  ITheme,
  BuffSize,
  DynamicTheme,
  ProviderTheme,
  Fonts,
} from '@interfaces/theme'
import { ConfigContext } from '@services/providers/ConfigProvider'
import { mapTheme } from '@themes/utils'
import { setupFontFaces } from '@utils/fonts'
import { getResponsiveVars } from '@utils/theme'
import { getClientThemes } from '@services/requests/theme'
import { convertDynamicTheme } from '@utils/dynamicTheme'
import baseTheme from '@themes/base'

export interface ThemeContextProps {
  /**
   * Currently set theme
   */
  theme: ITheme

  /**
   * Theme to be applied to BuffCore
   */
  buffTheme?: ITheme
}

export interface ThemeProviderProps {
  /**
   * Theme name that can be defined from the preview component & dev mode
   */
  themeName?: string

  /**
   * Client name to fetch the themes for
   */
  clientName?: string

  /**
   * HTML container element for the sdk
   */
  container?: HTMLElement

  /**
   * Buff size to show, used for local dev
   */
  localDevSDKSize?: BuffSize
}

export const ThemeContext = createContext<ThemeContextProps>(
  {} as ThemeContextProps
)

export const ThemeProvider: FC<ThemeProviderProps> = ({
  themeName,
  clientName,
  children,
  container,
  localDevSDKSize,
}) => {
  // Not destructuring as could be undefined due to being used in BuffPreview on dashboard
  const configValue = useContext(ConfigContext)
  const [dynamicThemes, setDynamicThemes] = useState<DynamicTheme[]>()
  const [theme, setTheme] = useState<ITheme>(baseTheme)

  const [buffSize, setBuffSize] = useState<BuffSize>(BuffSize.xSmall)
  const size = localDevSDKSize ?? buffSize

  const responsiveVars = getResponsiveVars(size)

  useEffect(() => {
    if (!dynamicThemes?.length) return
    const dynamicTheme = dynamicThemes.find(
      (curr: DynamicTheme) =>
        curr.theme.name === themeName ||
        curr.theme.name === configValue?.streamConfig?.config?.theme
    )

    if (dynamicTheme) {
      const convertedTheme = convertDynamicTheme(
        dynamicTheme.theme.light as ProviderTheme
      )
      setTheme({ variant: dynamicTheme.theme.name, ...convertedTheme })
    }
  }, [dynamicThemes, themeName, configValue?.streamConfig?.config?.theme])

  const defaultContext: ThemeContextProps = {
    theme,
    buffTheme: undefined,
  }

  const themeVars = useMemo(() => {
    const dynamicTheme = dynamicThemes?.find(
      (curr: DynamicTheme) => curr.theme.name === theme.variant
    )

    if (dynamicTheme?.theme.name) {
      setupFontFaces(dynamicTheme)
      const convertedTheme = convertDynamicTheme({
        ...(dynamicTheme.theme.colors as ProviderTheme),
      })

      return mapTheme({
        ...convertedTheme,
        'font-base': `"${(dynamicTheme.theme.fonts as Fonts).font1.name}"`,
        'font-secondary': `"${(dynamicTheme.theme.fonts as Fonts).font2.name}"`,
      })
    } else {
      return mapTheme(baseTheme)
    }
  }, [dynamicThemes, theme])

  useEffect(() => {
    const fetchDynamicThemes = async () => {
      const themes = (await getClientThemes(
        clientName ?? configValue?.widgetConfig?.clientName
      )) as DynamicTheme[]
      setDynamicThemes(themes)
    }

    fetchDynamicThemes()
  }, [configValue?.widgetConfig?.clientName, clientName])

  useEffect(() => {
    if (!container) return
    const observer = new ResizeObserver(([entry]) => {
      const { inlineSize: width } = entry.borderBoxSize[0]

      if (width < 1600) {
        setBuffSize(BuffSize.xSmall)
        return
      }

      if (width < 2100) {
        setBuffSize(BuffSize.small)
        return
      }

      if (width < 3000) {
        setBuffSize(BuffSize.medium)
        return
      }

      setBuffSize(BuffSize.large)
    })

    observer.observe(container)

    return () => {
      observer.disconnect()
    }
  }, [container])
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const styleObj: any = {
    ...themeVars,
    ...responsiveVars,
  }

  return (
    <ThemeContext.Provider value={defaultContext}>
      <div
        style={styleObj}
        className="text-base absolute inset-0 pointer-events-none font-base"
      >
        {children}
      </div>
    </ThemeContext.Provider>
  )
}
