import React, { useContext, useEffect } from 'react'
import { useNavigate, useOutlet } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import Modal from '@components/molecules/Modal'
import ProfilePhoto from '@components/atoms/ProfilePhoto'
import EditInput, { emailSchema, nameSchema } from '@components/atoms/EditInput'
import { useUpdateUser } from '@utils/hooks/useUpdateUser'
import { RoutePaths } from '@interfaces/navigation'
import AnimatedView from '@components/atoms/AnimatedView'
import { ConfigContext } from '@services/providers/ConfigProvider'
import { UserContext } from '@services/providers/UserProvider'
import { SignupBanner } from '@components/atoms/SignupBanner'
import { useSignupText } from '@utils/hooks/useSignupTexts'
import { useFloatingTabsContext } from '@services/providers/FloatingTabsProvider'

const Profile = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const outlet = useOutlet()

  const { widgetConfig } = useContext(ConfigContext)
  const { token, isLinkedTwitchUser, refetchProfile } = useContext(UserContext)
  const { isOpen } = useFloatingTabsContext()

  const {
    profilePhoto,
    isPhotoChangeEnabled,
    handleNameChangeRequest,
    name,
    errorName,
    loadingName,
    setName,
    email,
    errorEmail,
    loadingEmail,
    setEmail,
    handleEmailChangeRequest,
    editNameInputDisabled,
    editEmailInputDisabled,
  } = useUpdateUser()

  const { text: signUpText, actionText: signUpActionText } = useSignupText({
    placement: 'profileTab',
  })

  const handleProfilePictureClick = () => {
    navigate(RoutePaths.PROFILE_ASSETS)
  }

  const onGoBack = () => navigate(RoutePaths.PROFILE)

  const nameValidationSchema = nameSchema(t)
  const emailValidationSchema = emailSchema(t)

  const handleSignup = () => {
    navigate(RoutePaths.AUTH, {
      state: {
        onGoBack: onGoBack,
      },
    })
  }

  const isTwitch =
    widgetConfig.player === 'twitch-mobile' ||
    widgetConfig.player === 'twitch-web'

  const isUserAuthenticated = isTwitch ? isLinkedTwitchUser : Boolean(token)

  const contentClasses = isUserAuthenticated ? '' : 'blur-[4px]'

  useEffect(() => {
    if (!isOpen) return
    refetchProfile()
  }, [refetchProfile, isOpen])

  return (
    <AnimatedView data-testid="profile-content" viewPath={RoutePaths.PROFILE}>
      <Modal
        displaySolidBackground={false}
        title={t('settings.profile')}
        data-testid="profile"
        routerChildren={outlet}
        shouldAnimate={false}
        centerTitle
        contentClassName="!bg-none"
        className={contentClasses}
      >
        <div className="overflow-auto hide-scrollbar flex flex-col items-center w-5/6 mx-auto">
          <ProfilePhoto
            image={profilePhoto}
            disabled={!isPhotoChangeEnabled}
            onClick={handleProfilePictureClick}
          />
          <EditInput
            className="mb-2 mt-2"
            schema={nameValidationSchema}
            label={t('general.name')}
            loading={loadingName}
            error={errorName}
            value={name}
            onChange={setName}
            onSave={handleNameChangeRequest}
            disabled={editNameInputDisabled}
          />
          <EditInput
            schema={emailValidationSchema}
            label={t('general.email')}
            loading={loadingEmail}
            error={errorEmail}
            value={email}
            onChange={setEmail}
            onSave={handleEmailChangeRequest}
            disabled={editEmailInputDisabled}
          />
        </div>
      </Modal>
      {!isUserAuthenticated && (
        <div className="absolute inset-0 z-20 p-4">
          <SignupBanner
            text={signUpText}
            buttonText={signUpActionText}
            onClick={handleSignup}
          />
        </div>
      )}
    </AnimatedView>
  )
}

export default Profile
