import { client } from '@services/requests/client'
import { BatchUser, User } from '@interfaces/user'
import { AxiosResponse } from 'axios'
import { v4 } from 'uuid'
import storage from '@utils/storage'
import {
  BUFFUP_SDK_REFRESH_TOKEN,
  BUFFUP_SDK_TOKEN,
  BUFFUP_ANON_ID,
} from '../../constants'

/**
 * Get a batch of users
 * @param {string[]} userIds
 * @return {Promise<BatchUser[]>}
 */
export const getUsers = async (userIds: string[]): Promise<BatchUser[]> => {
  const response = await client.get(`/users?ids=${userIds.join(',')}`)

  const users = response.data
  return users
}

/**
 * Get a current logged in user
 * @param {string} userId
 * @return {Promise<User>}
 */
export const getUser = async (userId?: string): Promise<User> => {
  const response: AxiosResponse<User> = await client.get(`/users/me`)

  return response.data
}

/**
 * Update user
 * @param {string} userId
 * @param {string} displayName
 * @return {Promise<User>}
 */
export const updateUser = async (
  userId: string,
  displayName: string
): Promise<User> => {
  const response = await client.patch(`/users/me`, {
    displayName: displayName?.trim(),
  })

  const users = response.data
  return users
}

/**
 * Update user's profile picture
 * @param {string} userId
 * @param {string} profilePicture
 * @return {Promise<User>}
 */
export const updateUserProfilePicture = async (
  userId: string,
  profilePicture: string
): Promise<User> => {
  const response = await client.patch(`/users/me`, {
    profilePicture,
  })

  const users = response.data
  return users
}

/**
 * Update user email
 * @param {string} userId
 * @param {string} email
 * @param {string} otp
 * @return {Promise<User>}
 */
export const updateUserEmail = async (
  userId: string,
  email: string,
  otp: string
): Promise<void> => {
  const response = await client.put(`/users/${userId}/email`, {
    email: email?.trim(),
    otp,
  })

  return response.data
}

/**
 * Send OTP to given email
 * @param {string} email
 * @return {Promise<void>}
 */
export const sendOTP = async (email: string): Promise<void> => {
  const response = await client.post('/auth/otp/email', {
    email: email?.trim(),
  })

  return response?.data
}

/**
 * Login with otp & email
 * @param {string} otp
 * @param {string} email
 */
export const loginWithOTP = async (otp: string, email: string) => {
  const { data } = await client.post('/auth/login', {
    otp,
    email: email?.trim(),
  })

  storage.setItem(BUFFUP_SDK_REFRESH_TOKEN, data.refreshToken)
  const token = await refreshJwtToken()

  return token
}

/**
 * Refresh token
 */
export const refreshJwtToken = async () => {
  const refreshToken = storage.getItem(BUFFUP_SDK_REFRESH_TOKEN)

  try {
    const { data } = await client.post(
      '/auth/token-exchange',
      {
        refreshToken,
      },
      {
        headers: {
          bypass401: true,
        },
      }
    )

    if (!data.token) {
      storage.removeItem(BUFFUP_SDK_TOKEN)
      storage.removeItem(BUFFUP_SDK_REFRESH_TOKEN)
    }

    storage.setItem(BUFFUP_SDK_TOKEN, data?.token)

    return data?.token
  } catch (error) {
    storage.removeItem(BUFFUP_SDK_TOKEN)
    storage.removeItem(BUFFUP_SDK_REFRESH_TOKEN)
  }
}

interface CreateAnonUserResponse {
  refreshToken: string
  userID: string
  expiresAt: string
  createdAt: string
}

export const createAnonUser = async () => {
  const uuidRegex =
    /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i

  let id = storage.getItem(BUFFUP_ANON_ID)
  if (id === null || !uuidRegex.test(id)) {
    id = v4()
    storage.setItem(BUFFUP_ANON_ID, id)
  }

  const response = await client.post<CreateAnonUserResponse>(`/users/me`, {
    opaqueID: id,
  })

  return response.data
}
