import React, { useState, MutableRefObject, useEffect } from 'react'
import { MemoryRouter } from 'react-router-dom'
import { QueryClientProvider } from 'react-query'
import FullScreenButton from '@components/atoms/FullScreenButton'
import WritingModeWrapper from '@components/atoms/WritingModeWrapper'
import ExtensionWrapper from '@components/atoms/ExtensionWrapper/ExtensionWrapper'
import BuffWrapper from '@components/organisms/BuffWrapper'
import QRCodeWrapper from '@components/organisms/QRCodeWrapper'
import FloatingPanelWrapper from '@components/organisms/FloatingPanelWrapper'

import { LocalDebugUtilities, WidgetConfig } from '@interfaces/widget'
import { useUserActivityVisible } from '@utils/hooks/useUserActivityVisible'
import { ProviderWrapper } from '@services/providers'
import { BuffProviderRef } from '@services/providers/BuffProvider'
import { updateBaseURL } from '@services/requests/client'
import { queryClient } from '@utils/reactQuery'
import ReactionsWrapper from './organisms/ReactionsWrapper'

export interface IWidgetProps {
  /**
   * Configuration object of widget given by user
   */
  config: WidgetConfig

  /**
   * The container element of the video
   */
  container?: HTMLElement

  /**
   * Function that will toggle the video container fullscreen
   */
  toggleFullScreen?: () => void

  /**
   * Writing mode for SDK (used for dev)
   */
  writingMode?: 'ltr' | 'rtl'

  /**
   * Debug Object to activate/deactivate some functionality
   * for local debugging purposes
   */
  debug?: LocalDebugUtilities

  /**
   * Ref to the widget
   */
  innerRef?: MutableRefObject<BuffProviderRef | undefined>
}

/**
 * Component to be rendered on the page
 * @return {JSX.Element}
 */
const Widget = ({
  config,
  toggleFullScreen,
  container,
  debug,
  writingMode,
  innerRef,
}: IWidgetProps) => {
  const [axiosClientReady, setAxiosClientReady] = useState(false)
  const playerControlsVisible = useUserActivityVisible(config)

  /**
   * Update axios base url before rendering component
   */
  useEffect(() => {
    updateBaseURL(config.clientName)
    setAxiosClientReady(true)
  }, [config.clientName])

  return axiosClientReady ? (
    <QueryClientProvider client={queryClient}>
      <MemoryRouter
      // initialEntries={[RouteMainPaths.LEADERBOARDS]}
      // initialIndex={0}
      >
        <ProviderWrapper
          innerRef={innerRef}
          config={config}
          debug={debug}
          container={container}
          writingMode={writingMode}
          videoPlayer={config.videoPlayer}
        >
          <WritingModeWrapper>
            <ExtensionWrapper>
              {config?.addFullScreenButton && (
                <FullScreenButton
                  onClick={() => toggleFullScreen && toggleFullScreen()}
                  className={
                    playerControlsVisible ? 'opacity-100' : 'opacity-0'
                  }
                />
              )}
              <FloatingPanelWrapper
                playerControlsVisible={playerControlsVisible}
                sdkContainer={container}
              />

              <div
                className="pointer-events-auto antialiased"
                data-layout={config?.layout}
              >
                <BuffWrapper />
              </div>

              <ReactionsWrapper sdkContainer={container} />
            </ExtensionWrapper>
            <QRCodeWrapper />
          </WritingModeWrapper>
        </ProviderWrapper>
      </MemoryRouter>
    </QueryClientProvider>
  ) : null
}

Widget.displayName = 'Widget'
export default Widget
