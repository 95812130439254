import React from 'react'
import Image from '@components/atoms/Image'
import { ReactComponent as Camera } from '@assets/icons/camera.svg'
import { ReactComponent as ProfilePlaceholder } from '@assets/icons/profile-placeholder.svg'
import { ProfilePhotoProps } from './types'

const ProfilePhoto = ({ image, disabled, onClick }: ProfilePhotoProps) => {
  const cursor = !disabled ? 'cursor-pointer' : 'cursor-default'

  const handleClick = () => {
    onClick()
  }

  return (
    <button
      data-testid="profile-photo"
      disabled={disabled}
      onClick={handleClick}
      className={`h-10 w-10 rounded-full relative ${cursor}`}
    >
      <Image
        data-testid="profile-photo__image"
        className="block h-full w-full rounded-full object-cover"
        src={image}
        fallbackComponent={
          <ProfilePlaceholder
            className="block h-full w-full rounded-full"
            data-testid="profile-photo__fallback"
          />
        }
      />

      {!disabled && (
        <div
          data-testid="profile-photo__camera"
          className="absolute -bottom-1 -right-1 z-10 h-5 w-5 flex items-center justify-center rounded-full overflow-hidden text-button-tertiary-text bg-button-tertiary-bg"
        >
          <Camera width="70%" />
        </div>
      )}
    </button>
  )
}

export default ProfilePhoto
