import React, { useEffect, useRef, useState, useCallback, FC } from 'react'
import { motion } from 'framer-motion'
import { secondsToTime } from '@utils/secondsToTime'

import { ITimebarProps } from './types'

const getTimebarColor = (time: number) => {
  if (time <= 3) return 'var(--color-timer-end)'
  if (time <= 6) return 'var(--color-timer-middle)'
  return 'var(--color-timer-start)'
}

const getTimebarTextColor = (time: number) => {
  if (time <= 3) return 'var(--color-timer-end)'
  return 'var(--color-timer-background)'
}

const Timebar: FC<ITimebarProps> = ({
  duration = 15,
  inProgress,
  showCountdown = true,
  className = '',
  buffId,
  onTimeChange,
}) => {
  const [currentTime, setCurrentTime] = useState<number>(duration)
  const timeout = useRef<number>()

  const setTime = useCallback(
    () => inProgress && setCurrentTime((time) => time - 1),
    [inProgress]
  )
  const startTimeout = useCallback(
    () => (timeout.current = window.setTimeout(setTime, 1000)),
    [setTime]
  )
  const stopTimeout = useCallback(() => {
    clearTimeout(timeout.current)
  }, [])

  useEffect(() => {
    startTimeout()
    onTimeChange?.(currentTime)
    if (currentTime === 0) stopTimeout()
    return () => stopTimeout()
  }, [currentTime, onTimeChange, startTimeout, stopTimeout])

  const timePercentage = currentTime / duration

  const color = getTimebarColor(currentTime)
  const textColor = getTimebarTextColor(currentTime)

  const animatedProperties = {
    transform: `scaleX(${timePercentage})`,
    backgroundColor: color,
  }

  const countdownAnimatedProperties = {
    color: textColor,
  }

  return (
    <div data-testid="timebar" className={`flex items-center ${className}`}>
      {showCountdown && (
        <p
          data-testid="timebar__countdown"
          style={{ ...countdownAnimatedProperties }}
          className="me-1 text-xs text-buff-default-text font-bold font-secondary"
        >
          {secondsToTime(currentTime)}
        </p>
      )}

      <div
        data-testid="timebar__container"
        className="w-full rounded-full bg-timer-background h-1"
      >
        <motion.div
          key={buffId}
          data-testid="timebar__bar"
          animate={animatedProperties}
          transition={{ duration: 1, ease: 'linear' }}
          className="rounded-full h-1 origin-left"
          style={{
            ...animatedProperties,
          }}
        />
      </div>
    </div>
  )
}

export default Timebar
