import { Leaderboard, LeaderboardStanding } from '@interfaces/leaderboard'
import createClient, { Service } from '@utils/createClient'

export const getLeaderboardsByStreamId = async (streamId: string) => {
  const client = createClient(Service.LIVE)
  const data = await client.get(`/streams/${streamId}/leaderboards`)

  return data.data
}

export const getLeaderboardMetaById = async (
  leaderboardId?: string,
  streamId?: string
): Promise<Leaderboard> => {
  if (!leaderboardId || !streamId) return Promise.reject()

  const client = createClient(Service.LIVE)
  const data = await client.get(
    `/streams/${streamId}/leaderboards/${leaderboardId}`
  )

  return data.data
}

export const getStreamLeaderboards = async (streamId?: string) => {
  if (!streamId) return Promise.reject()

  const client = createClient(Service.LIVE)

  const data = await client.get(`/streams/${streamId}/leaderboards`)

  return data.data
}

export const getLeaderboardStandingsById = async (
  leaderboardId?: string,
  streamId?: string,
  groupId?: string
): Promise<LeaderboardStanding[]> => {
  if (!leaderboardId || !streamId) return Promise.reject()

  const client = createClient(Service.LIVE)

  let url = `/streams/${streamId}/leaderboards/${leaderboardId}/standings`

  if (!!groupId) {
    url += `?friendgroup=${groupId}`
  }

  const data = await client.get(url)
  return data.data
}

export const getLeaderboardStandingByEntityId = async (
  streamId: string,
  leaderboardId: string,
  entityId?: string,
  groupId?: string
): Promise<LeaderboardStanding> => {
  const client = createClient(Service.LIVE)

  let url = `/streams/${streamId}/leaderboards/${leaderboardId}/standings/${entityId}`

  if (!!groupId) {
    url += `?friendgroup=${groupId}`
  }

  const response = await client.get(url)

  return response.data
}

export const claimTwitchPayment = async ({
  streamId,
  leaderboardId,
  transactionId,
  jwt,
}: {
  streamId: string
  leaderboardId: string
  transactionId: string
  jwt: string | null
}): Promise<LeaderboardStanding> => {
  const client = createClient(Service.LIVE)
  const url = `/streams/${streamId}/leaderboards/${leaderboardId}/claim-twitch-payment`

  const response = await client.post(
    url,
    { transactionId },
    {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    }
  )

  return response.data
}
