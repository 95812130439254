import axios, { HeadersDefaults } from 'axios'
import { changeCaseInterceptor } from '@services/requestClient'
import storage from '@utils/storage'
import { refreshJwtToken } from '@services/requests/auth'
import { TwitchConfigState } from 'twitch-config'
import { BUFFUP_SDK_REFRESH_TOKEN, BUFFUP_SDK_TOKEN } from '../constants'

interface CommonHeaderProperties extends HeadersDefaults {
  Authorization: string
  'X-Buffup-Broadcaster-ID': string
}

export enum Service {
  CONFIG = 'config',
  LIVE = 'live',
}

/**
 * @param {Service} serviceName Name of the API service
 * @param {string} twitchClientName Determines the Twitch client
 * @return {AxiosInstance}
 */
export default function createClient(serviceName: Service) {
  let accountName = process.env.ACCOUNT_NAME ?? 'sportbuff'

  const configuration: TwitchConfigState = JSON.parse(
    window.Twitch?.ext?.configuration?.broadcaster?.content ?? '{}'
  )

  if (!!configuration?.clientName) {
    accountName = configuration?.clientName
  }

  const baseURL =
    ['develop', 'qa'].indexOf(accountName) > -1
      ? `https://${serviceName}.api.${accountName}.buffup.net`
      : `https://${serviceName}.api.prod.buffup.net`

  const client = axios.create({
    baseURL,
  })

  client.defaults.headers = {
    'X-Buffup-Broadcaster-ID': accountName,
  } as unknown as CommonHeaderProperties

  client.interceptors.request.use(
    (request) => {
      const jwtToken = storage.getItem(BUFFUP_SDK_TOKEN)

      if (request?.headers && !!jwtToken) {
        request.headers.Authorization = `Bearer ${jwtToken}`
      }

      return request
    },
    (error) => {
      return Promise.reject(error)
    }
  )

  // Used for users batch endpoint
  client.interceptors.response.use(
    changeCaseInterceptor.responseFulfilled,
    changeCaseInterceptor.responseRejected
  )

  // Used for users batch endpoint
  client.interceptors.response.use(
    changeCaseInterceptor.responseFulfilled,
    async (error) => {
      const config = error?.config

      if (error?.response?.status === 401 && !config?.sent) {
        config.sent = true

        if (error?.config?.headers?.bypass401) {
          storage.removeItem(BUFFUP_SDK_TOKEN)
          storage.removeItem(BUFFUP_SDK_REFRESH_TOKEN)
          window.dispatchEvent(new Event('expiredRefreshToken'))

          return Promise.reject(error)
        }

        const jwtToken = await refreshJwtToken()

        if (jwtToken) {
          config.headers = {
            ...config.headers,
            Authorization: `Bearer ${jwtToken}`,
          }
        }

        return client(config)
      }
      return Promise.reject(error)
    }
  )
  return client
}
